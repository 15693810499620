/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useContext, useState, useRef } from 'react';
import './style.scss';
import {
  Avatar,
  Button,
  Card,
  Skeleton,
  Space,
  Tabs,
  TabsProps,
  Tooltip,
  Modal,
  Typography,
} from 'antd';
import { TLoginButton, TLoginButtonSize } from 'react-telegram-auth';
import { isMobile, useDeviceData } from 'react-device-detect';
import AskAI from 'components/ask-ai';
import WalletConnectCard from 'connect/walletconnectcard';
import { ThemeContext } from 'contexts/ThemeContext';
import MetaMaskCard from 'connect/metamaskcard';
import CoinbaseCard from 'connect/coinbasecard';
import DiscordService from 'services/DiscordService';
import { ARCAPIService } from 'services/ARCAPIService';
import env from 'config/global-env';
import ARCModal from 'components/ARCModal/ARCModal';
import { AuthContext } from '../../contexts/AuthProvider';
import { __debounce } from '../../utility/debounce';

import TelegramIcon from '../../assets/telegramIcon.svg';
import DiscordIcon from '../../assets/discord.svg';
import TokenImg from '../../assets/token.png';
import NewTokenImg from '../../assets/newToken.png';
import DiscordImg from '../../assets/Discord.png';
import TelegramImg from '../../assets/Telegram.png';

const ArcSocialConnectionRightSidebar: React.FC = () => {
  const mobile = isMobile;
  const [isTypeDetection, setisTypeDetection] = useState(false);
  const [checkedOption, setCheckedOption] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);

  const {
    isSocialConnectRightBar,
    setSocialConnectionRightBar,
    isAuthenticated,
    setaskAIInfo,
    auditMyToken,
    setAuditMyToken,
    auditNewToken,
    setAuditNewToken,
    connectDiscord,
    setConnectDiscord,
    connectTelegram,
    setConnectTelegram,
    telegramData,
    setTelegramData,
  } = useContext(AuthContext);

  const { theme } = useContext(ThemeContext);

  const telegramBtnRef = useRef(null);
  const [discordMe, setDiscordMe] = useState<any>(null);
  const { discordToken, chainId } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const tokenPanelRef = useRef(null);
  const socialPanelRef = useRef(null);

  const currentHost = window.location.host;
  const { protocol } = window.location;
  const redirectUri = `${protocol}//${currentHost}/callback`;

  const DISCORD_AUTH_URL = `https://discord.com/api/oauth2/authorize?client_id=${env.DISCORD.clientId}&redirect_uri=${redirectUri}&response_type=code&scope=identify`;
  const DISCORD_LINK = env.DISCORD.inviteLink;
  const handleClose = () => {
    setSocialConnectionRightBar(false);
  };
  const handleRightClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    typeAsk: string,
  ) => {
    console.warn('handleRightClick', typeAsk);
  };

  const getMe = async () => {
    const x = await DiscordService.getMe();
    if (x && x.id) {
      setDiscordMe(x);
      setConnectDiscord(true);
    } else {
      setConnectDiscord(false);
    }
  };
  const revoke = async () => {
    const rstRevoke = await DiscordService.revoke();
    localStorage.removeItem('@app:d');
    setDiscordMe(null);
    setConnectDiscord(false);
    window.location.reload();
  };
  const getTelegram = async () => {
    const telegramData = localStorage.getItem('@app:t');
    if (telegramData) {
      const telegramDataJson = JSON.parse(telegramData);

      if (telegramDataJson) {
        setTelegramData(telegramDataJson);
      }
    }
  };

  const handleClickTelegramCard = () => {
    setSocialConnectionRightBar(false);
    if (!telegramData) setOpenModal(true);
    else {
      setTelegramData(null);
      setConnectTelegram(false);
      localStorage.removeItem('@app:t');
      window.location.reload();
    }
  };

  const registerWebNotification = async () => {
    if (auditMyToken) {
      setAuditMyToken(false);
      await ARCAPIService.RemoveFirebase();
    } else {
      setAuditMyToken(true);
      await ARCAPIService.UpdateFirebase();
    }
  };

  const handleClickNextStep = () => {
    if (tokenPanelRef && tokenPanelRef.current) {
      tokenPanelRef.current.style.animationName = 'hide';
      const timer = setTimeout(() => {
        setStep(1);
        tokenPanelRef.current.style.animationName = '';
        clearTimeout(timer);
      }, 1000);
    }
  };
  const handleClickPreviousStep = () => {
    if (socialPanelRef && socialPanelRef.current) {
      socialPanelRef.current.style.animationName = 'hide';
      const timer = setTimeout(() => {
        setStep(0);
        socialPanelRef.current.style.animationName = '';
        clearTimeout(timer);
      }, 900);
    }
  };

  useEffect(() => {
    if (discordToken) {
      getMe();
    }
  }, [discordToken]);

  useEffect(() => {
    getTelegram();
  }, []);

  return (
    <div
      className={
        isSocialConnectRightBar
          ? 'arc-rightbar-dialog show'
          : 'arc-rightbar-dialog'
      }
      role="document"
    >
      {/* <div className="arc-rightsiderbar-wrapper"> */}
      {/* <AskAI
        onRightClick={(event) => handleRightClick(event, 'swap')}
        theme={theme ?? 'dark'}
        content="Account Menu"
        onHandleClose={(value) => {
          setaskAIInfo(value);
        }}
      > */}
      <div className="modal-content arc-rightsiderbar-wrapper-social">
        {window.innerWidth > 400 && (
          <div
            className="arc-rightsidebar-drawer"
            onClick={() => {
              setSocialConnectionRightBar(false);
            }}
          />
        )}
        <div className="arc-rightsidebar-main-social">
          <div
            ref={tokenPanelRef}
            className="mt4 arc-rightsidebar-main-title-social-mt4"
            style={{
              display: step === 0 ? 'block' : 'none',
            }}
          >
            <div className="arc-rightsidebar-main-title-social">
              <h4 style={{ color: theme === 'light' ? 'black' : 'white' }}>
                Audit for tokens
              </h4>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                onClick={() => handleClose()}
              >
                <path
                  fill="none"
                  stroke={theme === 'light' ? 'black' : 'white'}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.5"
                  d="m7 7l10 10M7 17L17 7"
                />
              </svg>
            </div>
            <div className="arc-rightsidebar-main-body">
              <div
                className="arc-rightsidebar-token-card"
                // onClick={() => setAuditMyToken(!auditMyToken)}
                style={{
                  border: auditMyToken
                    ? '2px solid #007AFF'
                    : '2px solid #a5a5a5',
                }}
                onClick={registerWebNotification}
              >
                <img src={TokenImg} alt="token alert img" />
                <div className="arc-rightsidebar-token-card-detail">
                  {!auditMyToken ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 48 48"
                    >
                      <g fill="none" strokeLinejoin="round" strokeWidth="4">
                        <path
                          fill="none"
                          stroke="#B9B9BF"
                          d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z"
                        />
                        <path
                          stroke="#B9B9BF"
                          strokeLinecap="round"
                          d="M16 24L22 30L34 18"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 48 48"
                    >
                      <g fill="none" strokeLinejoin="round" strokeWidth="4">
                        <path
                          fill="#007afe"
                          stroke="#007afe"
                          d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z"
                        />
                        <path
                          stroke="#fff"
                          strokeLinecap="round"
                          d="M16 24L22 30L34 18"
                        />
                      </g>
                    </svg>
                  )}
                  <div className="arc-rightsidebar-token-card-info">
                    <h5
                      style={{
                        color: theme === 'light' ? 'black' : 'white',
                        margin: 0,
                      }}
                    >
                      Get Security Notifications in the App
                    </h5>
                    <p style={{ margin: 0 }}>
                      Get portfolio security updates through the notification
                      bell in the dApp
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="arc-rightsidebar-token-card"
                style={{
                  border: auditNewToken
                    ? '2px solid #007AFF'
                    : '2px solid #a5a5a5',
                }}
                onClick={() => setStep(1)}
                // onClick={() => setAuditNewToken(!auditNewToken)}
              >
                <img src={NewTokenImg} alt="new token alert img" />
                <div className="arc-rightsidebar-token-card-detail">
                  {!auditNewToken ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 48 48"
                    >
                      <g fill="none" strokeLinejoin="round" strokeWidth="4">
                        <path
                          fill="none"
                          stroke="#B9B9BF"
                          d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z"
                        />
                        <path
                          stroke="#B9B9BF"
                          strokeLinecap="round"
                          d="M16 24L22 30L34 18"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 48 48"
                    >
                      <g fill="none" strokeLinejoin="round" strokeWidth="4">
                        <path
                          fill="#007afe"
                          stroke="#007afe"
                          d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z"
                        />
                        <path
                          stroke="#fff"
                          strokeLinecap="round"
                          d="M16 24L22 30L34 18"
                        />
                      </g>
                    </svg>
                  )}
                  <div className="arc-rightsidebar-token-card-info">
                    <h5
                      style={{
                        color: theme === 'light' ? 'black' : 'white',
                        margin: 0,
                      }}
                    >
                      Get Security Notifications on Social Media
                    </h5>
                    <p style={{ margin: 0 }}>
                      Get portfolio security updates 24/7 through Discord and
                      Telegram
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            ref={socialPanelRef}
            className="mt4 arc-rightsidebar-main-title-social-mt4"
            style={{
              display: step === 1 ? 'block' : 'none',
            }}
          >
            <div
              className="arc-rightsidebar-main-title-social"
              style={{ justifyContent: 'flex-start' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                onClick={() => handleClickPreviousStep()}
              >
                <path
                  fill={theme === 'light' ? 'black' : 'white'}
                  d="M17 11H9.41l3.3-3.29a1 1 0 1 0-1.42-1.42l-5 5a1 1 0 0 0-.21.33a1 1 0 0 0 0 .76a1 1 0 0 0 .21.33l5 5a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42L9.41 13H17a1 1 0 0 0 0-2Z"
                />
              </svg>
              <h4
                style={{
                  color: theme === 'light' ? 'black' : 'white',
                  marginLeft: '1em',
                }}
              >
                Connect social account
              </h4>
            </div>
            <div className="arc-rightsidebar-main-body">
              <h5 style={{ color: theme === 'light' ? 'black' : 'white' }}>
                Connect to your accounts to get notified about the new tokens
                and alerts.
              </h5>
              <div
                className="arc-rightsidebar-token-card"
                style={{
                  border: discordMe ? '2px solid #007AFF' : '2px solid #a5a5a5',
                }}
                onClick={() => {
                  if (!discordMe) {
                    window.location.href = DISCORD_AUTH_URL;
                  } else {
                    revoke();
                  }
                }}
              >
                {discordMe && discordMe.avatar ? (
                  <div
                    style={{
                      maxHeight: 113,
                      display: 'flex',
                      alignItems: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={`https://cdn.discordapp.com/avatars/${discordMe.id}/${discordMe.avatar}`}
                      alt=""
                      className="social-connect-list-row-info-img"
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#2d2d31',
                      padding: '8px 0',
                    }}
                  >
                    <img
                      src={DiscordImg}
                      style={{ width: '85%' }}
                      alt="discord img"
                    />
                  </div>
                )}
                <div className="arc-rightsidebar-token-card-detail">
                  {discordMe && discordMe.avatar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 48 48"
                    >
                      <g fill="none" strokeLinejoin="round" strokeWidth="4">
                        <path
                          fill="#007afe"
                          stroke="#007afe"
                          d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z"
                        />
                        <path
                          stroke="#fff"
                          strokeLinecap="round"
                          d="M16 24L22 30L34 18"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 48 48"
                    >
                      <g fill="none" strokeLinejoin="round" strokeWidth="4">
                        <path
                          fill="none"
                          stroke="#B9B9BF"
                          d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z"
                        />
                        <path
                          stroke="#B9B9BF"
                          strokeLinecap="round"
                          d="M16 24L22 30L34 18"
                        />
                      </g>
                    </svg>
                  )}
                  {!discordMe ? (
                    <div className="arc-rightsidebar-token-card-info">
                      <h5
                        style={{
                          color: theme === 'light' ? 'black' : 'white',
                          margin: 0,
                        }}
                      >
                        Connect to Discord
                      </h5>
                      <p style={{ margin: 0 }}>Tap here to connect</p>
                    </div>
                  ) : (
                    <div className="arc-rightsidebar-token-card-info">
                      <h5
                        style={{
                          color: theme === 'light' ? 'black' : 'white',
                          margin: 0,
                          cursor: 'pointer',
                        }}
                      >
                        {discordMe.username}
                      </h5>
                      <div>
                        <a
                          href={DISCORD_LINK}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          Join our channel
                        </a>
                      </div>
                      <div style={{ margin: 0, cursor: 'pointer' }}>
                        Tap here to disconnect
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="arc-rightsidebar-token-card"
                style={{
                  border: telegramData
                    ? '2px solid #007AFF'
                    : '2px solid #a5a5a5',
                }}
                onClick={() => handleClickTelegramCard()}
              >
                {telegramData && telegramData.photo_url ? (
                  <div
                    style={{
                      maxHeight: 113,
                      display: 'flex',
                      alignItems: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={telegramData.photo_url}
                      alt="telegram img"
                      className="social-connect-list-row-info-img"
                    />
                  </div>
                ) : (
                  <>
                    <img src={TelegramImg} alt="telegram img" />
                  </>
                )}

                <div className="arc-rightsidebar-token-card-detail">
                  {!telegramData ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 48 48"
                    >
                      <g fill="none" strokeLinejoin="round" strokeWidth="4">
                        <path
                          fill="none"
                          stroke="#B9B9BF"
                          d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z"
                        />
                        <path
                          stroke="#B9B9BF"
                          strokeLinecap="round"
                          d="M16 24L22 30L34 18"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 48 48"
                    >
                      <g fill="none" strokeLinejoin="round" strokeWidth="4">
                        <path
                          fill="#007afe"
                          stroke="#007afe"
                          d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z"
                        />
                        <path
                          stroke="#fff"
                          strokeLinecap="round"
                          d="M16 24L22 30L34 18"
                        />
                      </g>
                    </svg>
                  )}
                  <div className="arc-rightsidebar-token-card-info">
                    <h5
                      style={{
                        color: theme === 'light' ? 'black' : 'white',
                        margin: 0,
                      }}
                      data-testid="telegram-user-name"
                    >
                      {telegramData
                        ? telegramData.first_name
                        : 'Connect to Telegram'}
                    </h5>
                    <p style={{ margin: 0 }}>
                      {!telegramData
                        ? `Tap here to connect`
                        : `Tap here to disconnect`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <button
                type="button"
                className={`arc-rightsidebar-main-social-step-btn ${
                  connectDiscord || connectTelegram ? '' : 'disabled'
                }`}
                onClick={() => setSocialConnectionRightBar(false)}
              >
                Confirm
              </button> */}
          </div>
        </div>
      </div>
      {/* </AskAI> */}
      <ARCModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          const timeout = setTimeout(() => {
            setSocialConnectionRightBar(true);
            clearTimeout(timeout);
          }, 300);
        }}
      >
        <div
          className={`depo__fulfill-modal  ${
            theme === 'light' ? 'bg-white' : 'bg-dark'
          } rounded-modal`}
        >
          <div className="p-4">
            <Typography
              className="title"
              style={{
                color: theme === 'light' ? '#007aff' : '#007aff',
                textAlign: 'center',
              }}
            >
              To connect your telegram account please click the button below and
              allow our bot access
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <TLoginButton
              key="TLbtn"
              botName="ARC_Archimedes_Bot"
              buttonSize={TLoginButtonSize.Large}
              lang="en"
              usePic={false}
              cornerRadius={20}
              onAuthCallback={async (user) => {
                const res = await ARCAPIService.UpdateTelegram(user);
                if (res) {
                  setOpenModal(false);
                  setTelegramData(user);
                  localStorage.setItem('@app:t', JSON.stringify(user));
                  const timeout = setTimeout(() => {
                    setSocialConnectionRightBar(true);
                    setConnectTelegram(true);
                    clearTimeout(timeout);
                  }, 300);
                }
              }}
              requestAccess="write"
            />
          </div>
        </div>
      </ARCModal>
    </div>
  );
};
export default ArcSocialConnectionRightSidebar;
