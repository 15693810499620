/* eslint-disable */
import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';
import { IProtocol } from '../interfaces/IProtocol';
import { IToken } from '../interfaces/IToken';
import { IUser } from '../interfaces/IUser';
import { __debounce } from '../utility/debounce';
import { OneInchService } from '../services/OneInchService';
import Web3ClientService from '../services/Web3ClientService';
import { ARCAPIService } from '../services/ARCAPIService';
import env from '../config/global-env';
import UniswapV2Service from '../services/UniswapV2Service';
import SupportedNetworks from '../interfaces/SupportedNetworks';
import Notification from 'components/Notification/Notification';
import {
  UserCollection,
  getUser,
  updateUserStatus,
  TokensCollection,
  GemsCollection,
} from 'connect/firebase';
import { doc, onSnapshot, query, where, deleteDoc } from 'firebase/firestore';
import { FireDB, addUser } from 'connect/firebase';
import { unsubscribe } from 'diagnostics_channel';
import { paymentOption, swapModeOption } from 'utility/constants';
import { ITxHistory } from 'interfaces/ITxHistory';
import { useConnect, useDisconnect } from 'wagmi';

interface AuthContextState {
  swapMode: string;
  setSwapMode: Function;
  tokens: IToken[];
  setTokens: Function;
  tokensForCrossSwap: IToken[];
  setTokensForCrossSwap: Function;
  toChainId: any;
  setToChainId: Function;
  protocols: IProtocol[];
  setProtocols: Function;
  user?: IUser;
  setUser: Function;
  chainId?: number;
  setChainId: Function;
  provider: any;
  setProvider: Function;
  symbols: any[];
  setSymbols: Function;
  uniqueSymbols: any[];
  setUniqueSymbols: Function;
  walletAvailableValue: number;
  setWalletAvailableValue: Function;
  balances: any;
  setUserBalances: Function;
  getBalanceFor: Function;
  isAuthenticated: boolean | undefined;
  setIsAuthenticated: Function;
  isTryConnect: boolean | undefined;
  setisTryConnect: Function;
  isLoading: boolean;
  setIsLoading: Function;
  walletLoading: boolean;
  setWalletLoading: Function;
  cexLoading: boolean;
  setCexIsLoading: Function;
  dexLoading: boolean;
  setDexIsLoading: Function;
  allOpenOrders: any[];
  setAllOpenOrders: Function;
  web3Provider: string | undefined;
  setWeb3Provider: Function;
  getAllBalances: Function;
  defaultCurrency: string;
  setDefaultCurrency: Function;
  defaultCurrencyAddr: string;
  ARCToken: IToken;
  exchangesAvaiableBalance: any;
  // getUserCexBalance: any;
  erc20TokensForStaticList: IToken[];
  disableFooter: boolean | false;
  setdisableFooter: Function;
  isAdvanceMode: boolean | false;
  setisAdvanceMode: Function;
  isAnonymous: boolean | false;
  setisAnonymous: Function;
  isRightBar: boolean | false;
  setisRightBar: Function;
  isLeftBar: boolean | false;
  setisLeftBar: Function;
  isDisconnect: boolean | undefined;
  setisDisconnect: Function;
  isTypeConnect: string;
  setisTypeConnect: Function;
  setAiVariables: Function;
  aiVariables: any;
  aiMode: boolean | false;
  setaiMode: Function;
  aiModeRegister: boolean | false;
  setaiModeRegister: Function;
  aiModeSwap: boolean | false;
  setaiModeSwap: Function;
  tempTokens: IToken[];
  settempTokens: Function;
  askAIInfo: any;
  setaskAIInfo: Function;
  pageMode: string | 'SWAP';
  setPageMode: Function;
  idAudit: any;
  setIdAudit: Function;
  chatMessages: string[];
  setChatMessages: Function;
  currentMessage: string;
  setCurrentMessage: Function;
  message: string;
  setMessage: Function;
  theId: string | null | undefined;
  settheId: Function;
  isGeneralMode: boolean | true;
  setisGeneralMode: Function;
  chats: any[];
  setChats: Function;
  oldToken: any;
  setoldToken: Function;
  isHide: boolean;
  setHide: Function;
  isArchmedesHover: boolean;
  setArchmedesHover: Function;
  isTyping: boolean;
  setIsTyping: Function;
  typingTextAudit: string;
  setTypingTextAudit: Function;
  isAudit: boolean;
  setisAudit: Function;
  isFirstView: boolean;
  setFirstView: Function;
  isShowAuditNotification: boolean;
  setShowAuditNotification: Function;
  walletAddress: string;
  setwalletAddress: Function;
  webConnector: any;
  setwebConnector: Function;
  walletAddresses: any[];
  setwalletAddresses: Function;
  isShowSearchBadge: boolean;
  setShowSearchBadge: Function;
  userAuthorize: Function;
  hasBalance: any[];
  setHasBalance: Function;
  sideBarActiveKey: number;
  setSideBarActvieKey: Function;
  discordToken: any;
  setDiscordToken: Function;
  tokenSidebar: IToken;
  setTokenSidebar: Function;
  isSocialConnectRightBar: boolean;
  setSocialConnectionRightBar: Function;
  auditMyToken: boolean;
  setAuditMyToken: Function;
  gemToken: boolean;
  setGemToken: Function;
  subscriptionId: string;
  setSubscriptionId: Function;
  giveawayUser: boolean;
  setGiveawayUser: Function;
  paymentType: string;
  setPaymentType: Function;
  auditNewToken: boolean;
  setAuditNewToken: Function;
  connectDiscord: boolean;
  setConnectDiscord: Function;
  connectTelegram: boolean;
  setConnectTelegram: Function;
  notificationData: any[];
  notificationGemData: any[];
  telegramData: any;
  setTelegramData: Function;
  tokenFromNotification: string;
  setTokenFromNotification: Function;
  getBalanceAfterSwap: Function;
  isDropdownOpen: boolean;
  setIsDropdownOpen: Function;
  customNotification: any;
  notification: Function;
  notificationStep: number;
  setNotificationStep: Function;
  userId: string;
  auditId: string | null;
  setAuditId: Function;
  openSubscriptionModal: boolean;
  setOpenSubscriptionModal: Function;
  openSubscriptionInfoModal: boolean;
  setOpenSubscriptionInfoModal: Function;
  selectedGem: string;
  setSelectedGem: Function;
  selectedGemAddress: string;
  setSelectedGemAddress: Function;
  showNewMessage: boolean;
  setShowNewMessage: Function;
  askHowCanHelp: boolean;
  setAskHowCanHelp: Function;
  isOrderDataFetching: boolean;
  setIsOrderDataFetching: Function;
  userTxHistory: ITxHistory[];
  setUserTxHistory: Function;
  isCookieModalVisible: boolean;
  setIsCookieModalVisible: Function;
  isAllowed: boolean;
  setIsAllowed: Function;
}

export const AuthContext = createContext<AuthContextState>(
  {} as AuthContextState,
);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [swapMode, setSwapMode] = useState<string>(swapModeOption.swap);
  const [protocols, setProtocols] = useState<IProtocol[]>([]);
  const [tokens, setTokens] = useState<IToken[]>([]);
  const [tokensForCrossSwap, setTokensForCrossSwap] = useState<IToken[]>([]);
  const [toChainId, setToChainId] = useState<any>(null);
  const [tempTokens, settempTokens] = useState<IToken[]>([]);
  const [user, setUser] = useState<IUser>({});
  const [chainId, setChainId] = useState<keyof SupportedNetworks>(env.CHAIN_ID);
  const [provider, setProvider] = useState<any>(null);
  const [symbols, setSymbols] = useState<any[]>([]);
  const [uniqueSymbols, setUniqueSymbols] = useState<any[]>([]);
  const [walletAvailableValue, setWalletAvailableValue] = useState<number>(0);
  const [balances, setUserBalances] = useState<any>({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isTryConnect, setisTryConnect] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [walletLoading, setWalletLoading] = useState(false);
  const [cexLoading, setCexIsLoading] = useState(true);
  const [dexLoading, setDexIsLoading] = useState(true);
  const [allOpenOrders, setAllOpenOrders] = useState<any[]>([]);
  const [exchangesAvaiableBalance, setExchangesAvaiableBalance] = useState<
    any[]
  >([]);
  const [web3Provider, setWeb3Provider] = useState<string | undefined>();
  const [defaultCurrency, setDefaultCurrency] = useState<string>('ETH');
  const [defaultCurrencyAddr] = useState<string>(
    '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  );
  const [disableFooter, setdisableFooter] = useState(false);
  const [isAdvanceMode, setisAdvanceMode] = useState(false);
  const [isAnonymous, setisAnonymous] = useState(false);
  const [isRightBar, setisRightBar] = useState(false);
  const [isSocialConnectRightBar, setSocialConnectionRightBar] =
    useState(false);
  const [isLeftBar, setisLeftBar] = useState(false);
  const [isDisconnect, setisDisconnect] = useState(false);
  const [isTypeConnect, setisTypeConnect] = useState(null);
  const [aiVariables, setAiVariables] = useState<any>(null);
  const [aiMode, setaiMode] = useState(true);
  const [aiModeRegister, setaiModeRegister] = useState(false);
  const [aiModeSwap, setaiModeSwap] = useState(false);
  const [askAIInfo, setaskAIInfo] = useState(null);
  const [pageMode, setPageMode] = useState('SWAP');
  const [idAudit, setIdAudit] = useState('');
  const [chatMessages, setChatMessages] = useState<string[]>([]);
  const [currentMessage, setCurrentMessage] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isHide, setHide] = useState<boolean>(false);
  const [theId, settheId] = useState<string>('');
  const [isGeneralMode, setisGeneralMode] = useState<boolean>(true);
  const [chats, setChats] = useState<any[]>([]);
  const [oldToken, setoldToken] = useState<any>(null);
  const [isArchmedesHover, setArchmedesHover] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [typingTextAudit, setTypingTextAudit] = useState('');
  const [isAudit, setisAudit] = useState<boolean>(false);
  const [isFirstView, setFirstView] = useState<boolean>(false);
  const [isShowAuditNotification, setShowAuditNotification] =
    useState<boolean>(false);

  const [walletAddress, setwalletAddress] = useState<string>('');
  const [webConnector, setwebConnector] = useState<any>(null);
  const [walletAddresses, setwalletAddresses] = useState<any[]>([]);
  const [isShowSearchBadge, setShowSearchBadge] = useState<boolean>(false);
  const [hasBalance, setHasBalance] = useState<any[]>([]);
  const [sideBarActiveKey, setSideBarActvieKey] = useState<number>(1);
  const [discordToken, setDiscordToken] = useState<any>(null);
  const [tokenSidebar, setTokenSidebar] = useState<IToken>(null);
  const [auditMyToken, setAuditMyToken] = useState<boolean>(false);
  const [gemToken, setGemToken] = useState<boolean>(false);
  const [subscriptionId, setSubscriptionId] = useState<string>('');
  const [giveawayUser, setGiveawayUser] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<string>('');
  const [auditNewToken, setAuditNewToken] = useState<boolean>(false);
  const [connectDiscord, setConnectDiscord] = useState<boolean>(false);
  const [connectTelegram, setConnectTelegram] = useState<boolean>(false);
  const [userFID, setUserFID] = useState<string>('');
  const [walletFAddress, setWalletFAddress] = useState<string>('');
  const [notificationData, setNotificationData] = useState<any[]>([]);
  const [notificationGemData, setNotificationGemData] = useState<any[]>([]);
  const [telegramData, setTelegramData] = useState<any>(null);
  const [tokenFromNotification, setTokenFromNotification] =
    useState<string>('');

  const [customNotification, setCustomNotification] = useState<any>(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [notificationStep, setNotificationStep] = useState<number>(0);
  const [userId, setUserId] = useState<string>('');
  const [auditId, setAuditId] = useState<string | null>(null);
  const [openSubscriptionModal, setOpenSubscriptionModal] =
    useState<boolean>(false);
  const [openSubscriptionInfoModal, setOpenSubscriptionInfoModal] =
    useState<boolean>(false);
  const [selectedGem, setSelectedGem] = useState<string>('');
  const [selectedGemAddress, setSelectedGemAddress] = useState<string>('');
  const [showNewMessage, setShowNewMessage] = useState<boolean>(false);
  const [askHowCanHelp, setAskHowCanHelp] = useState<boolean>(false);
  const [isCookieModalVisible, setIsCookieModalVisible] = useState<boolean>(
    localStorage.getItem('@app:cookie') !== 'enable',
  );
  const [isOrderDataFetching, setIsOrderDataFetching] =
    useState<boolean>(false);
  const [userTxHistory, setUserTxHistory] = useState<ITxHistory[]>([]);
  const [isAllowed, setIsAllowed] = useState<boolean>(true);
  const ARCToken = env.ERC20STATIC.filter((obj) => obj.name === 'ARC')[0];
  const erc20Tokens: any[] = [];

  const erc20TokensForStaticList =
    swapMode === swapModeOption.limitOrders
      ? [...env.ERC20STATIC.filter((item) => item.symbol !== 'ETH')]
      : [...env.ERC20STATIC];

  // Disconnect all wallets when mounted for the first time
  const { disconnect } = useDisconnect();
  const { connect } = useConnect();
  useEffect(() => {
    ARCAPIService.getIpLocation(setIsAllowed);
  }, []);

  useEffect(() => {
    let isSession = sessionStorage.getItem('@app:session');
    if (isSession === '1') {
      connect();
      sessionStorage.removeItem('@app:session');
    } else {
      disconnect();
    }
  }, []);

  const notification = (data: any) => {
    setCustomNotification(data);
  };

  const fetchProtocols = async () => {
    try {
      const protocols = await OneInchService.fetchProtocols();
      if (protocols) setProtocols(protocols);
    } catch (error) {
      // toast.error('Unable to get the protocol list');
    }
  };

  const getImportedTokens = () => {
    if (chainId) {
      const stored = localStorage.getItem('@app:imported-tokens');
      if (stored) {
        const tokens: IToken[] = JSON.parse(stored);
        return tokens.filter((token) => token.chainId === chainId);
      }
    }
    return [];
  };

  const fetchTokens = async () => {
    try {
      const anonMode = await localStorage.getItem('@app:anonymous-mode');

      const newMode = anonMode ? anonMode.includes('v1') : null;
      // setTokens([]);
      settempTokens([]);
      let tokens: any = [];
      if (newMode) {
        tokens = await ARCAPIService.getHoudiniToken();
        if (tokens) setTokens(tokens);
        const temp = await OneInchService.fetchTokens();

        if (chainId === 1) {
          temp.unshift(ARCToken);
          temp.unshift(...erc20Tokens);
        }
        const hasImportedTokens = getImportedTokens().filter(
          (token) => token.chainId === chainId,
        );
        temp.unshift(...hasImportedTokens);
        if (temp) {
          settempTokens(temp);
        }
      } else {
        tokens = await OneInchService.fetchTokens();
        //
        if (chainId === 1) {
          tokens.unshift(ARCToken);
          tokens.unshift(...erc20Tokens);
        }
        const hasImportedTokens = getImportedTokens().filter(
          (token) => token.chainId === chainId,
        );
        tokens.unshift(...hasImportedTokens);
        if (tokens) {
          setTokens(tokens);
        }
      }
    } catch (error) {
      // toast.error('Unable to get the token list.');
    }
  };

  const fetchTokensForCrossSwap = async () => {
    try {
      setTokensForCrossSwap([]);
      let tokens: any = [];
      tokens = await OneInchService.fetchTokens(toChainId);
      // //
      // if (chainId === 1) {
      //   tokens.unshift(ARCToken);
      //   tokens.unshift(...erc20Tokens);
      // }
      const hasImportedTokens = getImportedTokens().filter(
        (token) => token.chainId === chainId,
      );
      tokens.unshift(...hasImportedTokens);
      if (tokens) {
        setTokensForCrossSwap(tokens);
      }
    } catch (error) {
      // toast.error('Unable to get the token list.');
    }
  };

  const getAllBalances = async () => {
    setIsLoading(true);
    const account = walletAddress;
    if (web3Provider) {
      const web3Client = new Web3ClientService(
        web3Provider,
        account, // user.settings?.defaultWallet,
      );

      // console.log('Get all balances for account: ', account);

      try {
        const ethBalance = await web3Client.getBalanceEth();
        const xListTokens: any[] = tokens.filter(
          (token) => token.symbol !== 'ARC',
        );


        const lmt = 500;
        const pg: any = (xListTokens.length / lmt).toFixed(0);

        const paginate = (xListTokens: any[], page = 1, perPage = lmt) =>
          xListTokens.slice(perPage * (page - 1), perPage * page);
        let result: any = {};
        // console.log('Get all balances for account: ', JSON.stringify(xListTokens));
        let assetsUsers:any;
        const tokensN = localStorage.getItem('@app:tokens');

        if (typeof tokensN === 'string') {
          try {
            assetsUsers = JSON.parse(tokensN);
          } catch (error) {
            console.error('Error parsing JSON from localStorage:', error);
          }
        }
        // const assetsUsers =localStorage.getItem('@app:tokens');
        let xListTokens2: any[] = [];
        if (assetsUsers) {
          assetsUsers = assetsUsers.map(item => item.asset.address.toLowerCase()); // get only the addresses from x
          // console.log('Get all balances for account: ',  assetsUsers);
          xListTokens2 = tokens.filter(item => assetsUsers.includes(item.address.toLowerCase()));
          xListTokens2 = xListTokens2.filter(item => item._id !== "0xC82E3dB60A52CF7529253b4eC688f631aad9e7c2");

          // console.log('Get all balances for account 1: ',  xListTokens2);
        }

      if (xListTokens2.length > 0) {
        const rst = await web3Client.batchGetBalance(
          xListTokens2,
          defaultCurrency,
        );
        result = Object.assign(result, rst);
      }else{
        if (parseInt(pg) === 0) {
          const rst = await web3Client.batchGetBalance(
            xListTokens,
            defaultCurrency,
          );
          result = Object.assign(result, rst);
        } else {
          for (let i = 0; i < Number(pg); i++) {
            const rst = await web3Client.batchGetBalance(
              paginate(xListTokens, i + 1, lmt),
              defaultCurrency,
            );
            result = Object.assign(result, rst);
          }
        }
        // if (chainId === 1) {
        //   const rst1 = await web3Client.getBalance(ARCToken);
        //   const arcToken: any = ARCToken.address;
        //   if (!result[arcToken]) {
        //     result[arcToken] = rst1 ?? 0;
        //   } else {
        //     result[arcToken] = rst1 ?? 0;
        //   }
        // }
      }


        // const result = await web3Client.batchGetBalance(
        //   tokens.filter(
        //     (token) => token.symbol !== 'ARC',
        //   ),
        //   defaultCurrency,
        // );


        const balances = {
          ...result,
          [defaultCurrencyAddr]: ethBalance,
        };

        setUserBalances(balances);
        await getUserBalance(balances);
      } catch (error) {
        console.error(error);
        // Notification({
        //   type: 'warning',
        //   message: `Unable to get balances for the current network.`,
        // });
      }
    }

    setIsLoading(false);
  };

  const getUserBalance = async (balances: any) => {
    let balanceToken: any[] = [];

    await Promise.all(
      tokens.map(async (item) => {
        if (balances[item.address] > 0) {
          const usd =
            parseFloat(
              chainId === 1
                ? await ARCAPIService.getPriceUSDTWithAddress(
                    item.symbol,
                    item.address,
                    item.decimals,
                    chainId,
                  )
                : await ARCAPIService.getPriceUSDTWithAddressOtherChain(
                    item.symbol,
                    item.address,
                  ),
            ) * balances[item.address];
          balanceToken.push({
            ...item,
            balance: balances[item.address],
            balanceUsd: usd,
          });
        }
      }),
    );

    setHasBalance(balanceToken);
  };

  const getBalanceFor = async (symbol: string) => {
    if (balances[symbol]) return balances[symbol];
    return null;
  };

  const getBalanceAfterSwap = async (fromToken: IToken, toToken: IToken) => {
    const fromTokenBalance = balances[fromToken.address];
    const toTokenBalance = balances[toToken.address];

    const account = walletAddress;
    if (web3Provider) {
      const web3Client = new Web3ClientService(
        web3Provider,
        account, // user.settings?.defaultWallet,
      );
      const timer = setInterval(async () => {
        const _fromTokenBalance =
          fromToken.symbol === defaultCurrency
            ? await web3Client.getBalanceEth()
            : await web3Client.getBalance(fromToken);
        const _toTokenBalance =
          toToken.symbol === defaultCurrency
            ? await web3Client.getBalanceEth()
            : await web3Client.getBalance(toToken);

        if (
          Number(fromTokenBalance) === Number(_fromTokenBalance) &&
          Number(toTokenBalance) === Number(_toTokenBalance)
        ) {
          getAllBalances();
        } else {
          getAllBalances();
          clearInterval(timer);
        }
      }, 1000);
    }
    const timer = setInterval(() => {}, 1000);
  };

  const fetchDexData = () => {
    // __debounce(fetchProtocols, 250, 'fetchProtocols');
    __debounce(fetchTokens, 250, 'fetchTokens');
    // __debounce(initTokenWithPredefined, 250, 'initTokenWithPredefined');
  };

  const fetchCrossChainTokens = () => {
    __debounce(fetchTokensForCrossSwap, 100, 'fetchTokensFowCrossSwap');
    // __debounce(initTokenWithPredefined, 250, 'initTokenWithPredefined');
  };

  /**
   * When the network is changed, the application MUST reload all of its parameters
   * and fetch all its assets again in order to update the platform's data to the
   * current network. and reconnect to the BE
   */

  const handleUserWalletConnect = async (_isAuthenticated) => {
    if (!_isAuthenticated) {
      if (userFID.length > 0) {
        updateUserStatus(userFID, false, walletFAddress);
      }
      setWalletFAddress('');
    } else {
      const userFId = await getUser(walletAddress);
      if (userFId?.length > 0) {
        await updateUserStatus(userFId, true, walletAddress);
        setUserFID(userFId);
      } else {
        const res = await addUser(walletAddress);
        setUserFID(res);
      }
      setWalletFAddress(walletAddress);
    }
  };
  const userAuthorize = async (
    provider: any,
    chainId: any,
    account: any,
    CHAINS: any,
  ) => {
    if (!account) return;

    if (!CHAINS[chainId]) {
      setTokens([]);
      return Notification({
        type: 'error',
        title: 'Error',
        message: 'Unsupported network',
      });
      // throw new Error('Unsupported network');
    }
    const store = localStorage.getItem('@app:user');
    if (store) {
      const user: IUser = JSON.parse(store);
      setUser(user);
    } else {
      localStorage.removeItem('@app:jwt');
    }

    // if (isTypeConnect !== '3') {
    //   signer = provider.getSigner();
    //   address = await signer.getAddress();
    //   setwalletAddress(await signer.getAddress());
    // } else {
    //   address = account;
    //   setwalletAddress(address);
    // }
    setwalletAddress(account);

    const authorized = await ARCAPIService.verifyAuthorization();
    if (
      !authorized ||
      account?.toLowerCase() !== walletAddress?.toLowerCase()
    ) {
      const result = await ARCAPIService.authenticateSwap(
        account,
        // signHash as string,
      );
      if (result && !result.response) {
        setUser(result);
        // setProvider(provider.provider ? provider.provider : provider);
        setIsAuthenticated(true);
        //fetchiing userinfo from /ws/v2/user/AI/user-info/${chainId}
        Userinfo(chainId);
      } else {
        return Notification({
          type: 'error',
          title: 'Error',
          message:
            result.response?.data?.data?.message ??
            'Something bad happened while loging in',
        });
      }
    } else {
      setIsAuthenticated(true);
      // setProvider(provider.provider ? provider.provider : provider);
    }

    setWeb3Provider(CHAINS[chainId || 1].urls[0]);
    setChainId(chainId || undefined);

    const crossChainId =
      chainId !== env.DEFAULT_TO_CHAIN_ID
        ? env.DEFAULT_TO_CHAIN_ID
        : Object.keys(CHAINS).find((key) => key != chainId);
    setToChainId(crossChainId);

    return true;
  };

  const Userinfo = async (chainId: number) => {
    const user = await ARCAPIService.GetCustomToken(chainId);

    if (user && user.webApp) {
      setAuditMyToken(true);
    }
    if (user && user.gem) {
      setGemToken(true);
    }
    if (user && user.subscription && user.subscription.payment) {
      setPaymentType(user.subscription.payment);
      if (user.subscription.payment === paymentOption.stripe) {
        setSubscriptionId(user.subscription.session ?? '');
      } else if (user.subscription.payment === paymentOption.radom) {
        setSubscriptionId(user.subscription.subscriptionId ?? '');
      }
    }
    if (user && user.giveawayUser) {
      setGiveawayUser(true)
    }
    if (user && user.discordStatus) {
      setConnectDiscord(user.discordStatus === 'unmute');
    }
    if (user && user.telegramStatus) {
      setConnectTelegram(user.telegramStatus === 'unmute');
    }
    if (user && user.userId) {
      setUserId(user.userId);
    }
  };

  useEffect(() => {
    if (connectDiscord || connectTelegram) {
      setAuditNewToken(true);
    }
  }, [connectDiscord, connectTelegram]);
  useEffect(() => {
    //
    if (chainId && web3Provider) {
      env.CHAIN_ID = chainId;
      env.CURRENT_WEB3_PROVIDER = web3Provider;
      OneInchService.chainId = env.CHAIN_ID;
      UniswapV2Service.setNetwork(chainId, window.ethereum ?? web3Provider);
      localStorage.setItem('@app:lastConnectedChainId', chainId.toString());

      fetchDexData();
      // setTokens([]);
      settempTokens([]);
      setUserBalances({});
      setHasBalance([]);
    }
  }, [web3Provider, isAnonymous, chainId]);

  useEffect(() => {
    if (toChainId) fetchCrossChainTokens();
  }, [toChainId]);

  useEffect(() => {
    if (tokens.length && walletAddress && isAuthenticated) {
      __debounce(getAllBalances, 500, 'getAllBalancesForAccount');
      // getAllBalances();
    }
  }, [
    JSON.stringify(tokens),
    JSON.stringify(tempTokens),
    // user,
    walletAddress,
    // defaultCurrency,
    isAuthenticated,
  ]);

  useEffect(() => {
    console.log(`useEffect Userinfo(chainId): chainId`, chainId);
    Userinfo(chainId);
  }, [chainId]);

  useEffect(() => {
    getImportedTokens;
  }, [localStorage]);

  useEffect(() => {
    const anonMode = localStorage.getItem('@app:anonymous-mode');
    const discordtoken = localStorage.getItem('@app:d');
    const type = localStorage.getItem('@app:type');

    const newMode = anonMode ? anonMode.includes('v1') : null;
    setisTypeConnect(type);
    setDiscordToken(discordtoken ? JSON.parse(discordtoken) : null);
    if (newMode) {
      setisAnonymous(true);
    } else {
      setisAnonymous(false);
    }
    fetchDexData();
  }, []);

  useEffect(() => {
    const unsub = onSnapshot(UserCollection, (snapshot) => {
      const documents = [];
      snapshot.forEach((doc) => {
        documents.push({
          id: doc.id,
          ...doc.data(),
        });
      });
    });
    return () => unsub();
  }, []);

  // useEffect(() => {
  //   addUser('123456789');
  // }, [isRightBar]);
  useEffect(() => {
    handleUserWalletConnect(isAuthenticated);
    const wa = user?.settings?.defaultWallet ?? '';
    const q = query(TokensCollection, where('walletAddress', '==', wa));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const documents = [];
      snapshot.forEach((doc) => {
        documents.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      let dataSnapshots = [];
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          dataSnapshots.push({
            id: change.doc.id,
            ...change.doc.data(),
          });
        }
        if (change.type === 'modified') {
          dataSnapshots.push({
            id: change.doc.id,
            ...change.doc.data(),
          });
        }
        if (change.type === 'removed') {
          console.log('Removed token: ', change.doc.data());
        }
      });

      setNotificationData(dataSnapshots);
    });
    if (!isAuthenticated) {
      setNotificationData([]);
    }
    // }
    return () => unsubscribe();
  }, [JSON.stringify(user)]);

  useEffect(() => {
    handleUserWalletConnect(isAuthenticated);
    const wa = user?.settings?.defaultWallet ?? '';
    const q = query(GemsCollection, where('walletAddress', '==', wa));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const documents = [];
      snapshot.forEach((doc) => {
        documents.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      let dataSnapshots = [];
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          dataSnapshots.push({
            id: change.doc.id,
            ...change.doc.data(),
          });
        }
        if (change.type === 'modified') {
          dataSnapshots.push({
            id: change.doc.id,
            ...change.doc.data(),
          });
        }
        if (change.type === 'removed') {
          console.log('Removed token: ', change.doc.data());
        }
      });

      setNotificationGemData(dataSnapshots);
    });
    if (!isAuthenticated) {
      setNotificationGemData([]);
    }
    // }
    return () => unsubscribe();
  }, [JSON.stringify(user)]);

  // useEffect(() => {
  //   if (walletAddress?.length > 0) {
  //     ARCAPIService.getTransactionHistory(walletAddress).then((res) => {
  //       setUserTxHistory(res);
  //     });
  //   }
  // }, [walletAddress]);
  return (
    <AuthContext.Provider
      value={{
        swapMode,
        setSwapMode,
        protocols,
        setProtocols,
        tokens,
        setTokens,
        tokensForCrossSwap,
        setTokensForCrossSwap,
        toChainId,
        setToChainId,
        user,
        setUser,
        chainId,
        setChainId,
        provider,
        setProvider,
        symbols,
        setSymbols,
        uniqueSymbols,
        setUniqueSymbols,
        walletAvailableValue,
        setWalletAvailableValue,
        balances,
        setUserBalances,
        isAuthenticated,
        setIsAuthenticated,
        // getUserCexBalance,
        getBalanceFor,
        isLoading,
        setIsLoading,
        walletLoading,
        setWalletLoading,
        cexLoading,
        setCexIsLoading,
        dexLoading,
        setDexIsLoading,
        allOpenOrders,
        setAllOpenOrders,
        web3Provider,
        setWeb3Provider,
        getAllBalances,
        defaultCurrency,
        setDefaultCurrency,
        defaultCurrencyAddr,
        ARCToken,
        exchangesAvaiableBalance,
        erc20TokensForStaticList,
        isTryConnect,
        setisTryConnect,
        disableFooter,
        setdisableFooter,
        isAdvanceMode,
        setisAdvanceMode,
        isAnonymous,
        setisAnonymous,
        isRightBar,
        setisRightBar,
        isLeftBar,
        setisLeftBar,
        isDisconnect,
        setisDisconnect,
        isTypeConnect,
        setisTypeConnect,
        aiVariables,
        setAiVariables,
        aiMode,
        setaiMode,
        aiModeRegister,
        setaiModeRegister,
        aiModeSwap,
        setaiModeSwap,
        tempTokens,
        settempTokens,
        askAIInfo,
        setaskAIInfo,
        pageMode,
        setPageMode,
        idAudit,
        setIdAudit,
        chatMessages,
        setChatMessages,
        currentMessage,
        setCurrentMessage,
        message,
        setMessage,
        theId,
        settheId,
        isGeneralMode,
        setisGeneralMode,
        chats,
        setChats,
        oldToken,
        setoldToken,
        isHide,
        setHide,
        isArchmedesHover,
        setArchmedesHover,
        isTyping,
        setIsTyping,
        typingTextAudit,
        setTypingTextAudit,
        isAudit,
        setisAudit,
        isFirstView,
        setFirstView,
        isShowAuditNotification,
        setShowAuditNotification,
        walletAddress,
        setwalletAddress,
        webConnector,
        setwebConnector,
        walletAddresses,
        setwalletAddresses,
        isShowSearchBadge,
        setShowSearchBadge,
        userAuthorize,
        hasBalance,
        setHasBalance,
        sideBarActiveKey,
        setSideBarActvieKey,
        discordToken,
        setDiscordToken,
        tokenSidebar,
        setTokenSidebar,
        setSocialConnectionRightBar,
        isSocialConnectRightBar,
        auditMyToken,
        setAuditMyToken,
        gemToken,
        setGemToken,
        subscriptionId,
        setSubscriptionId,
        giveawayUser,
        setGiveawayUser,
        paymentType,
        setPaymentType,
        auditNewToken,
        setAuditNewToken,
        connectDiscord,
        setConnectDiscord,
        connectTelegram,
        setConnectTelegram,
        notificationData,
        notificationGemData,
        telegramData,
        setTelegramData,
        tokenFromNotification,
        setTokenFromNotification,
        getBalanceAfterSwap,
        isDropdownOpen,
        setIsDropdownOpen,
        customNotification,
        notification,
        notificationStep,
        setNotificationStep,
        userId,
        auditId,
        setAuditId,
        openSubscriptionModal,
        setOpenSubscriptionModal,
        openSubscriptionInfoModal,
        setOpenSubscriptionInfoModal,
        selectedGem,
        setSelectedGem,
        selectedGemAddress,
        setSelectedGemAddress,
        showNewMessage,
        setShowNewMessage,
        askHowCanHelp,
        setAskHowCanHelp,
        isOrderDataFetching,
        setIsOrderDataFetching,
        userTxHistory,
        setUserTxHistory,
        isCookieModalVisible,
        setIsCookieModalVisible,
        isAllowed,
        setIsAllowed,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
