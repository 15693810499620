/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-void */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import { Button, Spin } from 'antd';
import { AuthContext } from 'contexts/AuthProvider';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ARCAPIService } from 'services/ARCAPIService';
import { __debounce } from 'utility/debounce';
import {
  useAccount,
  useChainId,
  useConnect,
  useConnectors,
  useDisconnect,
  useSwitchChain,
} from 'wagmi';
import MetamaskIcon from '../assets/MetaMask_Fox 1.svg';
import BalanceCard from './balancecard';
import { CHAINS } from './chains';
import NetworkCard from './networkcard';
import './style.scss';
import UserCard from './usercard';

type MetamaskProps = {
  isTopBar?: boolean;
};

const MetamaskCard = ({ isTopBar }: MetamaskProps) => {
  const {
    setUser,
    setUserBalances,
    setWalletAvailableValue,
    setUniqueSymbols,
    setSymbols,
    setIsLoading,
    setisDisconnect,

    setAllOpenOrders,
    setIsAuthenticated,
    setProvider,
    isLoading,
    setwalletAddress,
    walletAddress,
    chainId,
    setisRightBar,
    isAuthenticated,
    setisTypeConnect,
    userAuthorize,
    hasBalance,
    setSubscriptionId,
    setPaymentType,
  } = useContext(AuthContext);

  const activeChainId = useChainId();
  const { address, connector } = useAccount();
  const { connect } = useConnect();
  const connectors = useConnectors();
  const { disconnect } = useDisconnect();
  const { switchChain } = useSwitchChain();
  const isActive = !!connector?.name.toLowerCase().includes('metamask');

  const [desiredChainId, setDesiredChainId] = useState(chainId);

  // const mobile = isMobile;
  const mobile = useMediaQuery({ maxWidth: 991 });
  const handleActivate = async () => {
    connect({
      connector: connectors.find((connector) =>
        connector.name.toLowerCase().includes('metamask'),
      ),
    });
    localStorage.setItem('@app:type', 'metamask');
    setisTypeConnect('metamask');
    if (mobile) {
      setisRightBar(false);
    }
    if (window.gtag) {
      window.gtag('event', 'wallet_connection');
    }
  };

  const handleSwitchChain = async (chainId: number) => {
    setDesiredChainId(chainId);
    localStorage.removeItem('@app:jwt');
    localStorage.removeItem('@app:user');
    switchChain({ chainId });
  };

  const handleDeactivate = async () => {
    disconnect();
    setUser({});
    setProvider(null);
    setUserBalances({});
    setWalletAvailableValue(0);
    setUniqueSymbols([]);
    setSymbols([]);
    setAllOpenOrders([]);
    setIsAuthenticated(false);
    setisDisconnect(true);
    setIsLoading(false);

    ARCAPIService.logout();
  };

  useEffect(() => {
    if (address && isActive) {
      setwalletAddress(address);
      setUser(address);
      window.fbq('track', 'AddPaymentInfo');
    } else {
      setisDisconnect(true);
      setSubscriptionId('');
      setPaymentType('');
    }
  }, [address, isActive]);

  const callUserAuthorize = () => {
    userAuthorize(null, activeChainId, address, CHAINS);
  };
  useEffect(() => {
    if (isActive && address) {
      __debounce(callUserAuthorize, 200, 'userAuthorize');
    }
  }, [desiredChainId, activeChainId, isActive, address]);

  return (
    <>
      {isActive && isAuthenticated ? (
        <>
          {isTopBar ? (
            <div className="card-top">
              <NetworkCard
                activeChain={activeChainId}
                onselect={handleSwitchChain}
              />
              {!mobile && <UserCard account={walletAddress} />}
            </div>
          ) : (
            <div>
              <BalanceCard
                tokenBalance={hasBalance}
                account={walletAddress}
                isLoading={isLoading}
                onDeactivate={handleDeactivate}
                icon={MetamaskIcon}
              />
            </div>
          )}
        </>
      ) : (
        <>
          {!isAuthenticated && !isTopBar ? (
            <Button
              className="btn-connect-right"
              onClick={() => handleActivate()}
            >
              <div className="btn-connect-right-inside">
                <Spin spinning={false} />

                <img src={MetamaskIcon} alt="Metamask" height={40} width={40} />
                <div>
                  <span className="btn-right-subtile">Metamask</span>
                </div>
              </div>
            </Button>
          ) : null}
        </>
      )}
    </>
  );
};

export default MetamaskCard;
