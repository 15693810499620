/* eslint-disable */
import { Button, Modal } from 'antd';
import React, { useContext } from 'react';
import BitcoinIcon from '../../assets/icons/bitcoin.svg';
import { ThemeContext } from '../../contexts/ThemeContext';
import ARCLogo from '../../assets/arc-svg-logo.svg';
import './style.scss';
import { useMediaQuery } from 'react-responsive';

const CookieModal: React.FC<{
  showPrivacyPolicy: Function;
  visible: boolean;
  onClick: Function;
  onClose: Function;
}> = ({ visible, onClose, showPrivacyPolicy }) => {
  const { theme } = useContext(ThemeContext);
  const mobile = useMediaQuery({ maxWidth: 991 });
  const onCloseHandler = () => {
    localStorage.setItem('@app:cookie', 'enable');
    onClose();
  };

  const feedbackText = () => {
    return (
      <div style={{ fontSize: '16px' }}>
        By clicking "Got it" on this banner or using our site, you agree to the
        {` `}
        <span
          style={{ color: 'var(--brand-swap-secondary)', cursor: 'pointer' }}
          onClick={($e) => showPrivacyPolicy()}
        >
          Privacy Policy
        </span>{' '}
        and the use of local browser data, unless you have disabled them.
      </div>
    );
  };

  return (
    <Modal
      destroyOnClose
      closeIcon={false}
      open={visible}
      className="depo__modal-selector d-flex justify-center depo__ant-modal-mask"
      wrapClassName="modal_wrap"
      style={{
        height: '270px',
        backgroundColor: '#0f111e',
        borderRadius: '27px',
        position: 'absolute',
        right: mobile ? '0px' : '30px',
        bottom: mobile ? '0px' : '50px',
        width: '540px',
        top: 'unset',
      }}
    >
      <div
        className={`depo__fulfill-modal  ${
          theme === 'light' ? 'bg-white' : 'bg-dark'
        } rounded-modal`}
      >
        <div className="depo__token-select-tab">
          <div
            className={
              theme === 'light'
                ? 'depo__token-select-mainpanel-light'
                : 'depo__token-select-mainpanel-dark'
            }
          >
            <div className="p-4">
              <div
                className="feedback-modal-list"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    textAlign: 'center',
                    zIndex: 10000,
                    marginBottom: '14px',
                  }}
                >
                  <img
                    src={ARCLogo}
                    alt="ARC"
                    width={50}
                    height={50}
                  />
                </div>

                {feedbackText()}
                <div className="text-center" style={{ marginTop: '1rem' }}>
                  <Button
                    className={
                      'btn-connect btn-md text-theme-light rounded-md bg-swap-secondary'
                    }
                    style={{
                      border: 'none',
                      color: 'white !important',
                      fontFamily: 'Gilroy-bold',
                      width: '100px',
                    }}
                    onClick={onCloseHandler}
                    block
                  >
                    Got it
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CookieModal;
