/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import {
  Layout,
  Grid,
  Row,
  Col,
  Button,
  Dropdown,
  MenuProps,
  Modal,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import DisclaimerModal from 'components/disclaimermodal';
import PrivateAndPolicyModal from 'components/privateandpolicy';
import FeedbackModal from 'components/FeedbackModal';
import AIDisclaimerModal from 'components/aimode/aimodal';
import CookieModal from 'components/CookieModal';
import CustomNotification from 'components/Notification/CustomNotification';
import SubscriptionModal from 'components/Modal/SubscriptionModal';
import { useMediaQuery } from 'react-responsive';
import SubscriptionInfoModal from 'components/Modal/SubscriptionInfoModal';
import { ThemeContext } from '../../contexts/ThemeContext';
import { AuthContext } from '../../contexts/AuthProvider';
import ARCMenuHeader from './arcMenuHeader';
import ARCLeftSidebar from './arcLeftSidebar';
import ARCRightSidebar from './arcRightSidebar';
import ArcSocialConnectionRightSidebar from './arcSocialConnectionRightSidebar';
import SecurityIcon from '../../assets/icons/security.svg';

const { Header, Content, Footer } = Layout;

type DefaultLayoutProps = {
  children: React.ReactNode;
};
const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  const breakpoints = Grid.useBreakpoint();
  const { pathname } = useLocation();
  const history = useHistory();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [pathName, setpathName] = useState('global');
  const [selectModal, setSelectModal] = useState(false);
  const [selectFeedbackModal, setSelectFeedbackModal] = useState(false);
  const [selectPrivateModal, setselectPrivateModal] = useState(
    pathname === '/privacy-policy',
  );
  const {
    disableFooter,
    aiModeRegister,
    setaiModeRegister,
    user,
    isAuthenticated,
    isLeftBar,
    setisLeftBar,
    pageMode,
    setPageMode,
    isArchmedesHover,
    isCookieModalVisible,
    setIsCookieModalVisible,
  } = useContext(AuthContext);
  const toggleNavbar = () => setIsCollapsed(!isCollapsed);
  const [displayFooter, setdisplayFooter] = useState(true);
  const [navbar, setNavbar] = useState(false);
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const isBrowserDefaulDark = () =>
    window.matchMedia('(prefers-color-scheme: dark)').matches;
  const getDefaultTheme = (): string => {
    const localStorageTheme = localStorage.getItem('default-theme');
    const browserDefault = 'dark';
    return localStorageTheme || browserDefault;
  };
  const [theme, setTheme] = useState(getDefaultTheme());
  // const mobile = isMobile;
  const mobile = useMediaQuery({ maxWidth: 767 });
  const screenWidth = window.innerWidth;

  const toggleTheme = () => {
    let clr: string;
    if (theme === 'light') {
      clr = '#e7e7e7';
    } else {
      clr = '#121010';
    }
    return clr;
  };
  const handleMenuClick: MenuProps['onClick'] = async (e: any) => {
    if (e.key === '1') {
      setSelectFeedbackModal(true);
    }
    if (e.key === '2') {
      setselectPrivateModal(true);
    }
    if (e.key === '3') {
      setSelectModal(true);
    }
  };

  const items: MenuProps['items'] = [
    {
      label: 'Feedback',
      key: '1',
    },
    {
      label: 'Privacy policy',
      key: '2',
    },
    {
      label: 'Disclaimer',
      key: '3',
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
    style: {
      color: theme === 'light' ? '#000' : '#fff',
      backgroundColor: theme === 'light' ? '#fff' : '#000',
    },
  };

  useEffect(() => {
    const unlisten = history.listen(() =>
      setIsCollapsed(breakpoints.xl !== true),
    );
    return () => unlisten();
  }, [history, breakpoints]);
  useEffect(() => {
    setIsCollapsed(breakpoints.xl !== true);
  }, [breakpoints]);
  useEffect(() => {
    if (theme === 'light') {
      document.body.classList.add('light');
      document.body.classList.remove('dark');
    } else {
      document.body.classList.remove('light');
      document.body.classList.add('dark');
    }
  }, [theme]);
  useEffect(() => {
    setdisplayFooter(!disableFooter);
  }, [disableFooter, aiModeRegister]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className={`theme-${theme}`} style={{ position: 'relative' }}>
        {/* {!mobile && pathname === '/swap' && pageMode !== 'CHAT' && (
          <div id="leftnav" className="sidenav">
            <a
              onClick={() => {
                if (pageMode === 'SWAP') {
                  setPageMode('CHAT');
                }
                if (pageMode === 'AUDIT') {
                  setPageMode('SWAP');
                }
              }}
              className="chatnavLeft"
              style={{
                backgroundImage: "url('sideButtonLeft.svg')",
              }}
            >
              {pageMode === 'SWAP' ? 'ARCHIMEDES' : 'SWAPS AI'}
            </a>
          </div>
        )} */}

        <Layout className="layout">
          <ARCMenuHeader
            onFeedBackModal={(value) => {
              if (value === '1') setSelectFeedbackModal(true);
              if (value === '2') setselectPrivateModal(true);
            }}
          />
          <Content
            className={
              mobile
                ? `container-xl content-wrapper-mobile`
                : `container-xl content-wrapper`
            }
          >
            {children}
          </Content>
          <ARCLeftSidebar />
          <ARCRightSidebar />
          <ArcSocialConnectionRightSidebar />
          {/* show footer when desktop */}
          {!mobile && screenWidth >= 1740 && (
            <div className="footer-left">
              ARC SwapsAI
              <span
                style={{
                  color: '#007aff',
                  border: '1px solid #007aff',
                  borderRadius: '5px',
                  padding: '5px',
                  marginLeft: '5px',
                }}
              >
                Beta
              </span>
            </div>
          )}

          {!mobile && (
            <div className="arc_header_menu_footer footer-right">
              <a
                href="https://skynet.certik.com/projects/arc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={SecurityIcon}
                  alt="security"
                  width={21}
                  height={21}
                  style={{ marginTop: '4px', display: 'inline-block' }}
                />
              </a>
              <Dropdown
                menu={menuProps}
                overlayClassName="network-panel__overlay"
              >
                <Button type="link">
                  <QuestionCircleOutlined style={{ fontSize: '20px' }} />
                </Button>
              </Dropdown>
            </div>
          )}

          <DisclaimerModal
            onSelect={() => {
              setSelectModal(false);
            }}
            visible={selectModal}
            onClose={() => {
              setSelectModal(false);
            }}
            mobile={mobile}
          />
          <PrivateAndPolicyModal
            onSelect={() => {
              setselectPrivateModal(false);
            }}
            visible={selectPrivateModal}
            onClose={() => {
              setselectPrivateModal(false);
            }}
            mobile={mobile}
          />
          <FeedbackModal
            onSelect={() => {
              setSelectFeedbackModal(true);
            }}
            visible={selectFeedbackModal}
            onClose={() => {
              setSelectFeedbackModal(false);
            }}
            mobile={mobile}
          />
          <AIDisclaimerModal
            onSelect={() => {
              console.log('select');
            }}
            visible={aiModeRegister}
            onClose={() => {
              setaiModeRegister(false);
            }}
            mobile={mobile}
          />
          <CookieModal
            onClick={undefined}
            visible={isCookieModalVisible}
            onClose={() => {
              setIsCookieModalVisible(false);
            }}
            showPrivacyPolicy={() => {
              setselectPrivateModal(true);
            }}
          />
        </Layout>
        {/* {!mobile && pathname === '/swap' && pageMode !== 'AUDIT' && (
          <div id="rightnav" className="sidenav">
            <a
              onClick={() => {
                if (pageMode === 'CHAT') {
                  setPageMode('SWAP');
                }
                if (pageMode === 'SWAP') {
                  setPageMode('AUDIT');
                }
              }}
              className="chatnavRight"
              style={{
                backgroundImage: 'url(sideButtonRight.svg)',
              }}
            >
              {pageMode === 'CHAT' ? 'SWAPS AI' : 'AUDIT'}
            </a>
          </div>
        )} */}
        {/* {isArchmedesHover && (
          <div
            className="chat-left-body-logo-modal"
            style={{
              backgroundColor: theme === 'light' ? '#959597' : '#2d2d31',
              color: 'white',
            }}
          >
            Archimedes of Syracuse Born 15 Sept 2023
          </div>
        )} */}
        {/* <CustomNotification />
        <SubscriptionModal />
        <SubscriptionInfoModal /> */}
      </div>
    </ThemeContext.Provider>
  );
};
export default DefaultLayout;
