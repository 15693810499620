/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable arrow-body-style */
import { Button, Skeleton } from 'antd';
import { AuthContext } from 'contexts/AuthProvider';
import React, { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { MetaMaskAvatar } from 'react-metamask-avatar';
import { shortenAddress, shortenAddressMobile } from 'utility/addressHelpers';
import './style.scss';

const UserCard: React.FC<{
  account: string;
}> = ({ account }) => {
  const { setisRightBar, isRightBar, isLoading } = useContext(AuthContext);
  const mobile = isMobile;

  const showBar = () => {
    setisRightBar(!isRightBar);
  };

  return (
    <div className="card-top-account">
      <Button
        className={`d-flex btn-wallet btn-sm rounded m-0 ${
          isLoading && 'shimmer'
        }`}
        style={{
          alignItems: 'center',
          display: 'flex',
          borderColor: '#676767',
          padding: '4px 9px',
        }}
        onClick={showBar}
      >
        {account && !mobile && <MetaMaskAvatar address={account || ''} />}

        <div style={{ marginLeft: '0.8em', fontSize: '0.8em' }}>
          {mobile
            ? shortenAddressMobile(account || '')
            : shortenAddress(account || '')}
        </div>
      </Button>
    </div>
  );
};

export default UserCard;
