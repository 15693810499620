/* eslint-disable */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, Typography, Input, Image, Row, Col, Checkbox } from 'antd';
import { ThemeContext } from '../../contexts/ThemeContext';
import ARCModal from '../ARCModal/ARCModal';
import './style.scss';
import closeImg from '../../assets/close.svg';
import closeImgBlack from '../../assets/close_black.svg';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

const FeedbackModal: React.FC<{
  onSelect: Function;
  visible: boolean;
  onClose: () => void;
  mobile?: boolean;
}> = ({ onSelect, visible, onClose, mobile }) => {
  const { theme } = useContext(ThemeContext);
  const [modalIndex, setModalIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isDisable, setisDisable] = useState(true);
  const listInnerRef = useRef<HTMLDivElement>(null);

  const onChange = (e: CheckboxChangeEvent) => {
    setisDisable(!e.target.checked);
  };
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop + clientHeight + 3 > scrollHeight) {
        setisDisable(false);
      }
    }
  };
  const onCloseHandler = () => {
    localStorage.setItem('@app:disclaimer', 'read');
    setModalIndex(0);
    onClose();
  };

  const feedbackText = () => {
    return (
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSdEr5I7ENJuWgVvWJ5oxG2cqAa85APi-p6Zi4OBQZLF2Dyq9Q/viewform?embedded=true"
        width="100%"
        height="100%"
        frameBorder="0"
      >
        Loading…
      </iframe>
    );
  };

  return (
    <>
      <ARCModal open={visible} onClose={onClose}>
        <div
          className={`depo__fulfill-modal  ${
            theme === 'light' ? 'bg-white' : 'bg-dark'
          } rounded-modal`}
        >
          <Button
            type="link"
            className="btn-close mt-3 mr-4"
            onClick={onCloseHandler}
          >
            <img
              src={theme === 'light' ? closeImgBlack : closeImg}
              alt="down-arrow"
            />
          </Button>
          <div className="depo__token-select-tab">
            <div
              className={
                theme === 'light'
                  ? 'depo__token-select-mainpanel-light'
                  : 'depo__token-select-mainpanel-dark'
              }
            >
              <div className="p-4" style={{ height: '532px' }}>
                <Typography
                  className="title"
                  style={{
                    color: theme === 'light' ? '#007aff' : '#007aff',
                    textAlign: 'center',
                  }}
                >
                  Feedback
                  <article
                    className="ant-typography h6"
                    style={{ color: 'rgb(77 77 77)', textAlign: 'center' }}
                  >
                    Let us know what you think about the app
                  </article>
                </Typography>

                {/* disclaimer text */}
                <div
                  className="feedback-modal-list"
                  style={{ height: '400px' }}
                  ref={listInnerRef}
                  onScroll={onScroll}
                >
                  {feedbackText()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ARCModal>
    </>
  );
};

export default FeedbackModal;
