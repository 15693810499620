/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/order */
import type { MenuProps } from 'antd';
import { Button, Dropdown, Grid } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
// import { isMobile } from 'react-device-detect';
import CoinbaseCard from 'connect/coinbasecard';
import MetamaskCard from 'connect/metamaskcard';
import TrustwalletCard from 'connect/trustwalletcard';
import WalletConnectCard from 'connect/walletconnectcard';
import { useMediaQuery } from 'react-responsive';

import { Header } from 'antd/lib/layout/layout';
import LoginButton from 'components/ConnectButton';
import NotificationDropDown from 'components/NotificationDropDown';
import { Link, useLocation } from 'react-router-dom';
import arcReactorLogoLight from '../../assets/main-logo-light.png';
import arcReactorLogo from '../../assets/main-logo.png';
import mobileMenuLight from '../../assets/mobile-menu-button-light.svg';
import mobileMenu from '../../assets/mobile-menu-button.svg';
import moon from '../../assets/moon.svg';
import sun from '../../assets/sun.svg';
import { AuthContext } from '../../contexts/AuthProvider';
import { ThemeContext } from '../../contexts/ThemeContext';
import { DappItems } from './menu-items';
import './style.scss';
import { useAccount, useConnections } from 'wagmi';

const ARCMenuHeader: React.FC<{
  onFeedBackModal: (feedback: string) => void;
}> = ({ onFeedBackModal }) => {
  const { pathname } = useLocation();
  const [cexBalance, setCexBalance] = useState<string>('');
  const [metamaskBalance, setMetamaskBalance] = useState('0.000000');
  const [loadingDex, setLoadingDex] = useState(false);
  const [loadingCex, setLoadingCex] = useState(false);
  const menuItems = DappItems;
  const {
    user,
    balances,
    walletAvailableValue,
    chainId,
    defaultCurrency,
    isAuthenticated,
    setDexIsLoading,
    setCexIsLoading,
    web3Provider,
    defaultCurrencyAddr,
    aiMode,
    isLoading,
    setaiModeRegister,
    isLeftBar,
    setisLeftBar,
    pageMode,
  } = useContext(AuthContext);

  const breakpoints = Grid.useBreakpoint();
  // const mobile = isMobile;
  const mobile = useMediaQuery({ maxWidth: 991 });

  const { connector } = useAccount();
  const connectorName = connector?.name.toLowerCase();

  const { theme, setTheme } = useContext(ThemeContext);
  const [selectedPageKey, setSelectedPageKey] = useState('');
  const [openKey, setOpenKey] = useState('');
  const [navbar, setNavbar] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [renderWallet, setRenderWallet] = useState(<LoginButton />);
  const getMetamaskBalance = async () => {
    if (balances[defaultCurrencyAddr]) {
      setLoadingDex(false);
      setMetamaskBalance((+balances[defaultCurrencyAddr]).toFixed(6));
    }
  };
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Feedback',
      style: {
        color: theme === 'light' ? '#000' : '#fff',
        fontSize: '12px',
      },
    },
    {
      key: '2',
      label: 'Privacy Policy',
      style: {
        color: theme === 'light' ? '#000' : '#fff',
        fontSize: '12px',
      },
    },
  ];
  const handleMenuClick: MenuProps['onClick'] = async (e: any) => {
    onFeedBackModal(e.key);
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
    style: {
      color: 'white',
    },
  };
  const handleThemeChange = () => {
    const isCurrentDark = theme === 'dark';
    setTheme(isCurrentDark ? 'light' : 'dark');
    localStorage.setItem('default-theme', isCurrentDark ? 'light' : 'dark');
  };
  const enableAIMode = (isEnable: boolean) => {
    if (isEnable) {
      setaiModeRegister(true);
    }
  };
  useEffect(() => {
    if (!isAuthenticated) {
      setMetamaskBalance('0.000000');
      setCexBalance('');
      setRenderWallet(<LoginButton />);
    } else {
      setLoadingDex(true);
      setLoadingCex(true);

      if (connectorName.includes('connect')) {
        setRenderWallet(<WalletConnectCard isTopBar />);
      } else if (connectorName.includes('metamask')) {
        // render <h1>connect</h1>
        setRenderWallet(<MetamaskCard isTopBar />);
      } else if (connectorName.includes('coinbase')) {
        setRenderWallet(<CoinbaseCard isTopBar />);
      } else if (connectorName.includes('trust')) {
        setRenderWallet(<TrustwalletCard isTopBar />);
      } else {
        setRenderWallet(<LoginButton />);
      }
    }
    if (!user?.exchanges || !user.exchanges.length) {
      setLoadingCex(false);
    }
  }, [isAuthenticated]);
  useEffect(() => {
    setCexBalance(parseFloat(walletAvailableValue?.toString()).toFixed(6));
    if (loadingCex) setLoadingCex(!loadingCex);
  }, [walletAvailableValue]);
  const changeBackground = () => {
    //
    if (window.scrollY >= 60) {
      //
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  // Update context loaders to spread all over the dashboard
  useEffect(() => {
    setDexIsLoading(loadingDex);
  }, [loadingDex]);
  useEffect(() => {
    setCexIsLoading(loadingCex);
  }, [loadingCex]);
  useEffect(() => {
    const menuItem = menuItems.find((item) => item.path.includes(pathname));
    if (menuItem) {
      setSelectedPageKey(menuItem.key.toString());
      setOpenKey('');
    } else if (pathname.includes('market')) {
      setOpenKey('3');
      setSelectedPageKey(pathname.includes('spot') ? '3.1' : '3.2');
    } else if (pathname.includes('/nft/stats')) {
      setOpenKey('6');
      setSelectedPageKey(pathname.includes('rankings') ? '6.1' : '6.2');
    } else if (pathname.includes('/legal')) {
      setOpenKey('8');
      let key = '';
      if (pathname.includes('/risk-statement')) {
        key = '8.1';
      } else if (pathname.includes('/republication-guidelines')) {
        key = '8.2';
      } else if (pathname.includes('/republication-guidelines')) {
        key = '8.3';
      }
      setSelectedPageKey(key);
    }
  }, [pathname]);
  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground);
  }, []);

  const showLeft = () => {
    setisLeftBar(!isLeftBar);
  };

  return (
    <>
      {pageMode !== 'CHAT' && (
        // {(!mobile || isHide) && (
        <Header
          className={navbar ? 'layout-header scroll-up' : 'layout-header'}
          // className="layout-header"
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            borderBottom: !mobile ? '1px rgb(83 81 128 / 36%) solid' : '',
          }}
        >
          <div className="layout-header-menu container-xl">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a
                href="https://farming.helloarc.ai"
                className="d-flex align-center logo-top"
                style={{
                  paddingRight: 0,
                  paddingLeft: 0,
                  marginRight: !mobile ? '50px' : '0px',
                }}
              >
                <img
                  src={theme === 'light' ? arcReactorLogoLight : arcReactorLogo}
                  style={{
                    borderRadius: '50%',
                    padding: '5px',
                  }}
                  height={!mobile ? 52 : 35}
                  alt="Logo"
                />
              </a>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="arc_header_menu-right">
                {/* {!mobile && type !== '3' && <NetworkPop />}
            {mobile || (type === '3' && <NetworkMobile />)} */}

                {/* <NotificationDropDown /> */}
                {!mobile && (
                  <Button
                    type="text"
                    className="btn-themes"
                    style={{
                      background: 'transparent !important',
                      border: '1px solid #676767',
                      borderRadius: '50%',
                      width: 40,
                      height: 40,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: '1em',
                    }}
                    onClick={handleThemeChange}
                  >
                    {theme === 'light' ? (
                      <img src={sun} height={20} alt="Light" />
                    ) : (
                      <img src={moon} height={20} alt="Dark" />
                    )}
                  </Button>
                )}
                {renderWallet}
              </div>
              {mobile && (
                <Button type="link" className="btn-ham-menu" onClick={showLeft}>
                  {/* <MenuUnfoldOutlined /> */}
                  <img
                    src={theme === 'dark' ? mobileMenu : mobileMenuLight}
                    alt="mobile-menu-icon"
                  />
                </Button>
              )}
            </div>
          </div>
        </Header>
      )}
    </>
  );
};
export default ARCMenuHeader;
