/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosInstance } from 'axios';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import env from 'config/global-env';
import Notification from 'components/Notification/Notification';
import farmsConfig from '../../config/constants/jungleFarms';
import jungleABI from '../../utility/abi/ArcJungle.json';
import standardAbiEth from '../../utility/abi/standard-abi.eth';
import { getAddress } from '../../utility/addressHelpers';
import multicall from '../../utility/multicall';
import { ARCAPIService } from '../../services/ARCAPIService';

const BLOCK_PER_DAY = 6500;
const BLOCK_PER_YEAR = 365 * BLOCK_PER_DAY;

const fetchJungleFarms = async (web3Provider: string, chainId: number) => {
  const addressData: any = [];
  const filterFarmConfig = farmsConfig.filter((obj) => obj.type === 'ARC');
  const data = await Promise.all(
    filterFarmConfig.map(async (farmConfig) => {
      const address = getAddress(farmConfig.contractAddress, chainId);
      const [rewardPerBlock, stakedTokenTransferFee] = await multicall(
        jungleABI,
        [
          { address, name: 'rewardPerBlock' },
          { address, name: 'stakedTokenTransferFee' },
        ],
        web3Provider,
        chainId,
      );
      const api: AxiosInstance = axios.create({
        baseURL: 'https://pro-api.coingecko.com/api/v3/',
        headers: {
          Accept: 'application/json',
          'x-cg-pro-api-key': env.COINGECKO_KEY,
        },
      });
      const url1 = `coins/${farmConfig.lockedTokenId}`;
      const url2 = `coins/${farmConfig.rewardTokenId}`;

      let lokedTokenPrice = 1;
      try {
        const result1 = await api.get(url1);
        // console.log('------ coin genko', url1);
        // console.log('------', result1);
        lokedTokenPrice = result1?.data?.market_data?.current_price.usd ?? '';
      } catch {
        lokedTokenPrice = 1;
      }

      let rewardTokenPrice = 1;
      try {
        const result2 = await api.get(url2);
        // console.log('------ coin genko url 2 reward token', url2);
        // console.log('------ result 2', result2);
        rewardTokenPrice = result2?.data?.market_data?.current_price.usd ?? '';
      } catch {
        rewardTokenPrice = 0.05;
      }

      const web3 = new Web3(web3Provider);
      const tokenContract = new web3.eth.Contract(
        standardAbiEth as AbiItem[],
        getAddress(farmConfig.lockedAddress, chainId),
      );
      const lockedTokenBal = await tokenContract.methods
        .balanceOf(getAddress(farmConfig.contractAddress, chainId))
        .call();
      const total = lockedTokenBal * lokedTokenPrice;
      const apy = new BigNumber(BLOCK_PER_YEAR)
        .multipliedBy(rewardPerBlock)
        .dividedBy(
          new BigNumber(10).pow(new BigNumber(farmConfig.rewardDecimal)),
        )
        .multipliedBy(new BigNumber(rewardTokenPrice))
        .dividedBy(lockedTokenBal)
        .dividedBy(new BigNumber(lokedTokenPrice))
        .multipliedBy(
          new BigNumber(10).pow(new BigNumber(farmConfig.lockedDecimal)),
        )
        .multipliedBy(new BigNumber(100));

      if (
        addressData.indexOf(farmConfig.lockedAddress[chainId].toLowerCase()) ===
        -1
      ) {
        addressData.push(farmConfig.lockedAddress[chainId].toLowerCase());
        const poolTokenPrice = await ARCAPIService.getPriceUSDTWithAddress(
          farmConfig.lpSymbol,
          farmConfig.lockedAddress[chainId].toLowerCase(),
        );
        return {
          ...farmConfig,
          allocPoint: 1,
          apy,
          stakedTokenTransferFee: stakedTokenTransferFee / 100,
          totalLiquidity: total,
          poolTokenPrice,
        };
      }

      return {
        ...farmConfig,
        allocPoint: 1,
        apy,
        stakedTokenTransferFee: stakedTokenTransferFee / 100,
        totalLiquidity: total,
        poolTokenPrice: 0,
      };

      // return {
      //   ...farmConfig,
      //   // poolTokenPrice: 0,
      // };
    }),
  );
  return data;
};

export default fetchJungleFarms;
