import USDC from '../../assets/tokens/ether-usdc.png';
import USDT from '../../assets/tokens/ether-usdt.png';
import WBTC from '../../assets/tokens/ether-wbtc.png';
import { PoolCategory } from '../../utility/types';

const pools: any[] = [
  {
    type: 'DEPO',
    active: false,
    version: 1,
    pid: -1,
    poolCategory: PoolCategory.CORE,
    sortOrder: 1,
    isFinished: false,
    lpSymbol: 'USDC',
    rewardSymbol: 'DEPO',
    lockedTokenId: 'usd-coin',
    rewardTokenId: 'depo',
    contractAddress: {
      1: '0x850b07532268c8606080123b68c889bb65c1fc22',
      4: '0x3dc3f4868159151878e5aBE93CB6460a03f50cD9',
    },
    lockedAddress: {
      1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      4: '0xcdD0478f1a2AaE2D281585D0cAe42AaE157269E3',
    },
    rewardAddresses: {
      1: '0xa5def515cfd373d17830e7c1de1639cb3530a112',
      4: '0xd597953a8bC09cE02B5652F0A8a774807601a7bD',
    },
    rewardDecimal: 18,
    lockedDecimal: 6,
    logoImage: USDC,
    isTokenOnly: true,
    depositFee: '2.5%',
  },
  {
    type: 'DEPO',
    active: false,
    version: 1,
    pid: -2,
    poolCategory: PoolCategory.CORE,
    sortOrder: 1,
    isFinished: false,
    lpSymbol: 'USDT',
    rewardSymbol: 'DEPO',
    lockedTokenId: 'tether',
    rewardTokenId: 'depo',
    contractAddress: {
      1: '0xaee2e6f41afdd791b313a95609151a6131abc286',
      4: '0x10E6f0c16e2D2b704333199842067BcB3E9B58bC',
    },
    lockedAddress: {
      1: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      4: '0xfF1979502522889f2EA584717C59368f778ABfBA',
    },
    rewardAddresses: {
      1: '0xa5def515cfd373d17830e7c1de1639cb3530a112',
      4: '0xd597953a8bC09cE02B5652F0A8a774807601a7bD',
    },
    rewardDecimal: 18,
    lockedDecimal: 6,
    logoImage: USDT,
    isTokenOnly: true,
    depositFee: '2.5%',
  },
  {
    type: 'DEPO',
    active: false,
    version: 1,
    pid: -3,
    poolCategory: PoolCategory.CORE,
    sortOrder: 1,
    isFinished: false,
    lpSymbol: 'WBTC',
    rewardSymbol: 'DEPO',
    lockedTokenId: 'wrapped-bitcoin',
    rewardTokenId: 'depo',
    contractAddress: {
      1: '0x46621b4ef89376150e072f71fbbc4b44a376d644',
      4: '0xbb08D9792349bCD2061AE6F8Ea6E8251e410d20E',
    },
    lockedAddress: {
      1: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      4: '0xb195106612E2A80516573A60e1c82973FCD974F7',
    },
    rewardAddresses: {
      1: '0xa5def515cfd373d17830e7c1de1639cb3530a112',
      4: '0xd597953a8bC09cE02B5652F0A8a774807601a7bD',
    },
    rewardDecimal: 18,
    lockedDecimal: 8,
    logoImage: WBTC,
    isTokenOnly: true,
    depositFee: '2.5%',
  },
  // Junglefarms 2.0 below
  {
    type: 'DEPO',
    active: false,
    version: 2,
    pid: -1,
    poolCategory: PoolCategory.CORE,
    sortOrder: 1,
    isFinished: false,
    lpSymbol: 'USDC',
    rewardSymbol: 'DEPO',
    lockedTokenId: 'usd-coin',
    rewardTokenId: 'depo',
    contractAddress: {
      1: '0xE2557b81f2552F21580354E922c85e1499421297',
      4: '0x3dc3f4868159151878e5aBE93CB6460a03f50cD9',
    },
    lockedAddress: {
      1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      4: '0xcdD0478f1a2AaE2D281585D0cAe42AaE157269E3',
    },
    rewardAddresses: {
      1: '0xa5def515cfd373d17830e7c1de1639cb3530a112',
      4: '0xd597953a8bC09cE02B5652F0A8a774807601a7bD',
    },
    rewardDecimal: 18,
    lockedDecimal: 6,
    logoImage: USDC,
    isTokenOnly: true,
    depositFee: '2.5%',
  },
  {
    type: 'DEPO',
    active: false,
    version: 2,
    pid: -2,
    poolCategory: PoolCategory.CORE,
    sortOrder: 1,
    isFinished: false,
    lpSymbol: 'USDT',
    rewardSymbol: 'DEPO',
    lockedTokenId: 'tether',
    rewardTokenId: 'depo',
    contractAddress: {
      1: '0xe80cBbB44c1C03A9129853Ad476F5176baF1e34d',
      4: '0x10E6f0c16e2D2b704333199842067BcB3E9B58bC',
    },
    lockedAddress: {
      1: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      4: '0xfF1979502522889f2EA584717C59368f778ABfBA',
    },
    rewardAddresses: {
      1: '0xa5def515cfd373d17830e7c1de1639cb3530a112',
      4: '0xd597953a8bC09cE02B5652F0A8a774807601a7bD',
    },
    rewardDecimal: 18,
    lockedDecimal: 6,
    logoImage: USDT,
    isTokenOnly: true,
    depositFee: '2.5%',
  },
  {
    type: 'DEPO',
    active: false,
    version: 2,
    pid: -3,
    poolCategory: PoolCategory.CORE,
    sortOrder: 1,
    isFinished: false,
    lpSymbol: 'WBTC',
    rewardSymbol: 'DEPO',
    lockedTokenId: 'wrapped-bitcoin',
    rewardTokenId: 'depo',
    contractAddress: {
      1: '0x8b8c3aad39daa2ef246067ffa87ba91f36dd4e1f',
      4: '0xbb08D9792349bCD2061AE6F8Ea6E8251e410d20E',
    },
    lockedAddress: {
      1: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      4: '0xb195106612E2A80516573A60e1c82973FCD974F7',
    },
    rewardAddresses: {
      1: '0xa5def515cfd373d17830e7c1de1639cb3530a112',
      4: '0xd597953a8bC09cE02B5652F0A8a774807601a7bD',
    },
    rewardDecimal: 18,
    lockedDecimal: 8,
    logoImage: WBTC,
    isTokenOnly: true,
    depositFee: '2.5%',
  },
  {
    type: 'ARC',
    active: false,
    version: 1,
    pid: -1,
    poolCategory: PoolCategory.CORE,
    sortOrder: 1,
    isFinished: false,
    lpSymbol: 'USDC',
    rewardSymbol: 'ARC',
    lockedTokenId: 'usd-coin',
    rewardTokenId: 'arc',
    contractAddress: {
      1: '0x5951B5EEDC30C8A5353f1d5824D5a8B3a9e73899',
      4: '',
    },
    lockedAddress: {
      1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      4: '0xcdD0478f1a2AaE2D281585D0cAe42AaE157269E3',
    },
    rewardAddresses: {
      1: '0xC82E3dB60A52CF7529253b4eC688f631aad9e7c2',
      4: '',
    },
    rewardDecimal: 18,
    lockedDecimal: 6,
    logoImage: USDC,
    isTokenOnly: true,
    depositFee: '2.5%',
  },
  {
    type: 'ARC',
    active: false,
    version: 1,
    pid: -2,
    poolCategory: PoolCategory.CORE,
    sortOrder: 1,
    isFinished: false,
    lpSymbol: 'USDT',
    rewardSymbol: 'ARC',
    lockedTokenId: 'tether',
    rewardTokenId: 'arc',
    contractAddress: {
      1: '0xA5c6540DB5Cf2Dbc49B02E568DC46D97B5918cE8',
      4: '',
    },
    lockedAddress: {
      1: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      4: '0xfF1979502522889f2EA584717C59368f778ABfBA',
    },
    rewardAddresses: {
      1: '0xC82E3dB60A52CF7529253b4eC688f631aad9e7c2',
      4: '',
    },
    rewardDecimal: 18,
    lockedDecimal: 6,
    logoImage: USDT,
    isTokenOnly: true,
    depositFee: '2.5%',
  },
  {
    type: 'ARC',
    active: false,
    version: 1,
    pid: -3,
    poolCategory: PoolCategory.CORE,
    sortOrder: 1,
    isFinished: false,
    lpSymbol: 'WBTC',
    rewardSymbol: 'ARC',
    lockedTokenId: 'wrapped-bitcoin',
    rewardTokenId: 'arc',
    contractAddress: {
      1: '0x3B6AD0a26309c6DE8e4Dc23233a5c25cdaf13C04',
      4: '',
    },
    lockedAddress: {
      1: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      4: '0xb195106612E2A80516573A60e1c82973FCD974F7',
    },
    rewardAddresses: {
      1: '0xC82E3dB60A52CF7529253b4eC688f631aad9e7c2',
      4: '',
    },
    rewardDecimal: 18,
    lockedDecimal: 8,
    logoImage: WBTC,
    isTokenOnly: true,
    depositFee: '2.5%',
  },
];
export default pools;
