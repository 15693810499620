/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Dropdown, type MenuProps } from 'antd';

interface Props {
  onRightClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  theme: string;
  content: string;
  onHandleClose: (askAIContent: string) => void;
  children: React.ReactNode;
}

const AskAI: React.FC<Props> = ({
  onRightClick,
  theme,
  content,
  onHandleClose,

  children,
}) => {
  const handleMenuClick: MenuProps['onClick'] = async (e: any) => {
    if (e.key === 'askai') {
      onHandleClose(`Please explain what ${content} is?`);
    }
  };
  const items: MenuProps['items'] = [
    {
      label: 'Ask AI',
      key: 'askai',
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
    style: {
      color: 'white',
      backgroundColor: theme === 'light' ? '#fff' : '#000',
    },
  };

  return (
    <Dropdown menu={menuProps} trigger={['contextMenu']}>
      {children}
    </Dropdown>
  );

  //   <div onContextMenu={handleContextMenu}>{children}</div>;
};

export default AskAI;
