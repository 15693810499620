import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import store from 'state';
import { Provider } from 'react-redux';
import AuthProvider from 'contexts/AuthProvider';

import { Buffer } from 'buffer';
import App from './App';
import ProviderWeb3 from './connect/webprovider';

window.Buffer = window.Buffer || Buffer;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <ProviderWeb3>
    <Provider store={store}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Provider>
  </ProviderWeb3>,
  // </React.StrictMode>,
);
