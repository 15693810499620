import React from 'react';
import arcLogo from '../../assets/full-logo.png';
import ipBlockedImg from '../../assets/ip-blocked.png';
import './styles.scss';

const Restriction: React.FC = () => {
  // eslint-disable-next-line no-lone-blocks
  {
    /* eslint-disable-next-line react/no-unescaped-entities */
  }
  return (
    <div className="restriction-page-wrapper">
      <div className="restriction-content-wrapper">
        <div className="restriction-content">
          <img src={arcLogo} alt="ARC Logo" height={40} />
          <div className="restriction-body">
            <img src={ipBlockedImg} alt="ARC Logo" height={40} />
            <div className="restriction-text">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <h3>We're sorry</h3>
              <h4>Arc is unavailable in your location.</h4>
              <span>Error code: 112</span>
              <p>
                Arc is required to comply with US sanctions laws that restrict
                the use of our site in certain Jurisdictions. Because of this,
                our services are not avilable in country 1, country 2.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Restriction;
