/* eslint-disable */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, Typography, Input, Image, Row, Col, Checkbox } from 'antd';
import { ThemeContext } from '../../contexts/ThemeContext';
import ARCModal from '../ARCModal/ARCModal';
import './style.scss';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

const DisclaimerModal: React.FC<{
  onSelect: Function;
  visible: boolean;
  onClose: () => void;
  mobile?: boolean;
}> = ({ onSelect, visible, onClose, mobile }) => {
  const { theme } = useContext(ThemeContext);
  const [modalIndex, setModalIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isDisable, setisDisable] = useState(true);
  const listInnerRef = useRef<HTMLDivElement>(null);

  const onChange = (e: CheckboxChangeEvent) => {
    setisDisable(!e.target.checked);
  };
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop + clientHeight + 3 > scrollHeight) {
        setisDisable(false);
      }
    }
  };
  const onCloseHandler = () => {
    localStorage.setItem('@app:disclaimer', 'read');
    setModalIndex(0);
    onClose();
  };

  const diclaimerText = () => {
    return (
      <div className="disclaimer-text">
        <h2 style={{ color: theme === 'light' ? '#000' : '#fff' }}>
          Disclaimer for ARC Swaps Product
        </h2>
        <p style={{ fontWeight: '700' }}>
          THE SERVICES ARE NOT AVAILABLE TO INDIVIDUALS WHO ARE RESIDENTS OF,
          OR LOCATED IN, THE UNITED STATES OF AMERICA, CANADA, OR ANY OTHER
          JURISDICTIONS WHERE THE USE OF OUR SERVICES MAY BE ILLEGAL OR OTHERWISE
          RESTRICTED ("RESTRICTED JURISDICTIONS").
          BY ACCESSING OR USING OUR SERVICES, YOU REPRESENT AND WARRANT
          THAT YOU ARE NOT LOCATED IN, UNDER THE CONTROL OF,
          OR A NATIONAL OR RESIDENT OF ANY RESTRICTED JURISDICTIONS.

        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          1. Introduction
        </h3>
        <p style={{ textAlign: 'justify'}}>
            Welcome to ARC ("ARC", "we", "us", "our"), a decentralized cryptocurrency security platform.
            ARC enables users to audit cryptocurrency tokens, ARC also allows users to interact with the 1inch,
            liquidity pool aggregator. ARC does not operate an exchange, nor does it directly engage in token swaps.
            Our platform facilitates access to liquidity pools through third-party services such as 1inch.
        </p>
        <p style={{ textAlign: 'justify'}}>

          ARC operates as a non-custodial service,
          which means we do not have access to, nor control over,
          your user funds at any point during the transaction.
          Users remain in control of their wallets and conduct swaps independently.

        </p>
        <p style={{ textAlign: 'justify'}}>
              These Terms of Use (the "Terms") govern your access to and use of our platform and related services (collectively, the "Services").
              By accessing or using our Services, you agree to be bound by these Terms.
              Please read these Terms carefully, as they contain important information about your legal rights and obligations
        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          2. Definitions
        </h3>
        <p style={{ textAlign: 'justify'}}>
            "Service": The decentralized swap aggregation service provided by ARC, which allows users to access the best token swap prices through third-party services like 1inch.
        </p>

        <p style={{ textAlign: 'justify'}}>
          "User" Individuals who use the ARC platform to access and perform token swaps.
        </p>
        <p style={{ textAlign: 'justify'}}>
          "Platform" The ARC website and associated software used to facilitate access to token swap features.

        </p>
        <p style={{ textAlign: 'justify'}}>
          "Liquidity Pool Aggregator" A third-party service like 1inch that aggregates liquidity from various sources to provide users with optimal prices for their token swaps.

        </p>
        <p style={{ textAlign: 'justify'}}>
          "Non-custodial" ARC does not hold or control user funds. Users conduct all transactions independently, and funds remain within their own wallets.

        </p>
        <p style={{ textAlign: 'justify'}}>
          "Cryptocurrency Swaps"  Transactions performed by users where one cryptocurrency is exchanged for another is facilitated via the 1inch API.

        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          3. Eligibility
        </h3>
        <p style={{ textAlign: 'justify'}}>
          The Services are not available to individuals who are residents of, or located in, the United States, Canada, or any other jurisdictions
          where the use of our Services may be illegal or otherwise restricted ("Restricted Jurisdictions").
           By accessing or using our Services, you represent and warrant that you are not located in, under the control of, or a national or resident of any Restricted Jurisdictions.

        </p>
        <p style={{ textAlign: 'justify'}}>
            Users outside Restricted Jurisdictions are responsible for ensuring compliance with local laws and regulations.
            ARC does not assume responsibility for user compliance with applicable laws, including tax obligations or reporting.

        </p>

        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          4. Transparency and Audits
        </h3>
        <p style={{ textAlign: 'justify'}}>
            ARC prioritizes user protection by providing access to audit reports of the smart contracts involved in token swaps.
             Users can review these audits to verify the legitimacy of the tokens being swapped and mitigate the risk of interacting with scam tokens.

        </p>

        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          5. Wallet Connection and Security
        </h3>
        <p style={{ textAlign: 'justify'}}>
          ARC operates a non-custodial platform. This means users do not create accounts with ARC, but instead,
          connect their decentralized wallets to use the Service.
          By using the Service, you accept all risks associated with connecting a decentralized wallet, including the security of your private keys.

        </p>
        <p style={{ textAlign: 'justify'}}>
        You are solely responsible for managing and securing your wallet and private keys.
        ARC is not liable for any loss or damage resulting from your failure to adequately secure your wallet.

        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          6. Fees
        </h3>
        <p style={{ textAlign: 'justify'}}>
            ARC applies a small transaction fee for facilitating access to the liquidity pool aggregator.
            This fee is separate from any fees charged by the third-party liquidity pools.
            Before completing any transaction, users will see a breakdown of all fees, including ARC's fee and any associated with the 1inch platform.

        </p>
        <p style={{ textAlign: 'justify'}}>
            Users ARC reserves the right to update its fee structure at any time. Users will be notified of any changes before completing a transaction.
        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          7. Risk Disclosure
        </h3>
        <p style={{ textAlign: 'justify'}}>
          Trading cryptocurrencies involves significant risk .
          The value of cryptocurrencies can fluctuate dramatically and is inherently unpredictable.
          ARC provides access to third-party services and does not guarantee the performance of any specific token swap.

        </p>
        <p style={{ textAlign: 'justify'}}>
          You should fully understand the risks involved before engaging in any cryptocurrency swap and seek professional financial advice if necessary.

        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          8. User Responsibility
        </h3>
        <p style={{ textAlign: 'justify'}}>
            Users are solely responsible for their actions while using ARC’s Services.
            This includes ensuring compliance with all applicable laws, regulations, and obligations within their jurisdiction.
            Users must act responsibly and ensure the accuracy of any information they provide.

        </p>

        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          9. Prohibited Use
        </h3>
        <p style={{ textAlign: 'justify'}}>
          You may not use ARC’s Services for any illegal or unauthorized purposes. This includes, but is not limited to:




        </p>
        <ul style={{ listStyleType: 'disc' }}>
            <li>Accessing the Service from a Restricted Jurisdiction</li>
            <li>Engaging in fraudulent activities</li>
            <li>Using the Service to launder money or finance illegal activities</li>
          </ul>
        <p style={{ textAlign: 'justify'}}>
          Violations of these terms may result in termination of your access to the platform and reporting to the relevant authorities, as required by law.

        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          10. Intellectual Property Rights
        </h3>
        <p style={{ textAlign: 'justify'}}>
          ARC and its licensors retain all intellectual property rights associated with the platform. You agree not to modify, distribute, or reproduce any part of ARC’s Services without explicit permission.
        </p>

        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          11. Third-Party Content
        </h3>
        <p style={{ textAlign: 'justify'}}>
          The ARC platform may include links to third-party websites or services, including 1inch.
          ARC does not endorse or guarantee the accuracy of third-party content and is not responsible for any loss or damage incurred from interacting with third-party services.

        </p>

        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          12. Indemnification
        </h3>
        <p style={{ textAlign: 'justify'}}>
          By using ARC’s Services, you agree to indemnify and hold ARC harmless from any claims, damages, or liabilities arising out of your use of the platform or violation of these Terms.

        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          13. Governing Law and Jurisdiction
        </h3>
        <p style={{ textAlign: 'justify'}}>
          These Terms are governed by and construed in accordance with the laws applicable in Switzerland,
          without regard to conflict of law principles. Any disputes related to these Terms or the use of ARC’s Services shall be resolved exclusively in the courts of Switzerland.

        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          14. Changes to the Terms of Use
        </h3>
        <p style={{ textAlign: 'justify'}}>ARC reserves the right to modify these Terms at any time.
           We will notify users of any changes by posting the updated Terms on our platform. Continued use of the platform after such modifications constitutes acceptance of the revised Terms.
        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          15. Termination of Use
        </h3>
        <p style={{ textAlign: 'justify'}}>
            ARC reserves the right to suspend or terminate your use of the Service at any time and without notice if you violate any of the terms of this agreement.
             This includes, but is not limited to, the following:

        </p>
        <ul style={{ listStyleType: 'disc' }}>
          <li>Using the Service for any illegal or unauthorized purpose</li>
          <li>Violating any applicable laws or regulations.</li>
          <li>Violating any of the terms of this agreement.</li>
          <li>Harming or threatening to harm any other user.</li>
          <li>Spamming or harassing any other user.</li>
          <li>Posting any defamatory, offensive, or illegal content.</li>
          <li>Impersonating any other person or entity.</li>

        </ul>
        <p style={{ textAlign: 'justify'}}>
        Using the Service in a way that disrupts or interferes with the normal operation of the Service.
        If your use of the Service is terminated, you will no longer be able to access the Service or any of its features. You will also be responsible for any outstanding fees or charges that you may owe to ARC.

        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          16. Disclaimer of Warranties
        </h3>
        <p style={{ textAlign: 'justify'}}>
        The Service is provided "as is". ARC makes no warranty that the Service will be uninterrupted, timely, secure, or error-free. ARC also makes no warranty that the results that may be obtained from the use of the Service will be accurate or reliable.
The Service may include third-party content or services. ARC makes no warranty regarding the accuracy, completeness, or legality of any third-party content or services.
ARC is not responsible for any damages that may result from your use of the Service, including, but not limited to, direct, indirect, incidental, consequential, or punitive damages.
Your use of the Service is at your own risk.

        </p>


        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          17. Limitation of Liability
        </h3>
        <p style={{ textAlign: 'justify'}}>
        ARC's liability to you for any loss or damage arising out of or in connection with your use of the Service is limited to the amount of fees actually paid by you to ARC for use of the Service.
ARC is not liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, arising out of or in connection with your use of the Service.
This limitation of liability applies even if ARC has been advised of the possibility of such damages.

        </p>


        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          18. Indemnification
        </h3>
        <p style={{ textAlign: 'justify'}}>
          You agree to indemnify and hold harmless ARC, its officers, directors, employees, and agents, from and against any claims, suits, proceedings, disputes, demands, liabilities, damages, losses, costs and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in connection with:

        </p>
        <ul>
          <li>Your use of the Service;</li>
          <li>Your violation of any term of this agreement; or</li>
          <li>Your violation of any law or regulation.</li>
        </ul>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          19. Dispute Resolution
        </h3>
        <p style={{ textAlign: 'justify'}}>
        Any dispute arising out of or in connection with these Terms of Use shall be resolved by binding arbitration. The arbitration shall be conducted in English and the arbitral award shall be final and binding. The parties agree to waive their right to a jury trial.

        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          20. Governing Law and Jurisdiction
        </h3>
        <p style={{ textAlign: 'justify'}}>
        These Terms of Use and any dispute or claim arising out of, or related to them, shall be governed by and construed in accordance with the internal laws of the British virgin islands without giving effect to any choice or conflict of law provision or rule.
        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          21. Changes to the Terms of Use
        </h3>
        <p style={{ textAlign: 'justify'}}>
        ARC reserves the right to change these Terms of Use at any time. We will notify you of any changes by posting the new Terms of Use on our Platform. You are responsible for reviewing the Terms of Use periodically to be aware of any changes. Your continued use of the Platform after the posting of changes to the Terms of Use constitutes your acceptance of those changes.


        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          22. Miscellaneous Provisions
        </h3>
        <p style={{ textAlign: 'justify'}}>
        If any part of these Terms of Use is held invalid or unenforceable, that part will be construed to reflect the parties' original intent, and the remaining portions will remain in full force and effect.
These are just some of the legal nuances that ARC should consider when drafting its Terms of Use. It is important to consult with an attorney to ensure that the Terms of Use are tailored to ARC's specific needs.

        </p>

        <p style={{ textAlign: 'justify'}}>Here are some additional considerations for ARC:</p>
        <ul>
          <li>
            KYC and AML requirements: ARC may need to implement KYC and AML
            procedures to comply with applicable laws and regulations.
          </li>
          <li>
            Scams and fraud: ARC should take steps to protect users from scams
            and fraud, such as by providing educational resources and warning
            users about potential risks.
          </li>
          <li>
            Taxes: ARC may need to collect taxes on transactions that occur on
            its platform.
          </li>
          <li>
            Regulatory compliance:   ARC should stay up-to-date on changes in the regulatory landscape and make sure that its platform complies with all applicable laws and regulations.

          </li>
        </ul>
      </div>
    );
  };

  return (
    <>
      <ARCModal open={visible} onClose={onClose}>
        <div
          className={`depo__fulfill-modal  ${
            theme === 'light' ? 'bg-white' : 'bg-dark'
          } rounded-modal`}
        >
          <div className="depo__token-select-tab">
            <div
              className={
                theme === 'light'
                  ? 'depo__token-select-mainpanel-light'
                  : 'depo__token-select-mainpanel-dark'
              }
            >
              <div className="p-4" style={{ height: '532px' }}>
                <Typography
                  className="title"
                  style={{
                    color: theme === 'light' ? '#007aff' : '#007aff',
                    textAlign: 'center',
                  }}
                >
                  Disclaimer
                  <article
                    className="ant-typography h6"
                    style={{ color: 'rgb(77 77 77)', textAlign: 'center' }}
                  >
                    All users must read in full
                  </article>
                </Typography>

                {/* disclaimer text */}
                <div
                  className="depo__token-list"
                  style={{ height: '330px' }}
                  ref={listInnerRef}
                  onScroll={onScroll}
                >
                  {diclaimerText()}
                </div>

                <Checkbox
                  checked={!isDisable}
                  onChange={onChange}
                  style={{
                    marginTop: '5px',
                    color: theme === 'light' ? '#000' : '#fff',
                  }}
                >
                  I've read through the agreement
                </Checkbox>
                <div
                  className={`depo__token-to-mng-list btn-mobile ${
                    isDisable ? 'disabled' : 'disabled-false'
                  }`}
                  style={{
                    marginTop: '20px',
                  }}
                  onClick={onCloseHandler}
                >
                  <Button
                    className={`text-white toMngList`}
                    type="link"
                    disabled={isDisable}
                    onClick={onCloseHandler}
                  >
                    Accept
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ARCModal>
    </>
  );
};

export default DisclaimerModal;
