import { MdSync } from 'react-icons/md';
import { FaTractor } from 'react-icons/fa';
import { FiatOnRampIcon } from '../../assets/icons';

// eslint-disable-next-line import/prefer-default-export
export const DappItems = [
  // {
  //   key: 1,
  //   title: 'Home',
  //   icon: MdDashboard,
  //   path: 'https://depo.io',
  //   protected: false,
  // },
  // {
  //   key: 2,
  //   title: 'Console',
  //   icon: MdAccountBalanceWallet,
  //   path: '/console',
  //   protected: true,
  // },
  // {
  //   key: 3,
  //   title: 'Market',
  //   icon: MdPoll,
  //   path: '/market',
  //   protected: false,
  //   children: [
  //     {
  //       title: 'Spot',
  //       key: 3.1,
  //       icon: MdPoll,
  //       path: '/market/spot',
  //       protected: false,
  //     },
  //     {
  //       title: 'Future',
  //       key: 3.2,
  //       icon: MdPoll,
  //       path: '/market/future',
  //       protected: false,
  //     },
  //   ],
  // },
  {
    key: 4,
    title: 'Swap',
    icon: MdSync,
    path: '/swap',
    protected: false,
  },

  {
    key: 6,
    title: 'Farm',
    icon: FaTractor,
    path: '/farm',
    protected: false,
  },
  {
    key: 7,
    title: 'On Ramp',
    icon: FiatOnRampIcon,
    path: '/fiat-on-ramp',
    protected: false,
  },
  {
    key: 8,
    title: 'Legal',
    path: '/legal',
    protected: false,
    children: [
      {
        title: 'Risk Statement',
        key: 8.1,
        path: '/risk-statement',
        protected: false,
      },
      {
        title: 'Republication Terms',
        key: 8.2,
        path: '/republication-guidelines',
        protected: false,
      },
      {
        title: 'Financial Services Provider',
        key: 8.3,
        path: '/financial-services-provider',
        protected: false,
      },
    ],
  },
  {
    key: 9,
    title: 'Contact',
    icon: FiatOnRampIcon,
    path: '/contact',
    protected: false,
  },
  // {
  //   key: 9,
  //   title: 'Account',
  //   icon: MdSettings,
  //   path: '/account',
  //   protected: true,
  // },
];
