/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import { Space, theme } from 'antd';
import AskAI from 'components/ask-ai';
import CoinbaseCard from 'connect/coinbasecard';
import MetaMaskCard from 'connect/metamaskcard';
import TrustwalletCard from 'connect/trustwalletcard';
import WalletConnectCard from 'connect/walletconnectcard';
import React, { useContext, useEffect, useState } from 'react';
import { isMobile, useDeviceData } from 'react-device-detect';
import { AuthContext } from '../../contexts/AuthProvider';
import './style.scss';

const ARCRightSidebar: React.FC = () => {
  const mobile = isMobile;
  const screenWidth = window.innerWidth;

  const [isTypeDetection, setisTypeDetection] = useState(false);

  const {
    isRightBar,
    setisRightBar,
    isAuthenticated,
    setaskAIInfo,
    setOpenSubscriptionInfoModal,
  } = useContext(AuthContext);

  const handleRightClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    typeAsk: string,
  ) => {
    console.warn('handleRightClick', typeAsk);
  };

  const getDevice = () => {
    const deviceData = useDeviceData(window.navigator.userAgent);

    const { ua } = deviceData;
    const isMetamask2 = ua.includes('MetaMaskMobile');
    if (isMetamask2) {
      setisTypeDetection(true);
    } else {
      setisTypeDetection(false);
    }
    // get metamaskmobile content
  };
  useEffect(() => {
    getDevice();
  }, []);

  return (
    <div
      className={
        isRightBar ? 'arc-rightbar-dialog show' : 'arc-rightbar-dialog'
      }
      role="document"
    >
      {/* <div className="arc-rightsiderbar-wrapper"> */}
      <AskAI
        onRightClick={(event) => handleRightClick(event, 'swap')}
        theme={theme ?? 'dark'}
        content="Account Menu"
        onHandleClose={(value) => {
          setaskAIInfo(value);
        }}
      >
        <div className="modal-content arc-rightsiderbar-wrapper">
          <div
            className="arc-rightsidebar-drawer"
            onClick={() => {
              setisRightBar(false);
            }}
            style={{ padding: !mobile ? '24px 28px 24px 14px' : '0' }}
          >
            {/* {!mobile && (
              <DoubleRightOutlined
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setisRightBar(false);
                }}
              />
            )} */}
          </div>
          <div
            className="arc-rightsidebar-main"
            style={{ width: screenWidth < 320 ? screenWidth : 320 }}
          >
            <div className="mt4">
              {!isAuthenticated && (
                <div className="arc-rightsidebar-main-title">
                  <h2>Connect a wallet</h2>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    onClick={() => {
                      setisRightBar(false);
                    }}
                  >
                    <path
                      fill={theme === 'light' ? 'black' : 'white'}
                      d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
                    />
                  </svg>
                </div>
              )}
              <Space direction="vertical" style={{ width: '100%' }}>
                {/* <WalletConnectCard /> */}
                {!mobile && (
                  <>
                    <MetaMaskCard />
                    <TrustwalletCard />
                    <CoinbaseCard />
                    <WalletConnectCard />
                  </>
                )}

                {mobile && !isTypeDetection && (
                  <>
                    <CoinbaseCard />
                    <WalletConnectCard />
                  </>
                )}
                {mobile && isTypeDetection && (
                  <>
                    <MetaMaskCard />
                  </>
                )}
              </Space>
              {isAuthenticated && (
                <div>
                  <div
                    className="arc-rightsidebar-subscription-button"
                    onClick={() => {
                      setisRightBar(false);
                      setOpenSubscriptionInfoModal(true);
                    }}
                  >
                    <p>Subscription</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                    >
                      <path
                        d="M14.3558 10.625H4.25V9.375H14.3558L9.60898 4.62819L10.5 3.75L16.75 9.99998L10.5 16.25L9.60898 15.3718L14.3558 10.625Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  {/* <TxHistory theme={theme} /> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </AskAI>
    </div>
  );
};
export default ARCRightSidebar;
