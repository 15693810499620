/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable arrow-body-style */
import {
  CopyOutlined,
  PoweroffOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Card, Skeleton, Tabs, TabsProps, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { shortenAddress } from 'utility/addressHelpers';
import SocialConnect from 'components/SocialConnect';
import { AuthContext } from 'contexts/AuthProvider';
import { ThemeContext } from 'contexts/ThemeContext';
import { useMediaQuery } from 'react-responsive';
import Notification from 'components/Notification/Notification';
import useCopyToClipboard from '../hooks/useCopyClipboard';
import newTokenAlert from '../assets/newTokenAlert.png';
import NewTokenImg from '../assets/newToken.png';

const { Meta } = Card;

const BalanceCard: React.FC<{
  tokenBalance: any;
  account: string;
  isLoading: boolean;
  onDeactivate: () => void;
  icon?: string;
}> = ({ tokenBalance, account, isLoading, onDeactivate, icon }) => {
  const {
    tokens,
    setisRightBar,
    erc20TokensForStaticList,
    setTokenSidebar,
    auditNewToken,
    setAuditNewToken,
    auditMyToken,
    setAuditMyToken,
    setSocialConnectionRightBar,
    setIsDropdownOpen,
  } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);

  const [value, copy] = useCopyToClipboard();
  const [checkedTokenOption, setCheckedTokenOption] = useState<boolean>(false);
  const { setSideBarActvieKey } = useContext(AuthContext);
  const mobile = useMediaQuery({ maxWidth: 576 });

  const onChange = (key: string) => {
    setSideBarActvieKey(Number(key));
  };
  const handleSelectedToken = (tokenSymbol: string) => {
    setisRightBar(false);
    const token = erc20TokensForStaticList.filter(
      (_token) => _token.symbol === tokenSymbol,
    )[0];

    setTokenSidebar(token);
  };
  const tabsToken = () => {
    return (
      <>
        <Skeleton loading={isLoading} active avatar>
          {tokenBalance.length > 0 &&
            tokenBalance.map((item: any) => (
              <div
                className="token-list"
                key={item?.address}
                onClick={() => handleSelectedToken(item.symbol)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSelectedToken(item.symbol);
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <div className="token-list-1">
                  <img src={item.logoURI} className="token-list-img" />
                </div>
                <div className="token-list-2">
                  <div className="token-list-2-title">{item.symbol}</div>
                  <div className="token-list-2-subtitle">{item.name}</div>
                </div>
                <div className="token-list-3">
                  <div className="token-list-3-value1">
                    {parseFloat(item?.balance).toFixed(4)}
                  </div>
                  <div className="token-list-3-value2">
                    ${item?.balanceUsd.toFixed(2)}
                  </div>
                </div>
              </div>
            ))}
          <div className="arc-rightsidebar-main-notification">
            {auditMyToken ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 48 48"
                onClick={() => setAuditMyToken(false)}
              >
                <g fill="none" strokeLinejoin="round" strokeWidth="4">
                  <path
                    fill="#007afe"
                    stroke="#007afe"
                    d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z"
                  />
                  <path
                    stroke="#fff"
                    strokeLinecap="round"
                    d="M16 24L22 30L34 18"
                  />
                </g>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                onClick={() => setAuditMyToken(true)}
              >
                <path
                  fill="#007afe"
                  d="M12 20a8 8 0 0 1-8-8a8 8 0 0 1 8-8a8 8 0 0 1 8 8a8 8 0 0 1-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"
                />
              </svg>
            )}
            <span>I want to get regular audit for my tokens.</span>
          </div>
          {/* <div className="arc-rightsidebar-token-balancecard">
            <img src={TokenImg} alt="token alert img" />
            <div className="arc-rightsidebar-token-card-detail">
              <div className="arc-rightsidebar-token-card-info">
                <h5
                  style={{
                    color: theme === 'light' ? 'black' : 'white',
                    margin: 0,
                  }}
                >
                  Audit for my token
                </h5>
                <p style={{ margin: 0 }}>
                  I want to get regular audit for my tokens.
                </p>
              </div>
              {!auditMyToken ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 48 48"
                  onClick={() => setAuditMyToken(!auditMyToken)}
                >
                  <g fill="none" strokeLinejoin="round" strokeWidth="4">
                    <path
                      fill="none"
                      stroke="#B9B9BF"
                      d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z"
                    />
                    <path
                      stroke="#B9B9BF"
                      strokeLinecap="round"
                      d="M16 24L22 30L34 18"
                    />
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 48 48"
                  onClick={() => setAuditMyToken(!auditMyToken)}
                >
                  <g fill="none" strokeLinejoin="round" strokeWidth="4">
                    <path
                      fill="#007afe"
                      stroke="#007afe"
                      d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z"
                    />
                    <path
                      stroke="#fff"
                      strokeLinecap="round"
                      d="M16 24L22 30L34 18"
                    />
                  </g>
                </svg>
              )}
            </div>
          </div> */}
        </Skeleton>
      </>
    );
  };
  const socialConnect = () => <SocialConnect />;
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Tokens`,
      children: tabsToken(),
    },
    {
      key: '2',
      label: `Social Network`,
      children: socialConnect(),
    },
  ];

  return (
    <div>
      <Card>
        <div className="card-profile">
          <Meta
            avatar={<img height={25} src={icon} />}
            title={shortenAddress(account ?? '')}
          />
          {!mobile && (
            <Tooltip placement="top" title="Copy">
              <CopyOutlined
                onClick={() => {
                  copy(account ?? '');
                  Notification({
                    type: 'info',
                    title: 'Copied',
                    message: 'Copied to clipboard',
                  });
                }}
              />
            </Tooltip>
          )}
          <Tooltip placement="top" title="Disconnect">
            <PoweroffOutlined onClick={() => onDeactivate()} />
          </Tooltip>
        </div>
      </Card>
      {/* <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        style={{
          color: 'rgb(255 255 255 / 85%)',
        }}
      /> */}
      <div style={{ marginTop: '1.5em' }}>
        <Skeleton loading={isLoading} active avatar>
          {tokenBalance.length > 0 && (
            <>
              {mobile && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>Your tokens</span>
                  <div>
                    <span className="pr-1">All</span>
                    <RightOutlined />
                  </div>
                </div>
              )}
              {tokenBalance.map((item: any) => (
                <div
                  className="token-list"
                  key={item?.address}
                  onClick={() => handleSelectedToken(item.symbol)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSelectedToken(item.symbol);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <div className="token-list-1">
                    <img src={item.logoURI} className="token-list-img" />
                  </div>
                  <div className="token-list-2">
                    <div className="token-list-2-title">{item.symbol}</div>
                    <div className="token-list-2-subtitle">{item.name}</div>
                  </div>
                  <div className="token-list-3">
                    <div className="token-list-3-value1">
                      {parseFloat(item?.balance).toFixed(4)}
                    </div>
                    <div className="token-list-3-value2">
                      ${item?.balanceUsd.toFixed(2)}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          {tokenBalance.length === 0 && (
            <div className="no-token-balance">
              You do not have any token with a non-zero balance in your wallet.
            </div>
          )}

          {/* <div className="arc-rightsidebar-main-notification">
            {checkedTokenOption ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 48 48"
                onClick={() => setCheckedTokenOption(false)}
              >
                <g fill="none" strokeLinejoin="round" strokeWidth="4">
                  <path
                    fill="#007afe"
                    stroke="#007afe"
                    d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z"
                  />
                  <path
                    stroke="#fff"
                    strokeLinecap="round"
                    d="M16 24L22 30L34 18"
                  />
                </g>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                onClick={() => setCheckedTokenOption(true)}
              >
                <path
                  fill="#007afe"
                  d="M12 20a8 8 0 0 1-8-8a8 8 0 0 1 8-8a8 8 0 0 1 8 8a8 8 0 0 1-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"
                />
              </svg>
            )}
            <span>I want to get regular audit for my tokens.</span>
          </div> */}
          {/* <div
            className="arc-rightsidebar-token-balancecard"
            style={{
              border: auditMyToken ? '2px solid #007AFF' : '2px solid #a5a5a5',
            }}
            // )}
            onKeyDown={(e) => {
              console.log('on keydown');
            }}
            role="button"
            tabIndex={0}
          >
            <img src={NewTokenImg} alt="token alert img" />
            <div className="arc-rightsidebar-token-card-detail">
              <div className="arc-rightsidebar-token-card-info">
                <h5
                  style={{
                    color: theme === 'light' ? 'black' : 'white',
                    margin: 0,
                    fontWeight: 'bold',
                  }}
                >
                  24/7 Security Notifications
                </h5>
                <p
                  style={{
                    margin: 0,
                    lineHeight: '1em',
                    marginTop: '5px',
                  }}
                >
                  Get real time notifications about any security concerns in
                  your portfolio!
                </p>
              </div>
              {!auditMyToken ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 48 48"
                >
                  <g fill="none" strokeLinejoin="round" strokeWidth="4">
                    <path
                      fill="none"
                      stroke="#B9B9BF"
                      d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z"
                    />
                    <path
                      stroke="#B9B9BF"
                      strokeLinecap="round"
                      d="M16 24L22 30L34 18"
                    />
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 48 48"
                >
                  <g fill="none" strokeLinejoin="round" strokeWidth="4">
                    <path
                      fill="#007afe"
                      stroke="#007afe"
                      d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z"
                    />
                    <path
                      stroke="#fff"
                      strokeLinecap="round"
                      d="M16 24L22 30L34 18"
                    />
                  </g>
                </svg>
              )}
            </div>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <button
                type="button"
                className="arc-rightsidebar-token-card-step-btn"
                onClick={() => {
                  setisRightBar(false);
                  setIsDropdownOpen(true);
                }}
              >
                Secure your Portolio
              </button>
            </div>
          </div> */}
        </Skeleton>
      </div>
    </div>
  );
};

export default BalanceCard;
