/* eslint-disable */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, Typography, Input, Image, Row, Col, Checkbox } from 'antd';
import { ThemeContext } from '../../contexts/ThemeContext';
import ARCModal from '../ARCModal/ARCModal';
import './style.scss';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import closeImg from '../../assets/close.svg';
import closeImgBlack from '../../assets/close_black.svg';

const PrivateAndPolicyModal: React.FC<{
  onSelect: Function;
  visible: boolean;
  onClose: () => void;
  mobile?: boolean;
}> = ({ onSelect, visible, onClose, mobile }) => {
  const { theme } = useContext(ThemeContext);
  const [modalIndex, setModalIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isDisable, setisDisable] = useState(true);
  const listInnerRef = useRef<HTMLDivElement>(null);

  const onChange = (e: CheckboxChangeEvent) => {
    setisDisable(!e.target.checked);
  };
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop + clientHeight + 3 > scrollHeight) {
        setisDisable(false);
      }
    }
  };
  const onCloseHandler = () => {
    localStorage.setItem('@app:disclaimer', 'read');
    setModalIndex(0);
    onClose();
  };

  const PrivatePolicyText = () => {
    return (
      <div className="disclaimer-text">
        {/* <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          Private policy
        </h3> */}
        <p>
          ARC Solutions Inc. (hereafter referred to as "we," "us," "our," or
          "ARC") respects the privacy of our users and is committed to
          protecting it through our compliance with this policy.
        </p>
        <p>
          This Privacy Policy (the "Policy") describes our practices in
          connection with information that we collect through activities that
          link to this Policy including websites (our "Websites" arc.market,
          arcai.com, helloarc.ai) and any mobile app that we may provide
          (collectively, our "Services" app.arc.market, app.helloarc.com). By
          using our Services, you agree to this Privacy Policy.
        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          INFORMATION WE DO NOT COLLECT
        </h3>
        <p>
          We do not collect any Personal Information from and about users of our
          Services. "Personal Information" is information that identifies you as
          an individual or relates to an identifiable individual.
        </p>
        <p>
          When you interact with our Services, we do not collect any
          information, including:
        </p>
        <p>
          <strong style={{ fontWeight: '600' }}>Personal Information</strong>:
          Since we are a decentralized service, we do not collect Personal
          Information that you voluntarily provide. This includes your name,
          address, email address, telephone number, and other personally
          identifiable information.
        </p>
        <p>
          <strong style={{ fontWeight: '600' }}>Usage Data</strong>:We do not
          collect usage data, such as information about how you access and use
          the Services.
        </p>
        <p>
          <strong style={{ fontWeight: '600' }}>Cookies</strong>:We do not use
          cookies, pixel tags, and other similar technologies to collect
          information about the pages you view, the links you click, and other
          actions you take on our Services.
        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          THIRD PARTY SERVICES
        </h3>
        <p>
          This Privacy Policy does not address, and we are not responsible for,
          the privacy, information, or other practices of any third parties,
          including any third party operating any site or service to which the
          Services link. The inclusion of a link on the Services does not imply
          endorsement of the linked site or service by us or by our affiliates.
        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          SECURITY
        </h3>
        <p>
          Even though we do not collect Personal Information through our
          Services, we take the security of our Services seriously. We use
          reasonable organizational, technical, and administrative measures to
          provide a secure environment for our Services.
        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          CHANGES TO OUR PRIVACY POLICY
        </h3>
        <p>
          We may change this Privacy Policy. The "Last Updated" legend at the
          top of this Privacy Policy indicates when this Privacy Policy was last
          revised. Any changes will become effective when we post the revised
          Privacy Policy on the Services. Your use of the Services following
          these changes means that you accept the revised Privacy Policy.
        </p>
        <h3 className={theme === 'light' ? 'dark-text' : 'light-text'}>
          CONTACT INFORMATION
        </h3>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at <a href="mailto:contact@arc.market">contact@arc.market</a>.
        </p>
        <p>
          Your use of our Services is also governed by our{' '}
          <a
            href="https://helloarc.ai/terms-and-conditions/"
            rel="noopener"
            target="_blank"
          >
            Terms of Service
          </a>
          .
          <br />
          Please review them to better understand your rights and obligations.
        </p>
        <p>
          Thank you for choosing ARC Solutions Inc. 900 Foulk Rd Wilmington
          Delaware 19803
          <br />
          Effective Date: 01 July 2023
          <br />
          Last Updated on: 01 July 2023
        </p>
      </div>
    );
  };

  return (
    <>
      <ARCModal open={visible} onClose={onClose}>
        <div
          className={`depo__fulfill-modal  ${
            theme === 'light' ? 'bg-white' : 'bg-dark'
          } rounded-modal`}
        >
          <Button
            type="link"
            className="btn-close mt-3 mr-4"
            onClick={onCloseHandler}
          >
            <img
              src={theme === 'light' ? closeImgBlack : closeImg}
              alt="down-arrow"
            />
          </Button>
          <div className="depo__token-select-tab">
            <div
              className={
                theme === 'light'
                  ? 'depo__token-select-mainpanel-light'
                  : 'depo__token-select-mainpanel-dark'
              }
            >
              <div className="p-4" style={{ height: '532px' }}>
                <Typography
                  className="title"
                  style={{
                    color: theme === 'light' ? '#007aff' : '#007aff',
                    textAlign: 'center',
                  }}
                >
                  Privacy policy
                </Typography>

                {/* disclaimer text */}
                <div className="depo__token-list" style={{ height: '330px' }}>
                  {PrivatePolicyText()}
                </div>

                <div
                  className={`depo__token-to-mng-list btn-mobile disabled-false`}
                  style={{
                    marginTop: '20px',
                  }}
                  onClick={onCloseHandler}
                >
                  <Button
                    className={`text-white toMngList`}
                    type="link"
                    onClick={onCloseHandler}
                  >
                    Dismiss
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ARCModal>
    </>
  );
};

export default PrivateAndPolicyModal;
