/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  arbitrumLogo,
  auroraLogo,
  avalancheLogo,
  bscLogo,
  ethLogo,
  fantomLogo,
  optimismLogo,
  polygonLogo,
} from 'assets';

import type { AddEthereumChainParameter } from '@web3-react/types';
import env from '../config/global-env';

interface BasicChainInformation {
  urls: string[];
  name: string;
  logo: string;
  blockExplorerUrls: string[];
}
interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency'];
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls'];
}
/** config for ETH Native */
const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  name: env.NETWORK.Ethereum.name,
  symbol: env.NETWORK.Ethereum.symbol,
  decimals: 18,
};
const BNB: AddEthereumChainParameter['nativeCurrency'] = {
  name: env.NETWORK.Binance.name,
  symbol: env.NETWORK.Binance.symbol,
  decimals: 18,
};
const POLYGON: AddEthereumChainParameter['nativeCurrency'] = {
  name: env.NETWORK.Polygon.name,
  symbol: env.NETWORK.Polygon.symbol,
  decimals: 18,
};
const OPTIMISM: AddEthereumChainParameter['nativeCurrency'] = {
  name: env.NETWORK.Optimism.name,
  symbol: env.NETWORK.Optimism.symbol,
  decimals: 18,
};
const ARBITRUM: AddEthereumChainParameter['nativeCurrency'] = {
  name: env.NETWORK.Arbitrum.name,
  symbol: env.NETWORK.Arbitrum.symbol,
  decimals: 18,
};
const FANTOM: AddEthereumChainParameter['nativeCurrency'] = {
  name: env.NETWORK.Fantom.name,
  symbol: env.NETWORK.Fantom.symbol,
  decimals: 18,
};
const AVALANCHE: AddEthereumChainParameter['nativeCurrency'] = {
  name: env.NETWORK.Avalanche.name,
  symbol: env.NETWORK.Avalanche.symbol,
  decimals: 18,
};
const AURORA: AddEthereumChainParameter['nativeCurrency'] = {
  name: env.NETWORK.Aurora.name,
  symbol: env.NETWORK.Aurora.symbol,
  decimals: 18,
};
type ChainConfig = {
  [chainId: number]: BasicChainInformation | ExtendedChainInformation;
};
function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation,
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency;
}
export const MAINNET_CHAINS: ChainConfig = {
  1: {
    urls: [`${env.SUPPORT_NETWORK[1]}`],
    name: 'Ethereum Mainnet',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://etherscan.io'],
    logo: ethLogo,
  },
  56: {
    urls: [`${env.SUPPORT_NETWORK[56]}`, `https://bsc-dataseed.binance.org/`],
    name: 'BNB',
    nativeCurrency: BNB,
    blockExplorerUrls: ['https://bscscan.com'],
    logo: bscLogo,
  },
  137: {
    urls: [`${env.SUPPORT_NETWORK[137]}`],
    name: 'Polygon',
    nativeCurrency: POLYGON,
    blockExplorerUrls: ['https://polygonscan.com'],
    logo: polygonLogo,
  },
  10: {
    urls: [`${env.SUPPORT_NETWORK[10]}`],
    name: 'Optimism',
    nativeCurrency: OPTIMISM,
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
    logo: optimismLogo,
  },
  42161: {
    urls: [`https://endpoints.omniatech.io/v1/arbitrum/one/public`],
    name: 'Arbitrum',
    nativeCurrency: ARBITRUM,
    blockExplorerUrls: ['https://arbiscan.io'],
    logo: arbitrumLogo,
  },
  43114: {
    urls: [`https://avalanche-c-chain.publicnode.com`],
    name: 'Avalanche',
    nativeCurrency: AVALANCHE,
    blockExplorerUrls: ['https://cchain.explorer.avax.network'],
    logo: avalancheLogo,
  },
  250: {
    urls: [`https://fantom.publicnode.com`],
    name: 'Fantom',
    nativeCurrency: FANTOM,
    blockExplorerUrls: ['https://ftmscan.com'],
    logo: fantomLogo,
  },
  // 1313161554: {
  //   urls: [`https://endpoints.omniatech.io/v1/aurora/mainnet/public`],
  //   name: 'Aurora',
  //   nativeCurrency: AURORA,
  //   blockExplorerUrls: ['https://explorer.aurora.dev'],
  //   logo: auroraLogo,
  // },
};
export const CHAINS: ChainConfig = {
  ...MAINNET_CHAINS,
};
export function getAddChainParameters(
  chainId: number,
): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId];
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    };
  }
  return chainId;
}
export const URLS: { [chainId: number]: string[] } = Object.keys(
  CHAINS,
).reduce<{
  [chainId: number]: string[];
}>((accumulator, chainId) => {
  const validURLs: string[] = CHAINS[Number(chainId)].urls;
  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs;
  }
  return accumulator;
}, {});
