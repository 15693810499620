/* eslint-disable no-unused-expressions */
/* eslint-disable no-void */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import { Button, Spin } from 'antd';
import { AuthContext } from 'contexts/AuthProvider';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ARCAPIService } from 'services/ARCAPIService';
import { __debounce } from 'utility/debounce';
import {
  useAccount,
  useChainId,
  useConnect,
  useConnectors,
  useDisconnect,
  useSwitchChain,
} from 'wagmi';
import TrustWalletIcon from '../assets/trustwallet.png';
import BalanceCard from './balancecard';
import { CHAINS } from './chains';
import NetworkCard from './networkcard';
import './style.scss';
import UserCard from './usercard';

type TrustwalletProps = {
  isTopBar?: boolean;
};

const TrustwalletCard: React.FC<TrustwalletProps> = ({ isTopBar }) => {
  const {
    setUser,
    setUserBalances,
    setWalletAvailableValue,
    setUniqueSymbols,
    setSymbols,
    setIsLoading,
    setisDisconnect,

    setAllOpenOrders,
    setIsAuthenticated,
    isLoading,
    setwalletAddress,
    walletAddress,
    chainId,
    setisRightBar,
    isAuthenticated,
    setisTypeConnect,
    userAuthorize,
    hasBalance,
  } = useContext(AuthContext);

  const activeChainId = useChainId();
  const { address, connector } = useAccount();
  const { connect } = useConnect();
  const connectors = useConnectors();
  const { disconnect } = useDisconnect();
  const { switchChain } = useSwitchChain();
  const isActive = !!connector?.name.toLowerCase().includes('trust');

  const [desiredChainId, setDesiredChainId] = useState(chainId);

  // const mobile = isMobile;
  const mobile = useMediaQuery({ maxWidth: 991 });

  const handleActivate = async () => {
    localStorage.setItem('@app:type', 'trustwallet');
    setisTypeConnect('trustwallet');
    if (mobile) {
      setisRightBar(false);
    }
    connect({
      connector: connectors.find((connector) =>
        connector.name.toLowerCase().includes('trust wallet'),
      ),
    });
  };

  const handleSwitchChain = async (chainId: number) => {
    setDesiredChainId(chainId);
    localStorage.removeItem('@app:jwt');
    localStorage.removeItem('@app:user');
    switchChain({ chainId });
  };

  const handleDeactivate = async () => {
    disconnect();
    setUser({});
    setUserBalances({});
    setWalletAvailableValue(0);
    setUniqueSymbols([]);
    setSymbols([]);
    setAllOpenOrders([]);
    setIsAuthenticated(false);
    setisDisconnect(false);
    setIsLoading(false);

    ARCAPIService.logout();
  };

  useEffect(() => {
    if (address && isActive) {
      setwalletAddress(address);
      setUser(address);
      window.fbq('track', 'AddPaymentInfo');
    } else {
      setisDisconnect(true);
    }
  }, [address, isActive]);

  const callUserAuthorize = () => {
    userAuthorize(null, activeChainId, address, CHAINS);
  };
  useEffect(() => {
    if (isActive && address) {
      __debounce(callUserAuthorize, 200, 'userAuthorize');
    }
  }, [desiredChainId, activeChainId, isActive, address]);

  return (
    <>
      {isActive && isAuthenticated ? (
        <>
          {isTopBar ? (
            <div className="card-top">
              <NetworkCard
                activeChain={activeChainId}
                onselect={handleSwitchChain}
              />
              {!mobile && <UserCard account={walletAddress} />}
            </div>
          ) : (
            <div>
              <BalanceCard
                tokenBalance={hasBalance}
                account={walletAddress}
                isLoading={isLoading}
                onDeactivate={handleDeactivate}
                icon={TrustWalletIcon}
              />
            </div>
          )}
        </>
      ) : (
        <>
          {!isAuthenticated && !isTopBar ? (
            <Button
              className="btn-connect-right"
              onClick={() => handleActivate()}
            >
              <div className="btn-connect-right-inside">
                <Spin spinning={false} />

                <img
                  src={TrustWalletIcon}
                  alt="TrustWallet"
                  height={40}
                  width={40}
                />
                <div>
                  <span className="btn-right-subtile">Trust Wallet</span>
                </div>
              </div>
            </Button>
          ) : null}
        </>
      )}
    </>
  );
};

export default TrustwalletCard;
