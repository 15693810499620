/* eslint-disable @typescript-eslint/no-unused-vars */
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  setDoc,
  query,
  where,
  getDocs,
  deleteDoc,
} from 'firebase/firestore';
import { IFUser, IFToken } from 'interfaces/FireStore';
import env from 'config/global-env';

const firebaseConfig = {
  apiKey: env.FIREBASE.apiKey,
  authDomain: env.FIREBASE.authDomain,
  projectId: env.FIREBASE.projectId,
  storageBucket: env.FIREBASE.storageBucket,
  messagingSenderId: env.FIREBASE.messagingSenderId,
  appId: env.FIREBASE.apiAppId,
  measurementId: env.FIREBASE.measurementId,
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const db = getFirestore(app);

export const UserCollection = collection(db, 'users');
export const TokensCollection = collection(db, 'tokens');
export const GemsCollection = collection(db, 'gems');

export async function addUser(address: string): Promise<string> {
  try {
    const docRef = await addDoc(UserCollection, {
      address,
      status: true,
    });

    return docRef.id;
  } catch (e) {
    console.error('Error adding document: ', e);
    return '';
  }
}

export async function getUser(address: string): Promise<string | null> {
  try {
    const q = query(UserCollection, where('address', '==', address));

    const querySnapshot = await getDocs(q);
    const ids = [];
    querySnapshot.forEach((doc) => {
      ids.push(doc.id);
    });
    if (ids.length > 0) {
      return ids[0];
    }
    return '';
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

export async function updateUserStatus(
  docId: string,
  status: boolean,
  walletAddress: string,
) {
  try {
    await setDoc(doc(UserCollection, docId), {
      address: walletAddress,
      status,
    });
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

export async function addTokens(
  type: string,
  mark: number,
  tokenName: string,
  chainName: string,
  chainAvatar: string,
  viewed: boolean,
  address: string,
): Promise<string> {
  try {
    const docRef = await addDoc(TokensCollection, {
      type,
      mark,
      tokenName,
      chainName,
      chainAvatar,
      address,
      viewed,
    });

    return docRef.id;
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

export async function updateTokensStatus(docId: string, data: any) {
  try {
    await setDoc(doc(TokensCollection, docId), {
      ...data,
    });
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

export async function deleteTokens(docId: string) {
  try {
    await deleteDoc(doc(TokensCollection, docId));
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

export const FireDB = db;
