/* eslint-disable @typescript-eslint/ban-types */

import { Grid, Modal } from 'antd';
import React from 'react';
import './style.scss';

interface IARCModal {
  children: React.ReactNode;
  onClose?: Function;
  open: boolean;
}

const ARCModal: React.FC<IARCModal> = ({ children, onClose, open }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const breakpoints = Grid.useBreakpoint();
  return (
    <Modal
      centered
      destroyOnClose
      closeIcon={false}
      open={open}
      className="depo__modal-selector d-flex justify-center depo__ant-modal-mask"
      wrapClassName="modal_wrap"
      onCancel={($e) => {
        if (onClose) onClose($e);
      }}
    >
      {children}
    </Modal>
  );
};

export default ARCModal;
