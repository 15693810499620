/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import { Provider } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { Spin } from 'antd';
import { AuthContext } from 'contexts/AuthProvider';

import NotAllowedPage from 'pages/NotAllowed';
import SwapHoudini from 'pages/Anonymous/houdini';

import Restriction from 'pages/Restriction';
import Subscription from 'pages/Subscription';
import FarmWithdraw from 'pages/Farm/farm-withdraw';
import Contact from 'pages/Contact';
import RiskStatement from 'pages/RiskStatement';
import RepublicationTerms from 'pages/RepublicationTerms';
import FinancialServicesProvider from 'pages/FinancialServicesProvider';

import DefaultLayout from '../components/Layout/DefaultLayout';
import Account from '../pages/Account';
import Farm from '../pages/Farm/farm';
import Swap from '../pages/Swap/swap';
import store from '../state';
import Route from './route';

const Routes: React.FC = () => {
  const { walletLoading, isAllowed } = useContext(AuthContext);
  return (
    <Provider store={store}>
      <Spin spinning={walletLoading}>
        <Switch>
          {isAllowed ? (
            <>
              <Route exact path="/restriction" component={Restriction} />
              <DefaultLayout>
                <Switch>
                  <Route exact path="/" component={Farm} />
                </Switch>
              </DefaultLayout>
            </>
          ) : (
            <>
              <Route exact path="/swap" component={NotAllowedPage} />
              <Redirect from="/" to="/swap" />
            </>
          )}
        </Switch>
      </Spin>
    </Provider>
  );
};

export default Routes;
