/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext } from 'react';
import './style.scss';
import {
  CloseOutlined,
  DoubleLeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import MetamaskCard from 'connect/metamaskcard';
import CoinbaseCard from 'connect/coinbasecard';
import WalletConnectCard from 'connect/walletconnectcard';
import TxHistory from 'components/TxHistory';
import { AuthContext } from '../../contexts/AuthProvider';
import { DappItems } from './menu-items';
import { ThemeContext } from '../../contexts/ThemeContext';
import sun from '../../assets/sun.svg';
import moon from '../../assets/moon.svg';
import subscription from '../../assets/subscription.svg';

const images = require.context('../../assets/', true);

const ARCLeftSidebar: React.FC = () => {
  const {
    isLeftBar,
    setisLeftBar,
    isAuthenticated,
    setOpenSubscriptionModal,
    setOpenSubscriptionInfoModal,
  } = useContext(AuthContext);
  const menuItems = DappItems;
  const { pathname } = useLocation();
  const { theme, setTheme } = useContext(ThemeContext);
  const handleThemeChange = () => {
    const isCurrentDark = theme === 'dark';
    setTheme(isCurrentDark ? 'light' : 'dark');
    localStorage.setItem('default-theme', isCurrentDark ? 'light' : 'dark');
  };
  return (
    <div
      className={
        isLeftBar ? 'arc-leftbar-dialog show' : 'arc-leftbar-dialog hide'
      }
      role="document"
    >
      {/* <div className="arc-leftsiderbar-wrapper"> */}

      <div className="modal-content arc-leftsiderbar-wrapper">
        <div className="arc-leftsidebar-main">
          <div className="arc_leftsidebar_header">
            <Button
              type="text"
              className="btn-themes"
              onClick={handleThemeChange}
            >
              {theme === 'light' ? (
                <img src={sun} height={20} alt="Light" />
              ) : (
                <img src={moon} height={20} alt="Dark" />
              )}
            </Button>
            <CloseOutlined
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setisLeftBar(false);
              }}
            />
          </div>
          <div
            className="arc_leftsidebar_subscription"
            onClick={() => setOpenSubscriptionInfoModal(true)}
          >
            <div className="d-flex alin-items-center">
              <img src={subscription} alt="subscription icon" />
              <span>Your subscription</span>
            </div>
            <RightOutlined />
          </div>
          <div className="arc_leftsidebar_wallet_card">
            <MetamaskCard />
            <CoinbaseCard />
            <WalletConnectCard />
          </div>
          {/* <div>
            <TxHistory theme={theme} />
          </div> */}
          <div
            className="arc_leftsidebar_footer"
            onClick={() => {
              setOpenSubscriptionModal(true);
            }}
          >
            <div className="left">
              <div className="title">Get ARC+</div>
              <div className="description">
                Get 24/7 security alerts, next gems and more
              </div>
            </div>
            <RightOutlined />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ARCLeftSidebar;
