/* eslint-disable */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, Typography, Input, Image, Row, Col, Checkbox } from 'antd';
import styled from 'styled-components';
import { ThemeContext } from '../../contexts/ThemeContext';
import ARCModal from '../ARCModal/ARCModal';
import './style.scss';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { AuthContext } from '../../contexts/AuthProvider';

const divButtonDark = styled.div`
  .ant-btn-primary:hover {
    color: #fff;
  }
`;
const divButtonLight = styled.div`
  .ant-btn-primary:hover {
    color: #000;
  }
`;
const AIDisclaimerModal: React.FC<{
  onSelect: Function;
  visible: boolean;
  onClose: () => void;
  mobile?: boolean;
}> = ({ onSelect, visible, onClose, mobile }) => {
  const { theme } = useContext(ThemeContext);
  const [modalIndex, setModalIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isDisable, setisDisable] = useState(true);
  const listInnerRef = useRef<HTMLDivElement>(null);
  const { aiModeSwap, setaiModeSwap, setaiModeRegister } =
    useContext(AuthContext);

  const onChange = (e: CheckboxChangeEvent) => {
    setisDisable(!e.target.checked);
  };
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop + clientHeight + 3 > scrollHeight) {
        setisDisable(false);
      }
    }
  };
  const onCloseHandler = () => {
    setModalIndex(0);
    onClose();
  };

  const onCloseHandlerPremium = () => {
    setaiModeSwap(true);
    setModalIndex(0);
    onClose();
  };

  const diclaimerText = () => {
    return (
      <div className="disclaimer-text">
        <p style={{ fontWeight: '600' }}>
          To get started with AI Audits, Anonymous Swaps, and our Premium UI,
          you're required to hold at least 25,000 $ARC tokens in your wallet
          connected to our dApp.
        </p>
        <p>Follow these easy steps to begin:</p>
        <ul>
          <li>
            1. Click on the <strong>"Premium button"</strong> below.
          </li>
          <li>
            2. The swaps box will automatically populate with 25,000 $ARC.
          </li>
          <li>
            3. Choose any 'lesser' token that you wish to convert into $ARC.
            &#x1F609;
          </li>
          <li>4. Click 'Swap' and follow the subsequent instructions.</li>
          <li>5. Welcome to Premium! We hope you enjoy.</li>
          <li>
            6. Remember, your tokens need to stay put. They can't be staked or
            moved.
          </li>
          <li>
            7. The site will automatically detect the $ARC in your wallet every
            time you log in.
          </li>
        </ul>
        <p>
          Gear up to enhance your dApp experience with us! Remember to always
          stay smart and safe with your transactions.
        </p>
      </div>
    );
  };

  return (
    <>
      <ARCModal open={visible} onClose={onClose}>
        <div
          className={`depo__fulfill-modal  ${
            theme === 'light' ? 'bg-white' : 'bg-dark'
          } rounded-modal`}
          style={{ minWidth: mobile ? '320px' : '500px' }}
        >
          <div className="depo__token-select-tab">
            <div
              className={
                theme === 'light'
                  ? 'depo__token-select-mainpanel-light'
                  : 'depo__token-select-mainpanel-dark'
              }
            >
              <div className="p-4" style={{ height: '500px' }}>
                <Typography
                  className="title"
                  style={{
                    color: theme === 'light' ? '#007aff' : '#007aff',
                    textAlign: 'center',
                    fontSize: '18px',
                  }}
                >
                  Unlock Premium Features in a Few Simple Steps
                </Typography>

                {/* disclaimer text */}
                <div
                  className="depo__token-list"
                  style={{ height: '350px' }}
                  onScroll={onScroll}
                >
                  {diclaimerText()}
                </div>

                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    type="primary"
                    className={`btn-lg   ${
                      theme === 'light' ? 'text-dark' : 'text-white'
                    } rounded`}
                    style={{ marginTop: `5px` }}
                    onClick={onCloseHandlerPremium}
                  >
                    Upgrade to Premium
                  </Button>
                  <span className="mx-2"> </span>
                  <Button
                    type="primary"
                    className={`btn-lg  ${
                      theme === 'light' ? 'text-dark' : 'text-white'
                    }  rounded`}
                    style={{
                      marginTop: `5px`,
                      backgroundColor: 'red',
                      borderColor: 'red',
                    }}
                    onClick={onCloseHandler}
                  >
                    Not now 😥
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ARCModal>
    </>
  );
};

export default AIDisclaimerModal;
