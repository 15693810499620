import { ILiquidityNetwork } from '../interfaces/NetworkLiquidityProtocol';
import SupportedNetworks from '../interfaces/SupportedNetworks';
import { SupportedAPIPools } from '../interfaces/NetworkPools';
import protocols from '../utility/liquidity-protocols/protocols';

const lastConnectedChainId = Number(
  localStorage.getItem('@app:lastConnectedChainId') || '1',
);
const env = {
  CHAIN_ID: lastConnectedChainId as keyof SupportedNetworks, // 1 as keyof SupportedNetworks,
  DEFAULT_TO_CHAIN_ID: 10,
  WALLETCONNECTID: process.env.REACT_APP_WALLETCONNECT_ID,
  SUPPORT_NETWORK: {
    1: process.env.REACT_APP_WEB3PROVIDER_ETHEREUM,
    56: process.env.REACT_APP_WEB3PROVIDER_BNB,
    137: process.env.REACT_APP_WEB3PROVIDER_POLYGON,
    10: process.env.REACT_APP_WEB3PROVIDER_OP,
    42161: process.env.REACT_APP_WEB3PROVIDER_ARBITRUM,
    43114: process.env.REACT_APP_WEB3PROVIDER_AVALANCHE,
    1313161554: process.env.REACT_APP_WEB3PROVIDER_AURORA,
    250: process.env.REACT_APP_WEB3PROVIDER_FANTOM,
  },
  CURRENT_WEB3_PROVIDER: process.env.REACT_APP_WEB3PROVIDER_ETHEREUM,
  /**
   * Find the router and factory contracts for a given protocol.
   *
   * The protocol must be zapper compatible and should be listed in the
   * protocols list.
   *
   * @param chainId
   * @param protocol
   * @returns
   */
  CONTRACTS(
    chainId: keyof SupportedNetworks,
    protocol: keyof SupportedAPIPools,
  ): ILiquidityNetwork {
    const hasProtocol = protocols.find(
      (item) =>
        item.parsedName === protocol &&
        item.networks.find(
          (network) => +parseInt(network.chainId, 16) === chainId,
        ),
    );
    if (hasProtocol) {
      const network = hasProtocol.networks.find(
        (item) => +parseInt(item.chainId, 16) === chainId,
      );
      if (network) {
        return network;
      }
    }

    throw new Error(
      `Protocol ${protocol} not supported for network id ${chainId}`,
    );
  },

  API: {
    ARC: process.env.REACT_APP_ARC_WEBSERVICE,
    ETHERSCAN: process.env.REACT_APP_API_ETHERSCAN,
    COINGECKO: process.env.REACT_APP_API_COINGECKO,
    ONEINCH_1: process.env.REACT_APP_API_ONEINCH_1,
    ONEINCH_2: process.env.REACT_APP_API_ONEINCH_2,
    ONEINCH_3: process.env.REACT_APP_API_ONEINCH_3,
    ONEINCH: process.env.REACT_APP_API_ONEINCH,
    ONEINCH_KEY: process.env.REACT_APP_API_ONEINCH_KEY,
    OKX_URL: process.env.REACT_APP_API_OKX_URL,
    OKX_PASSPHRASE: process.env.REACT_APP_API_OKX_PASSPHRASE,
    OKX_SECRET: process.env.REACT_APP_API_OKX_SECRET,
    OKX_KEY: process.env.REACT_APP_API_OKX_KEY,
  },
  ARC_TOKEN: {
    _id: '0xC82E3dB60A52CF7529253b4eC688f631aad9e7c2',
    address: '0xC82E3dB60A52CF7529253b4eC688f631aad9e7c2',
    decimals: 18,
    logoURI: 'https://d22g15ek9v9au.cloudfront.net/ARC-Circle.png',
    name: 'ARC',
    symbol: 'ARC',
  },
  ERC20STATIC: [
    {
      _id: '0xC82E3dB60A52CF7529253b4eC688f631aad9e7c2',
      address: '0xC82E3dB60A52CF7529253b4eC688f631aad9e7c2',
      decimals: 18,
      logoURI: 'https://d22g15ek9v9au.cloudfront.net/ARC-Circle.png',
      name: 'ARC',
      symbol: 'ARC',
    },
    {
      _id: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      symbol: 'WBTC',
      name: 'Wrapped BTC',
      address: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      decimals: 8,
      logoURI:
        'https://tokens.1inch.io/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.png',
    },
    {
      _id: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      symbol: 'ETH',
      name: 'Ethereum',
      decimals: 18,
      address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      logoURI:
        'https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
    },
    {
      _id: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      symbol: 'WETH',
      name: 'Wrapped ETH',
      decimals: 18,
      address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
      logoURI:
        'https://tokens.1inch.io/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png',
    },
    {
      _id: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      symbol: 'USDC',
      name: 'USDC',
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      decimals: 6,
      logoURI:
        'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
    },
    {
      _id: '0x6b175474e89094c44da98b954eedeac495271d0f',
      symbol: 'DAI',
      name: 'Dai',
      decimals: 18,
      address: '0x6b175474e89094c44da98b954eedeac495271d0f',
      logoURI:
        'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
      eip2612: true,
    },
  ],
  COINGECKO: process.env.REACT_APP_COINGECKO_API,
  COINGECKO_KEY: process.env.REACT_APP_API_COINGECKO_KEY,
  REFFERERADDRESS: process.env.REACT_APP_REFFERERADDRESS,
  FEE: process.env.REACT_APP_FEE_ARC || 0.2,
  HOLD_ARC: process.env.REACT_APP_HOLD_ARC || 25000,
  NETWORK: {
    Ethereum: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    Binance: {
      name: 'Binance Smart Chain',
      symbol: 'BNB',
      decimals: 18,
    },
    Polygon: {
      name: 'Polygon',
      symbol: 'MATIC',
      decimals: 18,
    },
    Optimism: {
      name: 'Optimism',
      symbol: 'ETH',
      decimals: 18,
    },
    Arbitrum: {
      name: 'Arbitrum',
      symbol: 'ETH',
      decimals: 18,
    },
    Avalanche: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    Aurora: {
      name: 'Aurora',
      symbol: 'ETH',
      decimals: 18,
    },
    Fantom: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18,
    },
  },
  FIREBASE: {
    apiKey:
      process.env.REACT_APP_API_APIKEY ||
      'AIzaSyCq654V_kgDPrVy67Na23XKyb5J1opoSgk',
    authDomain:
      process.env.REACT_APP_API_AUTHDOMAIN ||
      'arcnotification-70697.firebaseapp.com',
    projectId: process.env.REACT_APP_API_PROJECTID || 'arcnotification-70697',
    storageBucket:
      process.env.REACT_APP_API_STORAGEBUCHKET ||
      'arcnotification-70697.appspot.com',
    messagingSenderId:
      process.env.REACT_APP_API_MESSAGINGSENDERID || '1039028796660',
    apiAppId:
      process.env.REACT_APP_API_APPID ||
      '1:1039028796660:web:e942ac82c9bfbd5d07f2a1',
    measurementId: process.env.REACT_APP_API_MEASUREMENTID || 'G-NWX815WBH5',
  },
  DISCORD: {
    clientId: process.env.REACT_APP_DISCORD_CLIENT_ID,
    clientSecret: process.env.REACT_APP_DISCORD_CLIENT_SECRET,
    inviteLink: process.env.REACT_APP_DISCORD_INVITE_LINK,
  },
  SUBSCRIPTION: {
    stripeApiUrl: process.env.REACT_APP_STRIPE_API_URL,
    radomManagementUrl: process.env.REACT_APP_RADOM_MANAGEMENT_URL,
    paymentPageUrl: process.env.REACT_APP_PAYMENT_OPTION_URL,
  },
  METAMASK: {
    extentionUrl: process.env.REACT_APP_METAMASK_EXTENTION_URL,
  },
};
export default env;
