/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import './style.scss';

const Loading: React.FC<{
  className?: string;
  theme?: string;
}> = ({ className, theme = 'dark' }) => (
  <>
    <div
      className={`depo__loading-icon text-center d-flex justify-center align-center ${className}`}
      style={{ background: 'transparent' }}
    >
      <LoadingOutlined
        style={{ color: theme === 'light' ? '#007AFF' : '#fff' }}
      />
    </div>
  </>
);

export default Loading;
