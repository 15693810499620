/* eslint-disable */
import Axios, { AxiosError } from 'axios';
import BigNumber from 'bignumber.js';
import { I1InchAllowanceRequestParams } from 'interfaces/I1Inch';
import env from '../config/global-env';
import { I1InchOperation } from '../interfaces/I1InchOperation';
import { I1InchQuote, I1InchQuoteParams } from '../interfaces/I1InchQuote';
import {
  I1InchApprovalParams,
  I1InchApprovalReponse,
  I1InchSwap,
  I1InchSwapParams,
} from '../interfaces/I1InchSwap';
import { IProtocol } from '../interfaces/IProtocol';
import { IToken } from '../interfaces/IToken';
import { ARCAPIService } from './ARCAPIService';

/**
 * Service for 1Inch API communication.
 *
 * This static class is used to operate over 1Inch Exchange API aiming
 * swap, quote, token listing and protocols operations.
 *
 * @param {string} apiUrl
 *
 * @static fetchProtocols :: `IProtocolList`
 * @static fetchTokens :: `ITokenList`
 * @static getQuote :: `I1InchQuote`
 * @static getUrl :: `String`
 *
 * ```ts
 * import { OneInchService } from '@/services/OneInchService';
 *
 * async function getTokens() {
 *  return await OneInchService.getTokens();
 * }
 * ```
 *
 * @author [Pollum](pollum.io)
 * @since v0.1.0
 */
const MAX_RETRY_COUNT = 3; // Maximum number of retry attempts
const RETRY_DELAY_MS = 3000; // Delay between retries in milliseconds
export class OneInchService {
  /**
   * @var {number} chainId the current network id.
   */
  static chainId = env.CHAIN_ID;
  /**
   * @var {string} apiUrl Current URL for 1Inch API
   */
  // https://api-arcmarket.1inch.io/
  static apiUrl = env.API.ONEINCH;
  static apiUrlAlt2 = env.API.ONEINCH_2;
  static apiUrlAlt = env.API.ONEINCH_3;
  static apiKey = env.API.ONEINCH_KEY;
  static commonTokens = [
    'ETH',
    'UNI',
    'DAI',
    'USDT',
    'USDC',
    'BUSD',
    'SUSHI',
    '1INCH',
    'BAT',
    'MATIC',
    'BNB',
  ];
  static unusedDexProtocols = [
    'chai',
    'clipper',
    'cofix',
    'convergence x',
    'creamswap lending',
    'curve v2 eth crv',
    'curve v2 eth cvx',
    'curve v2 eurs',
    'curve v2 eurt',
    'curve v2 xaut',
    'dfx finance',
    'dodo',
    'swapr',
    'indexed finance',
    'mooniswap',
    'oasis',
    'liqpool x',
    'powerindex',
    'saddle finance',
    'smoothy finance',
    'value liquid',
    'xsigma',
  ];

  static async GetAuth<T>(url: string): Promise<T> {
    let temp = url;
    const location = window.location.href;
    if (location.indexOf('localhost') < 0) {
      temp = this.apiUrl + url;
    }
    const { data } = await Axios.get(temp, {
      headers: {
        Authorization: `Bearer ${this.apiKey}`,
        // 'Access-Control-Allow-Headers': '*',
        // 'Access-Control-Allow-Methods': '*',
        // 'Access-Control-Allow-Origin': 'http://localhost:3000',
      },
    });

    return data as T;
  }

  /**
   * Fetches the protocol list from 1Inch API.
   *
   * @see [Protocols](https://docs.1inch.io/api/protocols)
   * @returns
   */
  static async fetchProtocols(
    retryCount: number = 0,
  ): Promise<Array<IProtocol> | undefined> {
    const url = this.getUrl('protocols', retryCount);
    try {
      const result = await Axios.get(url);
      if (Array.isArray(result.data?.protocols)) {
        const sorted = result.data?.protocols.sort(
          (a: IProtocol, b: IProtocol) => (a.id > b.id ? 1 : -1),
        ) as Array<IProtocol>;
        return sorted;
      }
      return [];
    } catch (error: any) {
      // throw new Error(err.message);
      // console.log(error);
      const err = error as AxiosError;
      if (
        err.message === 'Network Error' ||
        (error.response && error.response.status === 429)
      ) {
        // console.log('---->>>>> Get list protocol');
        // console.log('---->>>>> Get Quote', retryCount);
        // const retryAfter = error.response.headers['retry-after'];
        const delay = RETRY_DELAY_MS;
        await new Promise((resolve) => setTimeout(resolve, delay));
        if (retryCount < MAX_RETRY_COUNT) {
          return this.fetchProtocols(retryCount + 1);
        } else {
          //  throw new Error('Maximum number of retry attempts reached.');
          return [] as Array<IProtocol>;
        }
        // if (retryCount < MAX_RETRY_COUNT) {
        //   return this.fetchProtocols(retryCount + 1);
        // } else {
        //   return [] as Array<IProtocol>;
      }
    }
  }

  /**
   * Fetches the token list from 1Inch API.
   *
   * @see [Tokens](https://docs.1inch.io/api/tokens)
   * @returns
   */
  static async fetchTokens(chainId?: number): Promise<Array<IToken>> {
    try {
      // const url = this.getUrl('tokens');
      // const result = await Axios.get(url);
      // if (result.data.tokens) {
      //   const tokens = Object.keys(result.data.tokens).map(
      //     (item) => result.data.tokens[item],
      //   );
      //   const sorted = tokens.sort((a: IToken, b: IToken) =>
      //     !this.commonTokens.find((item) => item === a.symbol) ? 1 : -1,
      //   );
      //   return sorted as Array<IToken>;
      // }
      const result = await ARCAPIService.getTokenList(
        chainId ? chainId : this.chainId,
      );
      return result as Array<IToken>;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.message);
    }
  }

  /**
   * Get a quotation from 1Inch Exchange using the chosen params.
   *
   * @see [Quote/Swap](https://portal.1inch.dev/documentation/swap/swagger?method=get&path=%2Fv5.2%2F1%2Fquote)
   * @param opts {I1InchQuoteParams} params for quotation
   * @returns
   */
  static async getQuote(
    opts: I1InchQuoteParams,
    retryCount: number = 0,
  ): Promise<I1InchQuote> {
    try {
      const url = this.mountQuoteUrl(opts, retryCount);
      const data = await this.GetAuth<I1InchQuote>(url);
      return data;
    } catch (error: any) {
      const err = error as AxiosError;
      if (
        err.message === 'Network Error' ||
        (error.response && error.response.status === 429)
      ) {
        const delay = RETRY_DELAY_MS;
        await new Promise((resolve) => setTimeout(resolve, delay));
        if (retryCount < MAX_RETRY_COUNT) {
          return this.getQuote(opts, retryCount + 1);
        } else {
          throw new Error('Maximum number of retry attempts reached.');
        }
      }
    }
  }

  /**
   * Gets the swap data to fulfill swap. The swap data will change quotation from time to time
   * so it is important to warn user and refetch if the time is too long between call and action.
   *
   * @param opts
   * @returns
   */
  static async getSwap(
    opts: I1InchSwapParams,
    retryCount: number = 0,
  ): Promise<I1InchSwap> {
    try {
      opts.fee = 0;
      const url = this.mountSwapUrl(opts, retryCount);
      return await this.GetAuth<I1InchSwap>(url);
    } catch (error) {
      console.log(error);
      const err = error as AxiosError;
      throw new Error(
        err.response?.statusText ??
        "Sorry, we've got an unknown error, please try again.",
      );
    }
  }

  static async getAllowance(
    params: I1InchAllowanceRequestParams,
  ): Promise<string> {
    try {
      let url = `/swap/v5.2/${this.chainId}/approve/allowance`;
      url += `?tokenAddress=${params.tokenContractAddress}&walletAddress=${params.userWalletAddress}`;
      const data = await this.GetAuth<{ allowance: string }>(url);
      return data.allowance;
    } catch (e) {
      const err = e as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  /**
   * Get call data for approving and spending wallet's assets in order to perform
   * swap.
   *
   * @param {I1InchApprovalParams} opts approval parameters
   */
  static async getApproveCalldata(
    opts: I1InchApprovalParams,
  ): Promise<I1InchApprovalReponse> {
    if (!this.isNative(opts.token.address)) {
      try {
        let params = `?tokenAddress=${opts.token.address}`;
        if (opts.amount) {
          params += `&amount=${opts.amount}`;
        }

        const url = `/swap/v5.2/${this.chainId}/approve/transaction` + params;

        return await this.GetAuth<I1InchApprovalReponse>(url);
      } catch (error) {
        const err = error as AxiosError;
        throw new Error(err.response?.statusText);
      }
    } else {
      throw new Error('No approval needed to native token.');
    }
  }

  /**
   * Get call data for approving and spending wallet's assets in order to perform
   * swap.
   *
   * @param {I1InchApprovalV4Params} opts approval parameters
   */
  // static async approveCalldataV4(
  //   opts: I1InchApprovalParams,
  // ): Promise<I1InchApprovalReponse> {
  //   if (!this.isNative(opts.tokenAddress)) {
  //     try {
  //       const { data } = await Axios.get(
  //         this.apiUrl +
  //         this.chainId +
  //         `/approve/transaction?tokenAddress=${opts.tokenAddress}`,
  //       );
  //       return data as I1InchApprovalReponse;
  //     } catch (error) {
  //       const err = error as AxiosError;
  //       throw new Error(err.response?.data?.message);
  //     }
  //   } else {
  //     throw new Error('No approval needed to native token.');
  //   }
  // }

  /**
   * Mounts the API url based on the chosen operation
   *
   * @param op endpoint operation reference
   * @param params url params if needed
   * @returns
   */
  static getUrl(op: keyof I1InchOperation, isChangeUrl: number = 0): string {
    let apiUrl;
    if (isChangeUrl % 2 === 0) {
      apiUrl = this.apiUrl;
    } else {
      apiUrl = this.apiUrlAlt2;
    }
    let query;
    switch (op) {
      case 'protocols':
        query = `${this.chainId}/liquidity-sources/`;
        break;
      case 'tokens':
        query = `${this.chainId}/tokens`;
        break;
      case 'spender':
        query = `swap/v5.2/${this.chainId}/approve/spender`;
        break;
    }
    return `${query}`;
  }

  /**
   * Mounts the quotation url with its parameters.
   *
   * @param params
   * @returns
   */
  private static mountQuoteUrl(
    params: I1InchQuoteParams,
    isChangeUrl: number = 0,
  ): string {
    let query = `/swap/v5.2/${this.chainId}/quote?src=${params.fromToken.address}`;
    query += `&dst=${params.toToken.address}`;
    query += `&amount=${params.amount}`;
    query += `&includeGas=true`;

    return params.fromToken.imported || params.toToken.imported
      ? `${query}`
      : `${query}`;
  }

  /**
   * Mounts the swap url with its parameters.
   *
   * @param params
   * @returns
   */
  private static mountSwapUrl(
    params: I1InchSwapParams,
    isChangeUrl: number = 0,
  ) {
    let protocols: string[][] = [];
    if (params.protocols && params.protocols.length) {
      protocols = params.protocols.map((protocol) =>
        protocol.map((route) => route.name),
      );
    }

    let query = `swap/v5.2/${this.chainId}/swap?src=${params.fromTokenAddress}`;
    query += `&dst=${params.toTokenAddress}`;
    query += `&amount=${params.amount}`;
    query += `&from=${params.fromAddress}`;
    query += `&slippage=${params.slippage}`;
    if (params.fee && params.fee > 0) {
      query += `&fee=${params.fee}`;
      query += `&referrer=${env.REFFERERADDRESS}`;
    }
    protocols.length ? (query += `&protocols=${protocols.join(',')}`) : null;
    // if (params?.fromToken?.imported || params?.toToken?.imported) query += `&disableEstimate=true`;
    return params?.fromToken?.imported || params?.toToken?.imported
      ? `${query}`
      : `${query}`;
  }

  /**
   * Returns if the addr belongs to the native network token
   * @param addr token address
   * @returns
   */
  static isNative(addr: string) {
    return addr === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
  }

  /**
   * Convert transaction fee from wei to eth
   * @param gas Gas used
   * @param gasPrice Gas price
   * @param decimals Token decimal places
   * @returns
   */
  static getFee(
    gas: string | number,
    gasPrice: string,
    decimals: number,
  ): string {
    return new BigNumber(gas)
      .times(new BigNumber(gasPrice))
      .dividedBy(new BigNumber(10).pow(new BigNumber(decimals)))
      .toFixed(8);
  }
}
