export const swapModeOption = {
  swap: 'Swap',
  limitOrders: 'Limit Orders',
  crossChain: 'Cross Chain',
};
export const tabTitle = {
  all: 'All',
  security: 'Security',
  gems: 'Gems',
};
export const tabSettingTitle = {
  security: 'Security',
  gems: 'Gems',
};
export const ExpiresInOption = [
  {
    label: '10 min',
    value: 600,
  },
  {
    label: '30 min',
    value: 1800,
  },
  {
    label: '1 hour',
    value: 3600,
  },
  {
    label: '1 day',
    value: 86400,
  },
  {
    label: '3 day',
    value: 259200,
  },
  {
    label: '7 days',
    value: 604800,
  },
  {
    label: '1 month',
    value: 2592000,
  },
  {
    label: '3 months',
    value: 7776000,
  },
  {
    label: 'custom',
    value: -1,
  },
];
export const prompts = {
  portfolio: 'Portfolio',
  audit: 'Audit',
  research: 'Research',
};
export const limitOrderStatus = {
  '1': 'Open',
  '2': 'Invalid',
  '3': 'Occupied',
  '4': 'Expired',
  '5': 'Cancelled',
  '6': 'Completed',
  '7': 'Failed',
};
export const paymentOption = {
  stripe: 'stripe',
  radom: 'radom',
};
