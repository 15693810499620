/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { MetaMaskAvatar } from 'react-metamask-avatar';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { AuthContext } from '../../contexts/AuthProvider';
import { shortenAddressMobile } from '../../utility/addressHelpers';

const LoginButton = () => {
  const mobile = useMediaQuery({ maxWidth: 991 });
  const ctx = useContext(AuthContext);

  const showBar = () => {
    ctx.setisRightBar(!ctx.isRightBar);
  };

  return (
    <>
      {ctx.isAuthenticated ? (
        <Button
          className="d-flex align-items-center btn-wallet btn-sm rounded m-0"
          style={{
            display: 'flex',
            alignItems: 'center',
            borderColor: '#3d4251',
          }}
          onClick={showBar}
        >
          {/* {ctx.isLoading && <LoadingOutlined className="mr-2" />}{' '} */}
          {ctx.walletAddress && !mobile && (
            <MetaMaskAvatar address={ctx?.walletAddress || ''} />
          )}
          {ctx.walletAddress ? (
            <div>{shortenAddressMobile(ctx?.walletAddress || '')}</div>
          ) : (
            ''
          )}
        </Button>
      ) : (
        !mobile && (
          <Button
            className="btn-wallet btn-sm btn-metamask-onboarding rounded ml-4"
            style={{ borderColor: '#3d4251' }}
            onClick={showBar}
          >
            {/* {ctx.isLoading && <LoadingOutlined className="mr-2" />} Login */}
            Login
          </Button>
        )
      )}
    </>
  );
};

export default LoginButton;
