/* eslint-disable @typescript-eslint/no-unused-vars */
import Axios, { AxiosInstance } from 'axios';
import env from 'config/global-env';
import { ARCAPIService } from './ARCAPIService';

class DiscordService {
  static api = {
    baseURL: 'https://discordapp.com/api/v9/',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  /**
   * Retrieves the user information for the authenticated user.
   * @returns A Promise that resolves to the user data.
   */
  public static async getMe(): Promise<any> {
    const tokenData: any = localStorage.getItem('@app:d')
      ? JSON.parse(localStorage.getItem('@app:d'))
      : null;
    try {
      const result = await Axios.get(`${this.api.baseURL}users/@me`, {
        headers: {
          Authorization: `Bearer ${tokenData.access_token}`,
        },
      });
      const xData = { ...result.data, token: tokenData };
      const x = await ARCAPIService.UpdateDiscord(xData);
      return result.data;
    } catch (error) {
      console.log(error);
    }
  }

  public static async revoke(): Promise<any> {
    const tokenData: any = localStorage.getItem('@app:d')
      ? JSON.parse(localStorage.getItem('@app:d'))
      : null;
    try {
      const data = new URLSearchParams();
      data.append('client_id', env.DISCORD.clientId);
      data.append('client_secret', env.DISCORD.clientSecret);
      data.append('token', tokenData.access_token);
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      const result = await Axios.post(
        `https://discord.com/api/oauth2/token/revoke`,
        data.toString(),
        config,
      );
      return result.data;
    } catch (error) {
      console.log(error);
    }
  }
}

export default DiscordService;
