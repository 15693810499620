import addresses from '../config/constants/contracts';

type AddressMapping = {
  [key: number]: string;
};

export const getAddress = (address: AddressMapping, chainId: number): string =>
  address[chainId];

export const getDepoMasterChefAddress = (
  chainId: number,
  version: number,
): string =>
  getAddress(
    version === 1 ? addresses.masterChef : addresses.masterChefV2,
    chainId,
  );

export const getArcMasterChefAddress = (
  chainId: number,
): ReturnType<typeof getAddress> =>
  getAddress(addresses.arcMasterChef, chainId);

export const getMulticallAddress = (
  chainId: number,
): ReturnType<typeof getAddress> => getAddress(addresses.multiCall, chainId);

export const shortenAddress = (address: string): string =>
  `${address.substring(0, 6)}...${address.substring(
    address.length - 4,
    address.length,
  )}`;
export const shortenAddressMobile = (address: string): string =>
  `${address.substring(0, 4)}...${address.substring(
    address.length - 2,
    address.length,
  )}`;
