/* eslint-disable */
import Axios, { AxiosError, AxiosInstance } from 'axios';
import { AIToken } from 'interfaces/AIToken';
import { I1InchAllowanceRequestParams } from 'interfaces/I1Inch';
import { I1InchQuoteParams } from 'interfaces/I1InchQuote';
import {
  I1InchAllowanceResponse,
  I1InchApprovalParams,
  I1InchApprovalReponse,
  I1InchQuoteResponse,
  I1InchSwapParams,
  I1InchSwapResponse,
} from 'interfaces/I1InchSwap';
import env from '../config/global-env';
import { ILiquidityProvision } from '../interfaces/ILiquidityProvision';
import { IOrder } from '../interfaces/IOrder';
import { IToken } from '../interfaces/IToken';
import { IUser } from '../interfaces/IUser';
import { SupportedAPIPools } from '../interfaces/NetworkPools';
import { SubgraphPair, SubgraphPools } from '../interfaces/Subgraphs';

/**
 * This is the default service to communicate with DePo Platform webservice.
 *
 * API routing is based in `.env` file by `REACT_APP_DEPO_WEBSERVICE` constant.
 *
 * @static `authenticate` :: `IUser | IResponse`
 * @static `updateUser` :: `any | null | IResponse`
 * @static `getUser` :: `IUser | IResponse`
 *
 * ```ts
 * import { DepoAPIService } from '@/services/DepoAPIService';
 * import { IUser } from '@/interfaces/IUser';
 *
 * async function authenticate(walletId: string): Promise<IUser> {
 *  const authenticate = await DepoAPIService.authenticate(walletId);
 *
 *  if('error' in authenticate) {
 *      console.error(autenticate.error);
 *      return false;
 *  }
 *  return authenticate;
 * }
 *
 *
 * ```
 *
 * @author [Pollum](pollum.io)
 * @since v0.1.0
 */

export class ARCAPIService {
  /**
   * @var api Axios instance for the current api
   * In order to use the variables set in Vercel, uncomment the line below baseURL
   */
  private static api: AxiosInstance = Axios.create({
    baseURL: env.API.ARC ?? 'https://api.arc.market',
  });
  static MAX_RETRY_COUNT = 3; // Maximum number of retry attempts
  static RETRY_DELAY_MS = 3000; // Delay between retries in milliseconds
  /**
   * Authenticate the user into the platform using its wallet id.
   *
   * If the wallet id is not found, the server will create an instance
   * for the current wallet.
   *
   * @param walletId Ethereum wallet id
   * @returnsf
   */
  static async authenticate(
    walletId: string,
    signature: string,
  ): Promise<IUser | any> {
    try {
      const result = await this.api.post('ws/v2/user/auth', {
        walletId,
        signature,
      });

      localStorage.setItem('@app:user', JSON.stringify(result.data.user));
      localStorage.setItem('@app:jwt', result.data.jwt);

      const verified = await this.verifyAuthorization(result.data.jwt);
      if (verified) {
        return result.data.user as IUser;
      }
      throw new Error('Invalid signature.');
    } catch (error) {
      return error;
    }
  }

  /**
   * Authenticates a swap for a given wallet ID.
   * @param walletId The ID of the wallet to authenticate the swap for.
   * @returns The authenticated user or an error object.
   */
  static async authenticateSwap(walletId: string): Promise<IUser | any> {
    try {
      const result = await this.api.post('ws/v2/user/auth-swap', {
        walletId,
      });

      localStorage.setItem('@app:user', JSON.stringify(result.data.user));
      localStorage.setItem('@app:jwt', result.data.jwt);

      const verified = await this.verifyAuthorization(result.data.jwt);
      if (verified) {
        return result.data.user as IUser;
      }
      throw new Error('Invalid signature.');
    } catch (error) {
      return error;
    }
  }

  /**
   * Requests a signature message from the server
   * @param walletId
   * @returns
   */
  static async getSigningMessage(walletId: string) {
    try {
      const result = await this.api.get(`ws/v2/user/${walletId}/auth-message`);
      return result.data.message;
    } catch (error) {
      return false;
    }
  }

  /**
   * Sends a request to the webservice to update user's preferences and data.
   *
   * @param user
   * @param walletId
   * @returns
   */
  static async updateUser(user: IUser, walletId: string): Promise<any | false> {
    try {
      const result = await this.api.put(`ws/v2/user/${walletId}`, user);
      return result.data;
    } catch (error) {
      return false;
    }
  }

  /**
   * Sends a request to the webservice to get the current user details
   * including `wallets` and `preferences`
   *
   * @param walletId
   * @returns
   */
  static async getUser(walletId: string): Promise<IUser | false> {
    try {
      const result = await this.api.get(`ws/v2/user/${walletId}`);
      return result.data as IUser;
    } catch (error) {
      return false;
    }
  }

  /**
   * Checks the time by making a GET request to the API endpoint `/ws/v2/ext/feecharge`.
   * @returns A Promise that resolves to the result of the GET request if successful, or `false` if an error occurs.
   */
  static async checkTime() {
    try {
      const result = await this.api.get(`ws/v2/ext/feecharge`);
      return result;
    } catch (error) {
      return false;
    }
  }
  /**
   * Adds a liquidity pool to the user
   * @param user The current user instance
   * @param pool the new pool
   * @returns
   */
  static async addLiquidityPool(
    user: IUser,
    pool: SubgraphPair,
    protocol: keyof SupportedAPIPools,
    chainId: number,
  ) {
    const provisionData: ILiquidityProvision = {
      poolContractAddress: pool.id,
      protocol,
      token0: pool.token0,
      token1: pool.token1,
      chainId,
    };
    if (!user.liquidityProvisions)
      user.liquidityProvisions = [] as ILiquidityProvision[];
    if (
      user.settings?.defaultWallet &&
      !user.liquidityProvisions.find(
        (provision: any) =>
          provision.poolContractAddress.toLowerCase() === pool.id &&
          chainId === provision.chainId,
      )
    ) {
      try {
        user.liquidityProvisions.push(provisionData);
        const result = await this.updateUser(
          { liquidityProvisions: [...user.liquidityProvisions] },
          user.settings?.defaultWallet,
        );
        if (result) {
          localStorage.setItem('@app:user', JSON.stringify(user));
          return user;
        }
        throw new Error("Couldn't update user.");
      } catch (error) {
        const err = error as Error;
        throw new Error(err.message);
      }
    } else {
      throw new Error('Liquidity pool already exists.');
    }
  }

  /**
   * Sends a request to the webservice to remove an enchange key from the database.
   *
   * @param walletId
   * @param exchangeId
   * @param apiKey
   */
  static async removeApiKey(
    walletId: string,
    exchangeId: string,
    apiKey: string,
  ): Promise<any | false> {
    try {
      const result = await this.api.delete(
        `ws/v2/user/${walletId}/${exchangeId}/${apiKey}`,
      );
      return result.data;
    } catch (error) {
      return false;
    }
  }

  /**
   * Sends a request to the webservice to get order book from ccxt.
   *
   * @param exchangeName
   * @param symbol
   */
  static async getOrderBook(
    exchangeName: string,
    symbol: string,
  ): Promise<any | null> {
    try {
      const result = await this.api.get(
        `ws/v2/marketDetails/${exchangeName.replace('.', '')}/${symbol}`,
      );
      return result.data.response;
      // as IOrderBook;
    } catch (error) {
      return null;
    }
  }

  /**
   * Sends a request to the webservice to get order book from all exchanges.
   *
   * @param symbol
   * @param marketType
   */
  static async getAllOrderBook(
    symbol: string,
    marketType: string,
  ): Promise<any | null> {
    try {
      const response = await this.api.get(
        `ws/v2/marketDetails/orderBook/${marketType}/${symbol}`,
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }

  /**
     * Sends a request to the webservice to get orders ( open , history ).
            /**
     * Sends a request to the webservice to get market from ccxt.
     *
     * @param exchangeName
     * @param marketType
     * @param symbol
     */

  static async getUserOrders(
    walletId: string,
    marketType: string,
    symbol: string,
  ): Promise<any | null> {
    try {
      const result = await this.api.get(
        `ws/v2/ordersBook/${walletId}/${marketType}/${symbol}`,
      );
      // const result = await axios.get(`http://0.0.0.0:3001/ws/v2/ordersBook/${walletId}/${symbol}`);
      return result.data.response;
      // as IOrderBook;
    } catch (error) {
      return null;
    }
  }

  /**
   * Sends a request to the webservice to get CEX Balance.
   *
   * @param walletId
   * @param marketType
   */

  static async getUserCEXBalance(
    walletId: string,
    marketType: string,
  ): Promise<any | null> {
    try {
      await this.verifyAuthorization();
      const result = await this.api.get(
        `ws/v2/user/cexBalance/${walletId}/${marketType}`,
      );
      return result.data.response;
    } catch (error) {
      return null;
    }
  }

  /**
   * Sends a request to the webservice to get all user open orders.
   *
   * @param walletId
   */

  static async getAllUserOpenOrders(walletId: string): Promise<any | null> {
    try {
      const result = await this.api.get(`ws/v2/user/cexOpenOrders/${walletId}`);
      return result.data.response;
    } catch (error) {
      return null;
    }
  }

  /**
   * Sends a request to the webservice to get market by symbol (quote)
   *
   * @param exchangeName
   * @param symbol
   */
  static async getMarketBySymbol(
    exchangeName: string,
    symbol: string,
  ): Promise<any | null> {
    try {
      const result = await this.api.get(
        `ws/v2/market/${exchangeName.replace('.', '')}/${symbol}`,
      );
      return result.data;
    } catch (error) {
      return null;
    }
  }

  /**
   * Sends a request to the webservice to get all markets by symbol (quote)
   *
   * @param symbol
   * @param marketType
   */

  static async getAllMarketsBySymbol(
    symbol: string,
    marketType: string,
  ): Promise<any | null> {
    try {
      const result = await this.api.get(
        `ws/v2/market/allmarkets/${symbol}/${marketType}`,
      );
      return result.data;
    } catch (error) {
      return null;
    }
  }

  /**
   * Sends a request to the webservice to create an order market from ccxt.
   *
   * @param exchangeName
   * @param marketType
   * @param order
   * @returns
   */

  static async sendOrder(
    exchangeName: string,
    marketType: string,
    order: IOrder,
  ): Promise<any | null> {
    try {
      const result = await this.api.post(
        `ws/v2/order/${exchangeName.replace('.', '')}`,
        {
          marketType,
          order,
        },
      );
      return result.data;
    } catch (error) {}
  }

  /**
   * Sends a request to the webservice to cancel an order market from ccxt.
   *
   * @param exchangeName
   * @param orderId
   * @returns
   */

  static async cancelOrder(
    walletId: string,
    exchangeName: string,
    orderId: string,
    symbol: string,
  ): Promise<any | null> {
    try {
      const result = await this.api.post(
        `ws/v2/order/cancel/${walletId}/${exchangeName.replace(
          '.',
          '',
        )}/${orderId}/${symbol}`,
      );
      return result.data;
    } catch (error) {}
  }

  /**
   * Sends a request to the webservice to create an order market from ccxt.
   *
   * @param type
   * @param exchangeName
   * @param order
   * @returns
   */

  static async getMarketOverviewData(
    type: string,
    exchangeName: string,
    quote: string,
  ): Promise<any | null> {
    try {
      const result =
        type === 'spot'
          ? await this.api.get(
              `/ws/v2/mktOverview/spot/${exchangeName.replace(
                '.',
                '',
              )}/${quote}`,
            )
          : await this.api.get(
              `/ws/v2/mktOverview/future/${exchangeName.replace(
                '.',
                '',
              )}/${quote}`,
            );
      return result.data;
    } catch (error) {}
  }

  /**
   * Sends a request to the webservice to create an order market from ccxt.
   *
   * @param type
   * @param symbol
   * @returns
   */

  static async getSymbolAllExchanges(
    type: string,
    symbol: string,
  ): Promise<any | null> {
    try {
      const formatedSymbol = symbol.replace('/', '-');
      // const result = type === 'spot' ?  await this.api.get(
      //   `/ws/v2/mktOverview/overview/spot/${formatedSymbol}`,
      // ) : await this.api.get(
      //   `/ws/v2/mktOverview/overview/future/${formatedSymbol}`,
      // )
      const result = await this.api.get(
        `/ws/v2/mktOverview/overview/${type}/${formatedSymbol}`,
      );
      return result.data;
    } catch (error) {}
  }

  /**
   * Check if valid on CMC/CG
   * @param symbol
   */
  static async getListingValidity(address: string) {
    try {
      const result = await this.api.get(
        `ws/v2/tokenPrice/tokens/validate/${address}`,
      );
      // console.log('result', result);
      if (!result.data.data.cmc && !result.data.data.cg) return false;
      return true;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  /**
   * To get token info for token address array on CMC/CG
   * @param token address array
   */
  static async getTokenInfo(tokenAddresses: string[]) {
    try {
      const result = await this.api.post(`ws/v2/tokenPrice/tokens/validate`, {
        tokens: tokenAddresses,
      });
      return result?.data?.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  /**
   * Gets the price of the symbol in USDT
   * @param symbol
   */
  static async getPriceUSDT(symbol: string) {
    try {
      const result = await this.api.get(
        `ws/v2/tokenPrice/${symbol.toUpperCase()}`,
      );
      return result.data.price;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  /**
   * Gets the price of the symbol with address in USDT
   * @param symbol
   */
  static async getPriceUSDTWithAddress(
    symbol: string,
    address: string,
    decimals?: number,
    chainId?: number,
  ) {
    try {
      let addrs;
      if (symbol.toUpperCase() === 'MATIC') {
        addrs = '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0';
      } else if (symbol.toUpperCase() === 'BNB') {
        addrs = '0x242a1ff6ee06f2131b7924cacb74c7f9e3a5edc9';
      } else if (symbol.toUpperCase() === 'ARC') {
        addrs = '0xC82E3dB60A52CF7529253b4eC688f631aad9e7c2';
      } else {
        addrs = address;
      }

      if (
        addrs.toLowerCase() === '0x47082a75bc16313ef92cfaca1feb885659c3c9b5'
      ) {
        const result = await this.api.get(
          `ws/v2/tokenPrice/ARC/${addrs.toLowerCase()}?decimals=${
            decimals ?? 18
          }&chainId=${chainId ?? 1}`,
        );
        // console.log(result.data)
        return result.data.price;
      } else {
        const result = await this.api.get(
          `ws/v2/tokenPrice/${symbol.toUpperCase()}/${addrs.toLowerCase()}?decimals=${
            decimals ?? 18
          }&chainId=${chainId ?? 1}`,
        );
        return result.data.price;
      }
    } catch (error) {
      const err = error as AxiosError;
      console.log(error);
      throw new Error(err.response?.statusText);
    }
  }

  /**
   * Gets the price of the symbol with address in USDT
   * @param symbol
   */
  static async getPriceUSDTWithAddressOtherChain(
    symbol: string,
    address: string,
  ) {
    try {
      let addrs;
      if (symbol.toUpperCase() === 'MATIC') {
        addrs = '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0';
      } else if (symbol.toUpperCase() === 'BNB') {
        addrs = '0x242a1ff6ee06f2131b7924cacb74c7f9e3a5edc9';
      } else if (symbol.toUpperCase() === 'ARC') {
        addrs = '0xC82E3dB60A52CF7529253b4eC688f631aad9e7c2';
      } else {
        addrs = address;
      }
      const result = await this.api.get(
        `ws/v2/tokenPrice/${symbol.toUpperCase()}/${addrs.toLowerCase()}/chain`,
      );
      // console.log('-->>>>>>>> restult price OtherChain', result);
      return result.data.price;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  /**
   * Gets the price bulk of the symbol with address in USDT
   * @param symbol
   */
  static async getPriceUSDTWithBulkAddress(listAddresses: Array<any>) {
    try {
      const result = await this.api.post(`ws/v2/tokenPrice/tokens`, {
        addresses: listAddresses,
      });
      return result.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  /**
   * Fetch an array of prices in USDT
   * @param array Array of symbols
   */
  static async getPricesUSDT(quotes: string[], addresses: string[]) {
    try {
      const result = await this.api.post(`ws/v2/tokenPrice`, {
        quotes,
        addresses,
      });
      return result.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  /**
   * Gets the price of the trade in the exchanges
   * @param marketType
   * @param symbolToCompare
   * @param type
   * @param userPriceUnit
   * @param userSize
   */
  static async getExchangeTradeCompare(
    marketType: string,
    symbolToCompare: string,
    type: string, // limit or market
    userPriceUnit: string,
    userSize: string,
  ) {
    try {
      const result = await this.api.post('/ws/v2/marketDetails/compare', {
        marketType,
        symbol: symbolToCompare,
        type,
        userPriceUnit,
        userSize,
      });
      return result.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }
  /**
   * Checks in the server if the authorization token is valid
   * and set default authorization headers.
   *
   * @param jwt the jwt token
   * @returns
   */
  static async verifyAuthorization(jwt?: string): Promise<boolean> {
    let authorization: string | undefined;
    if (jwt) {
      authorization = jwt;
    } else {
      const hasStoredJwt = localStorage.getItem('@app:jwt');
      if (hasStoredJwt) {
        authorization = hasStoredJwt;
      }
    }
    if (authorization) {
      try {
        // await this.api.get(`ws/v2/user/auth`, {
        //   headers: {
        //     authorization: `Bearer ${authorization}`,
        //   },
        // });

        this.api.defaults.headers.authorization = `Bearer ${authorization}`;
        return true;
      } catch (error) {
        this.logout();
      }
    }
    return false;
  }

  static async getLiquidityPools(
    chainId: number,
    protocol: keyof SupportedAPIPools,
  ): Promise<SubgraphPools> {
    try {
      const result = await this.api(`/ws/v2/pool/${chainId}/${protocol}`);
      return result?.data?.pools as SubgraphPools;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(
        err.response?.statusText ??
          'Something bad happened during this request.',
      );
    }
  }

  static async fetchGateioCandlesticks(currencyPair: string): Promise<any> {
    try {
      const result = await this.api(
        `/ws/v2/mktOverview/candlesticks/gateio/${currencyPair}`,
      );
      return result.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(
        err.response?.statusText ??
          'Something bad happened during this request.',
      );
    }
  }

  static logout() {
    localStorage.removeItem('walletconnect');
    localStorage.removeItem('WALLETCONNECT_DEEPLINK_CHOICE');
    localStorage.removeItem('wc@2:client:0.3//proposal');
    localStorage.removeItem('wc@2:universal_provider:/namespaces');
    localStorage.removeItem('wc@2:core:0.3//subscription');
    localStorage.removeItem('wc@2:core:0.3//keychain');
    localStorage.removeItem('wwc@2:core:0.3//messages');
    localStorage.removeItem('wc@2:core:0.3//pairing');

    localStorage.removeItem('wc@2:core:0.3//history');
    localStorage.removeItem('wc@2:client:0.3//session');
    localStorage.removeItem('wc@2:core:0.3//expirer');
    localStorage.removeItem('wc@2:universal_provider:/optionalNamespaces');
    localStorage.removeItem('@app:type');
    localStorage.removeItem('@app:jwt');
    localStorage.removeItem('@app:user');
  }

  static async wertConvertUSDToETH(amount: number) {
    try {
      const { data } = await Axios.post(
        'https://widget.wert.io/api/v3/partners/convert',
        { from: 'ETH', to: 'USD', amount },
        { headers: { 'X-Partner-ID': '01FR5T0F10N01MTZXQJVKAZEXR' } },
      );
      if (data.status === 'ok') {
        return data.body;
      }
      return null;
    } catch (err) {}
    return null;
  }

  static async signWertData(params: any) {
    const { data } = await this.api.post('ws/v2/sign', params);
    return data;
  }

  /**
   * Fetch an array of prices in USDT
   * @param array Array of symbols
   */
  static async getCMC(symbol: string, token_address: string) {
    try {
      const result = await this.api.get(
        `ws/v2/fng/cmcrank/${symbol.toUpperCase()}/${token_address.toLowerCase()}`,
      );
      return result.data;
    } catch (error) {
      const err = error as AxiosError;
    }
  }

  /**
   *
   * @returns
   */
  static async getFNG() {
    try {
      const result = await this.api.get(`ws/v2/fng`);
      if (result && result.data.length > 0) {
        return result.data[0];
      }
      return result.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }
  static async getHoudiniToken() {
    try {
      const result = await this.api.get(`/ws/v2/swap/houdini/token`);
      return result.data.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }
  static async getHoudiniQuote(
    amount: number,
    from: any,
    to: any,
    anonymous: boolean = false,
  ) {
    try {
      const result = await this.api.get(
        `ws/v2/swap/houdini/quote?amount=${amount}&from=${from}&to=${to}&anonymous=${anonymous}`,
      );
      return result.data.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  static async postHoudiniExchange(
    amount: number,
    from: string,
    to: string,
    addressTo: string,
    receiverTag?: string,
  ) {
    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      const { hasStoredJwt, wallet } = await this.getAuthToken();

      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const { data } = await this.api.post(
        'ws/v2/swap/houdini/exchange',

        { amount, from, to, addressTo, receiverTag },
      );

      return data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }
  static async tokenInfo(address?: string) {
    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      const { hasStoredJwt, wallet } = await this.getAuthToken();

      // this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const response = await this.api.get(`ws/v2/ext/cmc/tokeninfo/${address}`);

      return response.data.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }
  static async ARCAIInfo(
    tokenName?: string,
    address?: string,
    chainId?: number,
  ) {
    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      const { hasStoredJwt, wallet } = await this.getAuthToken();

      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const response = await this.api.post(
        'ws/v2/ai',

        { tokenName, address, chainId },
      );

      return response.data.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  // Minimum audit
  static async ARCAIMinInfo(address?: string, chainId?: number) {
    try {
      // this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const response = await this.api.post(
        'ws/v2/ai/arc/audit/minimum',

        { address, chainId },
      );

      return response.data.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  static async ARCAIMulti(tokens: any): Promise<AIToken[]> {
    try {
      const { hasStoredJwt } = await this.getAuthToken();

      if (hasStoredJwt) {
        this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
        const response = await this.api.post('ws/v2/ai/tokens', {
          tokens: tokens,
        });

        return response.data.data as AIToken[];
      }
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }
  static async openAIid(id: string) {
    try {
      // /console.log('token id', id);
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      const { hasStoredJwt, wallet } = await this.getAuthToken();
      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;

      const { data } = await this.api.get(`ws/v2/ai/${id}`);
      // console.log(data);
      if (data) {
        return data.data;
      }
      return null;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  static async chatBot(
    type: 'ARC' | 'SEARCH',
    message: any,
    id?: string,
    symbol?: string,
    address?: string,
    chainId?: number,
  ) {
    const endpoint = `ws/v2/ai/chat?type=${type || 'ARC'}`;

    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      // const hasStore = localStorage.getItem('@app:user');
      // const wallet = hasStore
      //   ? JSON.parse(hasStore).settings.defaultWallet
      //   : '';
      const { hasStoredJwt, wallet } = await this.getAuthToken();

      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      // console.log(JSON.parse(hasStore).settings.defaultWallet)
      const payload: any = {
        textPrompt: message,
        wallet: wallet,
        symbol: symbol ?? null,
        address: address ?? null,
        chainId: chainId ?? null,
      };
      if (id !== null && id !== '' && id !== undefined) {
        payload.id = id;
      }
      const response = await this.api.post(endpoint, payload);

      return response.data.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }
  /** chat bot by id  */

  static async chatAudit(auditId: string, usersPrompt: string, id: string) {
    const endpoint = `ws/v2/ai/arc/audit/chat`;

    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      // const hasStore = localStorage.getItem('@app:user');
      // const wallet = hasStore
      //   ? JSON.parse(hasStore).settings.defaultWallet
      //   : '';
      const { hasStoredJwt, wallet } = await this.getAuthToken();

      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      // console.log(JSON.parse(hasStore).settings.defaultWallet)
      const response = await this.api.post(endpoint, {
        auditId,
        userPrompt: usersPrompt,
        // id,
      });

      return response.data.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  static async chatBotId(id: string) {
    // https://staging-api.arc.market/ws/v2/ai/chat

    try {
      const hasStoredJwt = localStorage.getItem('@app:jwt');
      const hasStore = localStorage.getItem('@app:user');
      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      // console.log(JSON.parse(hasStore).settings.defaultWallet)
      const response = await this.api.get(`ws/v2/ai/chat/${id}`);

      return response.data.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  /** chat bot download  */

  static async chatBotIdDownload(id: string) {
    // https://staging-api.arc.market/ws/v2/ai/chat

    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      // const hasStore = localStorage.getItem('@app:user');
      const { hasStoredJwt, wallet } = await this.getAuthToken();

      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      // console.log(JSON.parse(hasStore).settings.defaultWallet)
      const response = await this.api.get(`ws/v2/ai/chat/${id}/download`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  /**
   * @params chainID
   */

  static async getTokenList(chainId: any): Promise<IToken | []> {
    try {
      const result = await this.api.get(`ws/v2/tokenPrice/tokens/${chainId}`);
      // console.log('result', result);
      const rst = result.data;
      return rst.success ? rst.data : [];
    } catch (error) {
      return [];
    }
  }

  static async UpdateDiscord(discord: any): Promise<any> {
    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      // const hasStore = localStorage.getItem('@app:user');
      // const wallet = hasStore
      //   ? JSON.parse(hasStore).settings.defaultWallet
      //   : '';

      const { hasStoredJwt, wallet } = await this.getAuthToken();

      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const response = await this.api.post(`ws/v2/user/AI/discord`, {
        wallet: wallet,
        discord: discord,
      });
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  static async UpdateDiscordStatus(status: boolean): Promise<any> {
    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      // const hasStore = localStorage.getItem('@app:user');
      // const wallet = hasStore
      //   ? JSON.parse(hasStore).settings.defaultWallet
      //   : '';

      const { hasStoredJwt, wallet } = await this.getAuthToken();

      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const response = await this.api.post(`ws/v2/user/AI/discord/status`, {
        status: !status ? 'mute' : 'unmute',
      });
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  static async UpdateTelegram(telegram: any): Promise<any> {
    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      // const hasStore = localStorage.getItem('@app:user');
      // const wallet = hasStore
      //   ? JSON.parse(hasStore).settings.defaultWallet
      //   : '';
      const { hasStoredJwt, wallet } = await this.getAuthToken();

      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const response = await this.api.post(`ws/v2/user/AI/telegram`, {
        wallet: wallet,
        telegram: telegram,
      });
      return response;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }
  static async UpdateTelegramStatus(status: boolean): Promise<any> {
    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      // const hasStore = localStorage.getItem('@app:user');
      // const wallet = hasStore
      //   ? JSON.parse(hasStore).settings.defaultWallet
      //   : '';
      const { hasStoredJwt, wallet } = await this.getAuthToken();

      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const response = await this.api.post(`ws/v2/user/AI/telegram/status`, {
        status: !status ? 'mute' : 'unmute',
      });
      return response;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  static async UpdateFirebase(): Promise<any> {
    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      // const hasStore = localStorage.getItem('@app:user');
      // const wallet = hasStore
      //   ? JSON.parse(hasStore).settings.defaultWallet
      //   : '';
      const { hasStoredJwt, wallet } = await this.getAuthToken();

      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const response = await this.api.post(`ws/v2/user/AI/firebase`, {
        wallet: wallet,
        firebase: { status: 'connected' },
      });

      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  static async RemoveFirebase(): Promise<any> {
    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      // const hasStore = localStorage.getItem('@app:user');
      // const wallet = hasStore
      //   ? JSON.parse(hasStore).settings.defaultWallet
      //   : '';
      const { hasStoredJwt, wallet } = await this.getAuthToken();

      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const response = await this.api.post(`ws/v2/user/AI/firebase/remove`, {});

      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  static async UpdateGem(): Promise<any> {
    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      // const hasStore = localStorage.getItem('@app:user');
      // const wallet = hasStore
      //   ? JSON.parse(hasStore).settings.defaultWallet
      //   : '';
      const { hasStoredJwt, wallet } = await this.getAuthToken();

      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const response = await this.api.post(`ws/v2/user/AI/gem/enable`, {
        wallet: wallet,
      });

      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  static async RemoveGem(): Promise<any> {
    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      // const hasStore = localStorage.getItem('@app:user');
      // const wallet = hasStore
      //   ? JSON.parse(hasStore).settings.defaultWallet
      //   : '';
      const { hasStoredJwt, wallet } = await this.getAuthToken();

      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const response = await this.api.post(`ws/v2/user/AI/gem/remove`, {});

      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  static async UpdateCustomToken(): Promise<any> {
    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      // const hasStore = localStorage.getItem('@app:user');
      // const wallet = hasStore
      //   ? JSON.parse(hasStore).settings.defaultWallet
      //   : '';
      const { hasStoredJwt, wallet } = await this.getAuthToken();

      const hasImportedTokens = localStorage.getItem('@app:imported-tokens');
      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const response = await this.api.post(`ws/v2/user/AI/custom-token`, {
        customToken: hasImportedTokens,
      });

      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  static async GetOHLCData(
    address: string,
    interval: string,
    convert: string,
  ): Promise<any> {
    try {
      const { hasStoredJwt, wallet } = await this.getAuthToken();
      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;

      let queryString: string = `interval=${interval}&convert=${convert}`;
      if (interval.toLowerCase().includes('h')) {
        queryString = queryString + '&time_period=hourly';
      }
      const result = await this.api.get(
        `ws/v2/exchange/quotes-ohlcv/${address}?${queryString}`,
      ); //&time_start=2023-12-17
      const rst = result.data;

      return rst.success ? rst.data : null;
    } catch (error) {
      console.log('error', error);
      // return [];
    }
  }

  static async GetHistoricalData(
    address: string,
    interval: string,
    convert: string,
  ): Promise<any> {
    try {
      const { hasStoredJwt, wallet } = await this.getAuthToken();
      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;

      const result = await this.api.get(
        `ws/v2/exchange/quotes-historical/${address}?interval=${interval}&convert=${convert}${
          interval === 'monthly' ? '&count=10' : ''
        }`,
      ); //&time_start=2023-12-17
      const rst = result.data;

      return rst.success ? rst.data : null;
    } catch (error) {
      console.log('error', error);
      // return [];
    }
  }

  static async UpdateTokenNotificationMute(
    tokenAddress: string,
    status: boolean,
  ): Promise<any> {
    try {
      const { hasStoredJwt, wallet } = await this.getAuthToken();
      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;

      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const response = await this.api.post(`ws/v2/user/AI/notification/token`, {
        tokenAddress,
        status: status ? 'mute' : 'unmute',
      });
      return response.data;
    } catch (error) {
      console.log('error', error);
      // return [];
    }
  }

  // static async UpdateWalletBalance(wallet:string, hasBalance:any , chaindId:number):Promise<any>{
  //   try {
  //       const {hasStoredJwt, wallet} = await this.getAuthToken();
  //       const tokenArray = hasBalance.map(tokenObj => tokenObj.address);

  //     this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
  //     const response = await this.api.post(`ws/v2/user/AI/token-balances`,{
  //       chainId:chaindId,
  //       tokens:tokenArray
  //     });
  //     return response.data;
  //   } catch (error) {
  //     const err = error as AxiosError;
  //     throw new Error(err.response?.statusText);
  //   }
  // }

  static async ShieldToken(chainId: any): Promise<any> {
    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      // const hasStore = localStorage.getItem('@app:user');
      // const wallet = hasStore
      //   ? JSON.parse(hasStore).settings.defaultWallet
      //   : '';
      const { hasStoredJwt, wallet } = await this.getAuthToken();
      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const response = await this.api.post(`ws/v2/user/AI/shield`, {
        chainId: chainId ?? 1,
      });
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  static async GetCustomToken(chainId: number): Promise<any> {
    try {
      const { hasStoredJwt, wallet } = await this.getAuthToken();
      if (hasStoredJwt !== null) {
        this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;

        const result = await this.api.get(`ws/v2/user/AI/user-info/${chainId}`);
        const rst = result.data;
        // console.log('result---user', rst);
        if (rst.success && rst.data && rst.data.assets) {
          localStorage.setItem(
            '@app:tokens',
            JSON.stringify(rst.data.assets),
          );

        }
        if (rst.success && rst.data && rst.data.customToken) {
          localStorage.setItem(
            '@app:imported-tokens',
            JSON.stringify(rst.data.customTokens),
          );
        }

        if (rst.success && rst.data && rst.data.discord) {
          localStorage.setItem(
            '@app:d',
            JSON.stringify(rst.data.discord.token),
          );
        }
        if (rst.success && rst.data && rst.data.telegram) {
          localStorage.setItem('@app:t', JSON.stringify(rst.data.telegram));
        }
        return rst.success ? rst.data : [];
      } else {
        return [];
      }
    } catch (error) {
      console.log('error', error);
      // return [];
    }
  }

  private static async getAuthToken() {
    const hasStoredJwt = localStorage.getItem('@app:jwt');
    const hasStore = localStorage.getItem('@app:user');
    const wallet = hasStore ? JSON.parse(hasStore).settings.defaultWallet : '';

    return {
      hasStoredJwt,
      wallet,
    };
  }

  static async UpdateStripe(sessionId: any): Promise<any> {
    try {
      // const hasStoredJwt = localStorage.getItem('@app:jwt');
      // const hasStore = localStorage.getItem('@app:user');
      // const wallet = hasStore
      //   ? JSON.parse(hasStore).settings.defaultWallet
      //   : '';
      const { hasStoredJwt, wallet } = await this.getAuthToken();

      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const response = await this.api.post(`ws/v2/user/AI/subscription`, {
        wallet: wallet,
        stripeData: sessionId,
      });
      return response;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  static async saveTransaction(
    fromToken: string,
    toToken: string,
    swapDate: number,
    walletAddress: string,
    transaction: string,
    scanLink: string,
  ): Promise<any> {
    try {
      const { hasStoredJwt, wallet } = await this.getAuthToken();
      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const response = await this.api.post(`ws/v2/swap/transaction`, {
        fromToken: fromToken,
        toToken: toToken,
        swapDate: swapDate,
        walletAddress: walletAddress,
        transaction: transaction,
        scanLink: scanLink,
      });
      return response?.data?.success;
    } catch (error) {
      const err = error as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  static async getTransactionHistory(walletAddress: string): Promise<any> {
    try {
      const { hasStoredJwt, wallet } = await this.getAuthToken();
      this.api.defaults.headers.authorization = `Bearer ${hasStoredJwt}`;
      const result = await this.api.get(
        `ws/v2/swap/transaction/${walletAddress}`,
      );
      const rst = result.data;
      return rst.success ? rst.data : [];
    } catch (error) {
      console.log('error', error);
      return [];
    }
  }

  /**
   * Get a quotation from 1Inch Exchange using the chosen params.
   *
   * @see [Quote/Swap](https://portal.1inch.dev/documentation/swap/swagger?method=get&path=%2Fv5.2%2F1%2Fquote)
   * @param opts {I1InchQuoteParams} params for quotation
   * @returns
   */
  static async getQuote(
    opts: I1InchQuoteParams,
    chainId: number,
    retryCount: number = 10,
  ): Promise<I1InchQuoteResponse> {
    try {
      let url = `/ws/v2/swap/${chainId}/quote?src=${opts.fromToken.address}`;
      url += `&dst=${opts.toToken.address}`;
      url += `&amount=${opts.amount}`;
      url += `&includeGas=true`;
      const { data } = await this.api.get(url);
      return data;
    } catch (error: any) {
      const err = error as AxiosError;
      if (
        err.message === 'Network Error' ||
        (error.response && error.response.status === 429)
      ) {
        const delay = this.RETRY_DELAY_MS;
        await new Promise((resolve) => setTimeout(resolve, delay));
        if (retryCount < this.MAX_RETRY_COUNT) {
          return this.getQuote(opts, chainId, retryCount + 1);
        } else {
          throw new Error('Maximum number of retry attempts reached.');
        }
      }
    }
  }

  static async getAllowance(
    params: I1InchAllowanceRequestParams,
    chainId: number,
  ): Promise<I1InchAllowanceResponse> {
    try {
      let url = `/ws/v2/swap/${chainId}/approve/allowance`;
      url += `?tokenAddress=${params.tokenContractAddress}&walletAddress=${params.userWalletAddress}`;
      const { data } = await this.api.get(url);
      return data;
    } catch (e) {
      const err = e as AxiosError;
      throw new Error(err.response?.statusText);
    }
  }

  static isNative(addr: string) {
    return addr === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
  }
  /**
   * Get call data for approving and spending wallet's assets in order to perform
   * swap.
   *
   * @param {I1InchApprovalParams} opts approval parameters
   */
  static async getApproveCalldata(
    opts: I1InchApprovalParams,
    chainId: number,
  ): Promise<I1InchApprovalReponse> {
    if (!this.isNative(opts.token.address)) {
      try {
        let params = `?tokenAddress=${opts.token.address}`;
        if (opts.amount) {
          params += `&amount=${opts.amount}`;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const url = `/ws/v2/swap/${chainId}/approve/transaction` + params;
        const { data } = await this.api.get(url);
        if (data.success == false) {
          throw new Error(`Getting ApproveCallData got error:${data.status}`);
        }
        return data.data;
      } catch (error) {
        const err = error as AxiosError;
        throw new Error(err.response?.statusText);
      }
    } else {
      throw new Error('No approval needed to native token.');
    }
  }

  static async getSwap(
    params: I1InchSwapParams,
    retryCount: number = 0,
    chainId,
  ): Promise<I1InchSwapResponse> {
    try {
      params.fee = 0;
      let protocols: string[][] = [];
      if (params.protocols && params.protocols.length) {
        protocols = params.protocols.map((protocol) =>
          protocol.map((route) => route.name),
        );
      }

      let url = `/ws/v2/swap/${chainId}/swap?src=${params.fromTokenAddress}`;
      url += `&dst=${params.toTokenAddress}`;
      url += `&amount=${BigInt(params.amount)}`;
      url += `&from=${params.fromAddress}`;
      url += `&slippage=${params.slippage}`;
      url += `&includeGas=${!!params.includeGas}`;
      if (params.fee && params.fee > 0) {
        url += `&fee=${params.fee}`;
        url += `&referrer=${env.REFFERERADDRESS}`;
      }
      protocols.length ? (url += `&protocols=${protocols.join(',')}`) : null;

      const { data } = await this.api.get(url);
      return data;
    } catch (error) {
      console.log(error);
      const err = error as AxiosError;
      throw new Error(
        err.response?.statusText ??
          "Sorry, we've got an unknown error, please try again.",
      );
    }
  }

  static async getIpLocation(
    setIsAllowed: React.Dispatch<React.SetStateAction<boolean>>,
  ): Promise<any> {
    const bannedCountryCodeList = ['RU'];
    Axios.get(`https://api.ipify.org?format=json`).then(({ data }) => {
      const { ip } = data;
      Axios.get(`https://api.iplocation.net/?ip=${ip}`).then(({ data }) => {
        const { country_code2 } = data;

        if (bannedCountryCodeList.includes(country_code2)) {
          console.log('CountryCode', country_code2);
          setIsAllowed(false);
        }
      });
    });
  }
}
