/* eslint-disable */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, Typography, Input, Image, Row, Col, Checkbox } from 'antd';
import { ThemeContext } from '../../contexts/ThemeContext';
import ARCModal from '../ARCModal/ARCModal';
import './style.scss';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import closeImg from '../../assets/close.svg';
import closeImgBlack from '../../assets/close_black.svg';

const WalletDisclaimerModal: React.FC<{
  onSelect: Function;
  visible: boolean;
  onClose: () => void;
  mobile?: boolean;
}> = ({ onSelect, visible, onClose, mobile }) => {
  const { theme } = useContext(ThemeContext);
  const [modalIndex, setModalIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isDisable, setisDisable] = useState(true);
  const listInnerRef = useRef<HTMLDivElement>(null);

  const onChange = (e: CheckboxChangeEvent) => {
    setisDisable(!e.target.checked);
  };
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop + clientHeight + 3 > scrollHeight) {
        setisDisable(false);
      }
    }
  };
  const onCloseHandler = () => {
    localStorage.setItem('@app:disclaimer', 'read');
    setModalIndex(0);
    onClose();
  };

  const PrivatePolicyText = () => {
    return (
      <p style={{ fontSize: 17, margin: 0 }}>
        To use ARC farms, please connect your wallet. This is a separate
        framework, so you need to reconnect your wallet to access the features
        on this page.
      </p>
    );
  };

  return (
    <>
      <ARCModal open={visible} onClose={onClose}>
        <div
          className={`depo__fulfill-modal  ${
            theme === 'light' ? 'bg-white' : 'bg-dark'
          } rounded-modal`}
        >
          <Button
            type="link"
            className="btn-close mt-3 mr-4"
            onClick={onCloseHandler}
          >
            <img
              src={theme === 'light' ? closeImgBlack : closeImg}
              alt="down-arrow"
            />
          </Button>
          <div className="depo__token-select-tab">
            <div
              className={
                theme === 'light'
                  ? 'depo__token-select-mainpanel-light'
                  : 'depo__token-select-mainpanel-dark'
              }
            >
              <div className="p-4" style={{ height: '332px' }}>
                <Typography
                  className="title"
                  style={{
                    color: theme === 'light' ? '#007aff' : '#007aff',
                    textAlign: 'center',
                  }}
                >
                  Disclaimer
                </Typography>

                {/* disclaimer text */}
                <div className="depo__token-list" style={{ height: '130px' }}>
                  {PrivatePolicyText()}
                </div>

                <div
                  className={`depo__token-to-mng-list btn-mobile disabled-false`}
                  style={{
                    marginTop: '20px',
                  }}
                  onClick={onCloseHandler}
                >
                  <Button
                    className={`text-white toMngList`}
                    type="link"
                    onClick={onCloseHandler}
                  >
                    Dismiss
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ARCModal>
    </>
  );
};

export default WalletDisclaimerModal;
