/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Grid,
  Input,
  Menu,
  Radio,
  Space,
  Switch,
  Row,
  Col,
} from 'antd';
import BigNumber from 'bignumber.js';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { BiGridHorizontal } from 'react-icons/bi';
import { MdList } from 'react-icons/md';

import { ARCAPIService } from 'services/ARCAPIService';
import { ThemeContext } from 'contexts/ThemeContext';
import Notification from 'components/Notification/Notification';
import DisclaimerModal from 'components/disclaimermodal';
import WalletDisclaimerModal from 'components/WalletDisclaimer';
// import { isMobile } from 'react-device-detect';
import type { MenuProps } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { AuthContext } from '../../contexts/AuthProvider';
import useRefresh from '../../hooks/useRefresh';
import {
  fetchFarmsPublicDataAsync,
  fetchFarmUserDataAsync,
  fetchJungleFarmsPublicDataAsync,
  fetchJungleFarmUserDataAsync,
} from '../../state/actions';
import {
  useFarms,
  useFarmsLoading,
  useJungleFarms,
  useJungleFarmsLoading,
} from '../../state/hook';
import { useAppDispatch } from '../../state';
import ListItem from './Component/listItem';
import PairItem from './Component/pairItem';
import Loading from '../../components/Loading/Loading';
import './style.scss';
import '../Swap/style.scss';
import gridview from '../../assets/grid_view_active.svg';
import gridviewInactive from '../../assets/grid_view_inactive.svg';
import gridviewInactiveLight from '../../assets/grid_view_inactive_light.svg';
import listview from '../../assets/list_view_active.svg';
import listviewInactive from '../../assets/list_view_inactive.svg';
import listviewInactiveLight from '../../assets/list_view_inactive_light.svg';
import downArrow from '../../assets/arrow_down.svg';
import downArrowLight from '../../assets/arrow_down_light.svg';

const options = [
  { label: 'Active', value: 1 },
  { label: 'Coming soon', value: 2 },
  { label: 'Inactive', value: 3 },
];

const Farm: React.FC = () => {
  const [tabStatus, setTabStatus] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [isStaked, setStaked] = useState(false);
  const [poolInfo, setPoolInfo] = useState<any>([]);
  const [chainIdx, setChainIdx] = useState(1);
  const [arcPrice, setARCPrice] = useState(0);
  const [selectModal, setSelectModal] = useState(false);
  const dispatch = useAppDispatch();
  const breakpoints = Grid.useBreakpoint();
  const [isView, setView] = useState(0);
  const [selectedOption, setSelectedOption] = useState('Active');
  const {
    user,
    web3Provider,
    chainId,
    ARCToken,
    provider,
    setdisableFooter,
    isAuthenticated,
  } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const farmsLoading = useFarmsLoading();
  const data = useFarms();
  const jungleFarmsLoading = useJungleFarmsLoading();
  const jungleData = useJungleFarms();
  const mobile = useMediaQuery({ maxWidth: 800 });
  const isMobilexs = useMediaQuery({ maxWidth: 400 });
  const filterByText = (filterData: any) =>
    filterData.filter(
      (item: any) =>
        item.lpSymbol.includes(searchText.toUpperCase() as string) > 0,
    );

  const filteryByTab = (filter: any) => {
    if (tabStatus === 1) {
      return filter.filter(
        (item: any) => item.active === true && item.type !== 'DEPO',
      );
    }
    if (tabStatus === 3) {
      return filter.filter(
        (item: any) => item.active === false && item.type !== 'DEPO',
      );
    }
    return [];
  };

  const filterByStake = (filter: any) => {
    if (isStaked === true) {
      return filter.filter(
        (item: any) =>
          new BigNumber(item.userData ? item.userData.stakedBalance : 0) >
          new BigNumber(0),
      );
    }
    return filter;
  };

  const { slowRefresh } = useRefresh();

  useEffect(() => {
    if (!user?.settings?.defaultWallet) return;
    if (web3Provider && chainId === 1 && user?.settings?.defaultWallet) {
      setChainIdx(chainId);
      dispatch(fetchFarmsPublicDataAsync({ web3Provider, chainId })).then(
        (r) => {
          const account =
            user?.settings?.defaultWallet || provider?.accounts?.[0];

          if (account) {
            dispatch(
              fetchFarmUserDataAsync({
                web3Provider,
                chainId,
                account,
              }),
            );
          }
        },
      );
      dispatch(fetchJungleFarmsPublicDataAsync({ web3Provider, chainId })).then(
        (r) => {
          const account =
            user?.settings?.defaultWallet || provider?.accounts?.[0];

          if (account) {
            dispatch(
              fetchJungleFarmUserDataAsync({
                web3Provider,
                chainId,
                account,
              }),
            );
          }
        },
      );
    } else {
      if (chainId !== 1) {
        Notification({
          type: 'warning',
          message: `Please switch network to ETHEREUM Network`,
        });
      }
    }
  }, [web3Provider, chainId, user]);

  useEffect(() => {
    let filteredData = data.concat(jungleData);
    filteredData = filteryByTab(filteredData);
    filteredData = filterByText(filteredData);
    filteredData = filterByStake(filteredData);
    setPoolInfo(filteredData);
  }, [data, jungleData, isStaked, tabStatus, searchText]);
  const [selectPrivateModal, setselectPrivateModal] = useState(true);
  useEffect(() => {
    if (farmsLoading.public || jungleFarmsLoading.public) {
      Notification({
        type: 'warning',
        title: 'Connect your wallet',
        message: 'To view and use ARC farms, please log in with your wallet.',
      });
    }
    try {
      (async () => {
        const arcPrice = await ARCAPIService.getPriceUSDTWithAddress(
          ARCToken.symbol,
          ARCToken.address.toLowerCase(),
          ARCToken.decimals,
          chainId,
        );

        console.log('arc price', arcPrice);
        setARCPrice(arcPrice);
      })();
      setdisableFooter(false);

      const isDisclaimer = localStorage.getItem('@app:disclaimer');
      if (isDisclaimer !== 'read') {
        setSelectModal(true);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const onChange = (e: any) => setTabStatus(e.target.value);

  const onSearchTextChange = (e: any) => setSearchText(e.target.value);

  const onChangeView = (viewIndex: number) => () => setView(viewIndex);

  const onStacked = (checked: boolean) => setStaked(checked);

  const handleMenuClick = (e: any) => setTabStatus(parseInt(e.key, 10));

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Active</Menu.Item>
      <Menu.Item key="2">Coming Soon</Menu.Item>
      <Menu.Item key="3">Inactive</Menu.Item>
    </Menu>
  );

  const items: MenuProps['items'] = [
    {
      label: 'Active',
      key: '1',
    },
    {
      label: 'Inactive',
      key: '2',
    },
  ];

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === '1') {
      setSelectedOption('Active');
      setStaked(false);
    } else if (key === '2') {
      setSelectedOption('Inactive');
      setStaked(true);
    }
    // message.info(`Click on item ${key}`);
  };

  const defaultWallet = useMemo(() => user?.settings?.defaultWallet, [user]);

  const loading = !!(
    (farmsLoading.public || jungleFarmsLoading.public) &&
    defaultWallet
  );

  return (
    <>
      <div id="depo__farm">
        <div
          className="farm__PanelContainer"
          style={{
            paddingLeft: mobile ? '10px' : 0,
          }}
        >
          <div>
            <h3 className="page-title" style={{ fontSize: '32px' }}>
              Farm
            </h3>
            <div className="farm__PanelText2">Stake tokens to earn rewards</div>
          </div>
        </div>
        {loading && (
          <div style={{ margin: '72px -12px 24px', flex: '1 1 100%' }}>
            <Loading theme={theme} />
          </div>
        )}
        {loading ? null : (
          <>
            <div
              className="farm__toolbarContainer"
              style={{
                paddingLeft: mobile ? '10px' : 0,
              }}
            >
              <div className="farm__filterContainer">
                {!isMobilexs && (
                  <Input
                    className="farm__searchInput"
                    placeholder="Search Farms"
                    prefix={<SearchOutlined className="textColor" />}
                    onChange={onSearchTextChange}
                  />
                )}
                {isMobilexs && (
                  <SearchOutlined
                    className="textColor"
                    style={{
                      backgroundColor: theme === 'light' ? 'white' : '#0b1e3c',
                      borderRadius: '100px',
                      padding: '13px',
                    }}
                  />
                )}
                {/* Dropdown for active and inactive ... */}
                {/* <Dropdown overlay={menu}>
                  <Button className="farm__dropDown">
                    {
                      options.find((option) => option.value === tabStatus)
                        ?.label
                    }
                    <DownOutlined />
                  </Button>
                </Dropdown> */}
              </div>
              <div className="farm__viewContainer">
                {/* <Switch style={{ marginRight: 8 }} onChange={onStacked} />
                <div style={{ marginRight: 16 }} className="farm__staked">
                  Staked only
                </div> */}
                {!mobile && (
                  <>
                    <Dropdown
                      menu={{ items, onClick }}
                      overlayClassName="chart-dropdown"
                      overlayStyle={{
                        color: theme === 'light' ? '#000' : '#fff',
                        backgroundColor:
                          theme === 'light' ? 'white' : '#0B1E3C',
                        border: 'none',
                      }}
                    >
                      <span
                        style={{
                          color: theme === 'light' ? '#000' : '#fff',
                          backgroundColor:
                            theme === 'light' ? 'white' : '#0B1E3C',
                          padding: '10px 18px',
                          borderRadius: '100px',
                          marginRight: '5px',
                          border:
                            theme === 'light' ? '1px solid #8d8d8d' : 'none',
                        }}
                      >
                        <Space>
                          {/* {selectedTokens.from.symbol}/{selectedTokens.to?.symbol} */}
                          <span style={{ minWidth: '55px' }}>
                            {selectedOption}
                          </span>
                          <img
                            src={theme === 'dark' ? downArrow : downArrowLight}
                            alt="downArrow"
                            style={{ marginLeft: '10px' }}
                          />
                          {/* <DownOutlined /> */}
                        </Space>
                      </span>
                    </Dropdown>
                    <img
                      // eslint-disable-next-line prettier/prettier, no-nested-ternary
                      src={
                        isView === 0
                          ? gridview
                          : theme === 'dark'
                          ? gridviewInactive
                          : gridviewInactiveLight
                      }
                      className="farm__viewMode"
                      alt="gridview"
                      style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                      onClick={onChangeView(0)}
                    />
                    <img
                      // eslint-disable-next-line prettier/prettier, no-nested-ternary
                      src={
                        isView === 1
                          ? listview
                          : theme === 'dark'
                          ? listviewInactive
                          : listviewInactiveLight
                      }
                      className="farm__viewMode"
                      alt="listview"
                      style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                      onClick={onChangeView(1)}
                    />
                  </>
                )}
                {mobile && (
                  <>
                    <img
                      // eslint-disable-next-line prettier/prettier, no-nested-ternary
                      src={
                        isView === 0
                          ? gridview
                          : theme === 'dark'
                          ? gridviewInactive
                          : gridviewInactiveLight
                      }
                      className="farm__viewMode"
                      alt="gridview"
                      style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                      onClick={onChangeView(0)}
                    />
                    <img
                      // eslint-disable-next-line prettier/prettier, no-nested-ternary
                      src={
                        isView === 1
                          ? listview
                          : theme === 'dark'
                          ? listviewInactive
                          : listviewInactiveLight
                      }
                      className="farm__viewMode"
                      alt="listview"
                      style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                      onClick={onChangeView(1)}
                    />
                    <Dropdown
                      menu={{ items, onClick }}
                      overlayClassName="chart-dropdown"
                      overlayStyle={{
                        color: theme === 'light' ? '#000' : '#fff',
                        backgroundColor:
                          theme === 'light' ? 'white' : '#0B1E3C',
                        border: 'none',
                      }}
                    >
                      <span
                        style={{
                          color: theme === 'light' ? '#000' : '#fff',
                          backgroundColor:
                            theme === 'light' ? 'white' : '#0B1E3C',
                          padding: '10px 18px',
                          borderRadius: '100px',
                          marginRight: '5px',
                          border:
                            theme === 'light' ? '1px solid #8d8d8d' : 'none',
                        }}
                      >
                        <Space>
                          {/* {selectedTokens.from.symbol}/{selectedTokens.to?.symbol} */}
                          <span style={{ minWidth: '55px' }}>
                            {selectedOption}
                          </span>
                          <img
                            src={theme === 'dark' ? downArrow : downArrowLight}
                            alt="downArrow"
                            style={{ marginLeft: '10px' }}
                          />
                          {/* <DownOutlined /> */}
                        </Space>
                      </span>
                    </Dropdown>
                  </>
                )}
              </div>
            </div>
            {isView === 0 ? (
              <Row
                className="p-0"
                style={{
                  margin: mobile ? '0 0' : '0 -12px',
                  flex: '1',
                  flexBasis: '100%',
                }}
              >
                {poolInfo.map((item: any) => (
                  <Col
                    xs={24}
                    sm={12}
                    lg={8}
                    key={`${item.version}-${item.pid}`}
                  >
                    <PairItem
                      farmItem={item}
                      lpAddress={
                        item.pid < 0
                          ? item.lockedAddress[chainIdx]
                          : item.lpAddress[chainIdx]
                      }
                      poolId={item.pid}
                      arcPrice={arcPrice}
                      disabled={!defaultWallet}
                      loading={loading}
                    />
                  </Col>
                ))}
              </Row>
            ) : (
              <div className="farm__listViewContainer">
                {poolInfo.map((item: any, index: number) => (
                  <div key={`${item.version}-${item.pid}`}>
                    <ListItem
                      farmItem={item}
                      lpAddress={
                        item.pid < 0
                          ? item.lockedAddress[chainIdx]
                          : item.lpAddress[chainIdx]
                      }
                      poolId={item.pid}
                      arcPrice={arcPrice}
                      disabled={!defaultWallet}
                      loading={loading}
                    />
                    {index !== poolInfo.length - 1 && (
                      <div className="farm__divider" />
                    )}
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
      <DisclaimerModal
        onSelect={() => {
          setSelectModal(false);
        }}
        visible={selectModal}
        onClose={() => {
          setSelectModal(false);
        }}
        mobile={mobile}
      />
      <WalletDisclaimerModal
        onSelect={() => {
          setselectPrivateModal(false);
        }}
        visible={!isAuthenticated && selectPrivateModal}
        onClose={() => {
          setselectPrivateModal(false);
        }}
        mobile={mobile}
      />
    </>
  );
};

export default Farm;
