/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import env from 'config/global-env';
import { PropsWithChildren } from 'react';
import { WagmiProvider, createConfig, http } from 'wagmi';
import {
  Chain,
  arbitrum,
  avalanche,
  bsc,
  fantom,
  mainnet,
  optimism,
  polygon,
} from 'wagmi/chains';
import { coinbaseWallet, walletConnect } from 'wagmi/connectors';

export const wagmiConfig = createConfig({
  chains: [
    mainnet,
    optimism,
    bsc,
    polygon,
    fantom,
    arbitrum,
    avalanche,
  ] as readonly [Chain, ...Chain[]],
  connectors: [
    coinbaseWallet({
      appName: 'ARCSwaps AI',
    }),
    walletConnect({
      projectId: env.WALLETCONNECTID,
      metadata: {
        name: 'ARCSwaps AI',
        description: '',
        url: window?.location?.origin,
        icons: [],
      },
    }),
    // wagmi automatically injects installed metamask and trust wallet provider.
  ],
  transports: {
    [mainnet.id]: http(),
    [optimism.id]: http(),
    [bsc.id]: http(),
    [polygon.id]: http(),
    [fantom.id]: http(),
    [arbitrum.id]: http(),
    [avalanche.id]: http(),
  },
});

const queryClient = new QueryClient();

export default function ProviderWeb3({ children }: PropsWithChildren) {
  return (
    <WagmiProvider config={wagmiConfig} reconnectOnMount={false}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}
