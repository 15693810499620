import React from 'react';
import LogoImage from '../../assets/icons/ARC_Full.svg';
import VPNLockImage from '../../assets/icons/vpn-lock.svg';
import './style.scss';

const NotAllowedPage: React.FC = () => (
  <div className="error-container">
    <div className="error-wrapper">
      <div>
        <img src={LogoImage} width={150} height={150} alt="logo" />
      </div>
      <div className="vpn-img-wrapper">
        <img
          src={VPNLockImage}
          width={24}
          height={24}
          alt="earth lock"
          className="white"
        />
      </div>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          flexDirection: 'column',
          textAlign: 'center',
          marginTop: '25px',
        }}
      >
        <div className="error-header">{`We're sorry.`}</div>
        <div className="error-sub-header">
          Arc is unavailable in your location.
        </div>
      </div>

      <div className="error-content">
        <div className="error-code">Error code:112</div>
        Arc is required to comply with US sanctions laws that restrict the use
        of our site in certain Jurisdictions. Because of this, our services are
        not available in your country.
      </div>
    </div>
    {/* <div className="effect1" />
    <div className="effect2" /> */}
    <div className="purple-effect" />
    <div className="inner-purple-effect" />
  </div>
);

export default NotAllowedPage;
