/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-types */

import { Button, Dropdown, type MenuProps } from 'antd';
import AskAI from 'components/ask-ai';
import { AuthContext } from 'contexts/AuthProvider';
import { ThemeContext } from 'contexts/ThemeContext';
import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { swapModeOption } from 'utility/constants';
import warningLogo from '../assets/warning.png';
import { CHAINS } from './chains';

const CHAIN_IDS = Object.entries(CHAINS).map(([key, value]) => ({
  id: key,
  ...value,
}));

const NetworkCard: React.FC<{
  activeChain: number;
  onselect: (chain: number) => void;
}> = ({ activeChain, onselect }) => {
  const { theme } = useContext(ThemeContext);
  const {
    swapMode,
    setaskAIInfo,
    isTypeConnect,
    setDefaultCurrency,
    setIsLoading,
  } = useContext(AuthContext);
  const [desiredChainId, setDesiredChainId] = useState(activeChain);
  const [isUnsupportedChain, setIsUnsupportedChain] = useState(false);
  // const mobile = isMobile;
  const mobile = useMediaQuery({ maxWidth: 991 });

  const handleRightClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    typeAsk: string,
  ) => {
    console.log('Right-clicked!', event, typeAsk);
  };
  const handleMenuClick: MenuProps['onClick'] = async (e: unknown) => {
    if (typeof e === 'object' && e !== null && 'key' in e) {
      onselect(Number(e.key));
    }
  };
  const itemsNetwork: MenuProps['items'] = CHAIN_IDS.map((x) => ({
    label: x.name,
    key: x.id,
    disabled:
      (isTypeConnect === 'walletconnect' && +x.id !== 1
        ? true
        : +x.id === activeChain) ||
      (swapMode === swapModeOption.crossChain && +x.id === 250),

    // theme: {theme},
    style: {
      color: 'red',
      // color: theme === 'light' ? '#000 !important' : '#fff !important',
      // backgroundColor: theme === "light" ? "#fff" : "#000",
    },
    icon: (
      <img
        src={x?.logo}
        width={25}
        height={25}
        style={{
          borderRadius: '100%',
        }}
      />
    ),
  }));
  const menuProps = {
    items: itemsNetwork,
    onClick: handleMenuClick,
  };

  useEffect(() => {
    if (activeChain) {
      const xChain = CHAINS[activeChain];

      if (xChain) {
        const cn: any = CHAINS[activeChain];
        setDefaultCurrency(cn.nativeCurrency.symbol);
        if (!mobile) setIsLoading(true);
      } else {
        setIsUnsupportedChain(true);
      }
    }
  }, [activeChain]);

  return (
    <AskAI
      onRightClick={(event) => handleRightClick(event, 'swap')}
      theme={theme}
      content="Chains"
      onHandleClose={(value) => {
        setaskAIInfo(value);
      }}
    >
      <>
        <Dropdown
          menu={menuProps}
          trigger={['click']}
          overlayStyle={{
            color: theme === 'light' ? '#000' : '#fff',
            backgroundColor: 'transparent',
            // color: 'red',
          }}
          overlayClassName={
            theme === 'light'
              ? 'network-card-dropdown-light'
              : 'network-card-dropdown'
          }
        >
          <Button
            type="link"
            onClick={(e) => e.preventDefault()}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              height: 40,
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: mobile ? 0 : '',
              paddingRight: mobile ? 0 : '',
              // marginRight: '1em',
              marginLeft: !mobile ? '1em' : '0px',
              borderLeft: !mobile ? '2px solid #8080808f' : 'none',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: !mobile ? 40 : 36,
                height: !mobile ? 40 : 36,
                border: '1px solid #676767',
                borderRadius: '50%',
                marginRight: 5,
              }}
            >
              <img
                src={
                  activeChain === -1 || isUnsupportedChain
                    ? warningLogo
                    : CHAINS[activeChain]?.logo
                }
                width={25}
                height={25}
                style={{ borderRadius: '50%' }}
              />
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              style={{
                filter: 'drop-shadow(0px 0px 5px rgba(255, 255, 255, 1))',
              }}
            >
              <path
                fill={theme === 'light' ? 'black' : 'white'}
                d="m12 13.171l4.95-4.95l1.414 1.415L12 16L5.636 9.636L7.05 8.222l4.95 4.95Z"
              />
            </svg>
          </Button>
        </Dropdown>
      </>
    </AskAI>
  );
};

export default NetworkCard;
